import { IconButton, useToast } from "@chakra-ui/react";
import { FaSyncAlt } from "react-icons/fa";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { useContext } from "react";
import { refreshUsers } from "lib/backend/api";

export function RefreshDashboardButton() {
    const { selectedUsers, clearSelectedUsers } = useContext(XboxUsersContext);
    const toast = useToast();


    const handleConfirm = () => {

        const newProm = new Promise((resolve, reject) => {
            clearSelectedUsers()
            refreshUsers(Array.from(selectedUsers).map(user => user.emailAddress)).then(resolve).catch(reject);
        })

        toast.promise(newProm, {
            success: (result) => ({
                title: "Refresh complete",
                description: `Refreshed ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,
            }),
            error: (error) => ({
                title: "Refresh failed",
                description: `Failed to refresh ${selectedUsers.size()} user(s) - ${error.message}`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,
            }),
            loading: {
                title: "Refreshing users...",
                description: `Refreshing ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,
            },
        })
    }




    return (
        <IconButton
            aria-label='Refresh users'
            icon={<FaSyncAlt />}
            onClick={handleConfirm}
            colorScheme='blue'
            variant='ghost'
            size='md'
            isDisabled={selectedUsers.size() === 0}
            title='Refresh selected users'
        />
    )
}