import { SoftwareSettings, SearchedSoftware } from "lib/coplay/types/BackendTypes"
import { useState } from "react"
import { SoftwareSettingsController } from "../profile-utils"

export const useSoftwareSettings = (consoleSettings: SoftwareSettings = {
    software: [],
    reinstall: false,
    removeOthers: false
}, setSetting: Function): SoftwareSettingsController => {
    const [selected, setSelected]
        = useState<SearchedSoftware[]>(consoleSettings.software)
    const [reinstall, setReinstall] = useState<boolean>(consoleSettings.reinstall)
    const [removeOthers, setRemoveOthers]
        = useState<boolean>(consoleSettings.removeOthers)

    const add = (app: SearchedSoftware) => {
        if (selected.find((item) => item.name === app.name)) {
            return // App already exists
        }

        setSetting(setSelected)([...selected, app])
    }

    const remove = (app: SearchedSoftware) => {
        setSetting(setSelected)(selected.filter((item) => item.name !== app.name))
    }

    return {
        selected,
        add,
        remove,
        reinstall, setReinstall: setSetting(setReinstall),
        removeOthers, setRemoveOthers: setSetting(setRemoveOthers)
    };
}