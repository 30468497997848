import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, IconButton, Menu, MenuButton, MenuItem } from "@chakra-ui/react";

import { MenuList } from "@chakra-ui/react";
import { deleteUsers } from "lib/backend/api";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { useContext, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";

export function DeleteXboxUserButton(props: { emailAddress: string }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { clearSelectedUsers } = useContext(XboxUsersContext);

    const handleDelete = async () => {
        try {
            clearSelectedUsers()
            setIsLoading(true);
            await deleteUsers([props.emailAddress]);
            setIsOpen(false);
        } catch (error) {
            console.error("Failed to delete user:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (

        <Menu>
            <MenuButton
                as={IconButton}
                icon={<Icon as={FaEllipsisV} />}
                color="gray.400"
                variant="ghost"
                onClick={(e) => e.stopPropagation()}
            />
            <MenuList>
                <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                }}>
                    Delete User
                </MenuItem>
            </MenuList>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={undefined}
                onClose={() => setIsOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>Delete User</AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to delete this user? This action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                            <Button
                                colorScheme="red"
                                isLoading={isLoading}
                                onClick={handleDelete}
                                ml={3}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Menu>
    );
}


