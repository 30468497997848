import { BatchRequest, FriendingRequest, ScheduleEvent, ScheduleFrequency, XMProfileRequest } from "lib/coplay/types/BackendTypes";

import { getRequestToken } from "lib/backend/firebase/service";
import { OauthActions } from "lib/coplay/types/BackendTypes";


function createScheduleId(
    action: OauthActions,
    freq: ScheduleFrequency,
    startDate: Date,
    endDate: Date,
    requestId: string | number) {
    // Print the date in "dd-mm-yyyy" format.
    const startLabel = startDate.toLocaleDateString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').join('-').trim();
    const endLabel = endDate.toLocaleDateString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').join('-').trim();
    return `${action}-${freq}-${startLabel}-${endLabel}-${requestId}`;
}


export async function createFriendingRequest(
    emailAddresses: string[],
    friendXuids: string[],
    oauthAction: OauthActions.AddFriends | OauthActions.RemoveFriends | OauthActions.SyncFriends
): Promise<FriendingRequest> {
    const token = await getRequestToken();

    return {
        oauthAction: oauthAction,
        firebaseToken: token,
        requestId: Date.now(),
        emailAddresses: emailAddresses,
        friendXuids: friendXuids
    }
}

export function createScheduleEvent(
    req: FriendingRequest | BatchRequest | XMProfileRequest,
    frequency: ScheduleFrequency,
    start: Date,
    end: Date,
    name?: string
) {
    const hours = start.getHours()
    const minutes = start.getMinutes()

    if (frequency === ScheduleFrequency.OneTime) {
        end = start
    } else {
        end.setHours(hours, minutes + 1, 0, 0); // To make the last schedule execute
    }

    const scheduleEvent: ScheduleEvent = {
        name: name === '' ? undefined : name,
        scheduleId: createScheduleId(req.oauthAction, frequency, start, end, req.requestId),
        scheduledRequest: req,
        frequency: frequency,
        startDate: start.getTime(),
        endDate: end.getTime(),
        time: `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
    }

    return scheduleEvent;
}

export async function createBatchRequest(
    oauthAction: OauthActions.Refresh | OauthActions.ClearConversations,
    emailAddresses: string[]
): Promise<BatchRequest> {
    const token = await getRequestToken();

    return {
        oauthAction: oauthAction,
        firebaseToken: token,
        requestId: Date.now(),
        emailAddresses: emailAddresses
    }
}

export async function createSyncProfileRequest(
    emailAddresses: string[]
): Promise<XMProfileRequest> {
    const token = await getRequestToken();

    return {
        oauthAction: OauthActions.XMProfile,
        firebaseToken: token,
        requestId: Date.now(),
        action: "sync",
        emailAddresses: emailAddresses
    }
}