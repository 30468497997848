import { FormControl, FormLabel, Checkbox, Text } from "@chakra-ui/react";
import { SoftwareSettingsController }
    from "../profile-utils";
import SoftwareDescription from "./SoftwareDescription";
import { CommonApps } from "lib/coplay/types/FrontendTypes";
import { ProfileAppSearch } from "./ProfileAppSearch";
import { ProfileSoftwareContainer } from "./ProfileSoftwareContainer";

export function ProfileAppSettings(props: {
    editing: boolean, controller: SoftwareSettingsController
}
) {

    return (
        <>
            < FormControl >
                <FormLabel fontWeight='bold'>Apps</FormLabel>
                <Text>
                    <SoftwareDescription
                        editing={props.editing} softwareCategory={"app"}
                    />
                </Text>
                <ProfileSoftwareContainer
                    apps={props.controller.selected}
                    editing={props.editing}
                    remove={props.controller.remove}
                    softwareCategory={"app"}
                />
            </FormControl >

            <FormControl>
                <div style={{ height: "55px", maxWidth: "448px" }}>
                    <ProfileAppSearch
                        editing={props.editing}
                        onSelected={props.controller.add}
                        recomended={CommonApps}
                        placeholder="Search recommended apps"
                    />
                </div>
                <Checkbox
                    isDisabled={!props.editing}
                    onChange={() => props.controller.setReinstall(!props.controller.reinstall)}
                    defaultChecked={props.controller.reinstall}
                    checked={props.controller.reinstall}
                    pt='10px'
                >
                    Clear App Data
                </Checkbox>
                <Text fontWeight='bold' fontSize='sm'>
                    *Clearing app data will reinstall apps to clear data like login information from streaming servies like netflix or hulu. We recommend using this setting.
                </Text>
                <Checkbox
                    isDisabled={!props.editing}
                    onChange={() => props.controller.setRemoveOthers(!props.controller.removeOthers)}
                    defaultChecked={props.controller.removeOthers}
                    checked={props.controller.removeOthers}
                    pt='10px'
                >
                    Remove apps not on your app list
                </Checkbox>
            </FormControl>
        </>
    )
}