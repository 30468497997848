import { useState } from "react";
import { XboxConsole, XboxPower } from "lib/coplay/types/BackendTypes";
import { batchTogglePower, refreshConsoles } from "lib/backend/api";

function bytesToGB(bytes: number) {
    const bytesPerGB = 1024 * 1024 * 1024; // = 2^30
    return (bytes / bytesPerGB).toFixed(2);
}

export const useDeviceHeader = (emailAddress: string, device: XboxConsole) => {
    const [isRefreshing, setIsRefreshing] = useState(false);

    const regex = /(?<=[a-z])(?=[A-Z])/;
    const xboxType = device.consoleType.split(regex).join(' ');

    const storageDevices = () => {
        if (device.storageDevices) {
            return device.storageDevices.map((dev) => {
                const totalSpaceGB = Number(bytesToGB(dev.totalSpaceBytes));
                const freeSpaceGB = Number(bytesToGB(dev.freeSpaceBytes));
                const usedSpaceGB = totalSpaceGB - freeSpaceGB;
                const usedPercentage = (usedSpaceGB / totalSpaceGB) * 100;

                return {
                    name: dev.storageDeviceName,
                    freeSpaceGB,
                    totalSpaceGB,
                    usedPercentage
                };
            });
        }
        return [];
    };

    const refreshConsole = () => {
        setIsRefreshing(true);
        refreshConsoles([emailAddress]).then(() => {
            setIsRefreshing(false);
        }).catch((error: any) => {
            console.error(error);
            setIsRefreshing(false);
        });
    };

    const togglePower = async (powerState: XboxPower) => {
        try {
            setIsRefreshing(true);
            await batchTogglePower([emailAddress], powerState);
        } catch (error: any) {
            console.error('Failed to toggle power:', error);
        } finally {
            setIsRefreshing(false);
        }
    };

    return {
        isRefreshing,
        xboxType,
        storageDevices,
        refreshConsole,
        togglePower
    };
};