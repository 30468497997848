import { Avatar, Box, Spacer, useColorModeValue } from "@chakra-ui/react";
import { Flex, Text } from "@chakra-ui/react";
import { AddXboxConsoleButton } from "lib/coplay/components/xbox/AddXboxConsoleButton";
import { UserStatusMessage, XboxUser } from "lib/coplay/types/BackendTypes";
import { XboxUserStatus } from "./user-status/XboxUserStatus";
import { ScheduleBadge } from "./ScheduleBadge";
import { XboxConsoleDetailsButton } from "lib/coplay/components/xbox/XboxConsoleDetailsButton";
import { XboxUserStatusNotificationButton } from "./user-status/modal/components/XboxUserStatusButtons";
import XboxProfileLine from "./XboxProfileLine";
import { DeleteXboxUserButton } from "./DeleteXboxUserButton";

type XboxUserCardProps = {
    user: XboxUser,
    isSelected: boolean,
    onSelect: (user: XboxUser, shiftKey: boolean) => void,
    onDeselect: (user: XboxUser) => void,
    onDeviceDetails: (user: XboxUser) => void,
    onAccountDetails: (user: XboxUser) => void,
    onStatusDetails: (user: XboxUser) => void,
}

export function XboxUserCard(props: XboxUserCardProps) {

    const secondaryColor = useColorModeValue('gray.400', 'white');
    const cardShadow = useColorModeValue('0px 0px 20px rgba(112, 144, 176, 0.12)', 'unset');

    const hasStatusNotification
        = props.user.userStatus.statusMessage === UserStatusMessage.Error ||
        props.user.userStatus.statusMessage === UserStatusMessage.Warning

    // console.log(`[Rendering] XboxUserCard ${props.user.emailAddress} isSelected: ${props.isSelected}`);

    return (
        <li style={{ listStyle: 'none' }} key={props.user.emailAddress} onClick={() => { props.onAccountDetails(props.user) }}>
            <Flex borderRadius={'10px'} w='100%' boxShadow={cardShadow} wrap='wrap' alignItems='center' gap='2' _hover={{ cursor: 'pointer', background: 'secondaryGray.300' }}>
                {/* CHECKBOX */}
                <Box p={2} fontWeight='bold' fontSize='md'>
                    <label>
                        <input id={`${props.user.xuid}-checkbox`} readOnly style={{ height: '18px', width: '18px' }} type="checkbox" checked={props.isSelected}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (props.isSelected) {
                                    props.onDeselect(props.user)
                                } else {
                                    props.onSelect(props.user, e.shiftKey)
                                }
                            }} />
                    </label>
                </Box>
                {/* USER INFO */}
                <Flex w='300px' h={'75px'} overflow='hidden' alignItems="center">
                    <Box m={1}>
                        <Avatar src={props.user.profilePicUrl} mr='2' >
                            {props.user.schedules?.length > 0 &&
                                <ScheduleBadge
                                    scheduleIds={props.user.schedules}
                                    emailAddress={props.user.emailAddress}
                                />
                            }
                        </Avatar>
                    </Box>
                    <Flex direction='column' justifyContent='center' h={'100%'} >
                        <Text
                            fontWeight='bold'
                            fontSize='md'
                            maxHeight='50px'
                        >
                            {props.user.emailAddress}
                        </Text>
                        <Text fontWeight='bold' fontSize='xs'>
                            {props.user.gamerTag}
                        </Text>
                        <XboxProfileLine profile={props.user.xmProfile} />
                        <Text color={secondaryColor} fontWeight='bold' fontSize='xs'>
                            Last Updated: {props.user.updatedAt.toLocaleString()}
                        </Text>
                    </Flex>
                </Flex>
                <Flex h='100%' w='120px' alignItems='center' borderRadius='10px' justifyContent='center' p={1} overflow='hidden' _hover={{ cursor: 'pointer', bg: 'secondaryGray.200' }}>
                    {/* TODO: Lift this up to the parent component */}
                    {!props.user.consoleId ?
                        <AddXboxConsoleButton emailAddress={props.user.emailAddress} /> :
                        <XboxConsoleDetailsButton
                            consoleId={props.user.consoleId}
                            consoleName={props.user.consoleName}
                            consoleOs={props.user.consoleOS}
                            consoleState={props.user.consoleState}
                            emailAddress={props.user.emailAddress}
                            onClick={() => { props.onDeviceDetails(props.user) }}
                        />
                    }
                </Flex>
                <Spacer />
                {/* Friends and Conversations */}
                <Flex width='125px' direction='column' fontWeight='bold' fontSize='sm'>
                    <Text>Friends: {props.user.numFriends}</Text>
                    <Text>Conversations: {props.user.numConversations}</Text>
                </Flex>

                {/* User Status */}
                <XboxUserStatus user={props.user} >
                    {hasStatusNotification &&
                        <XboxUserStatusNotificationButton user={props.user} onOpen={() => { props.onStatusDetails(props.user) }} />
                    }
                </XboxUserStatus>

                {/* Notes */}
                <Flex mr="2" width='150px' h='75px' direction='column' fontWeight='bold' fontSize='sm' overflow="hidden">
                    <Text w="100%" textAlign="center" color={secondaryColor} fontWeight='bold' fontSize='sm'>
                        {props.user.notes ? props.user.notes : "Notes"}
                    </Text>
                </Flex>
                <DeleteXboxUserButton emailAddress={props.user.emailAddress} />
            </Flex>
        </li >
    )
}