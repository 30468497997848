import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import Card from 'lib/horizon/components/card/Card';

export function PaymentsUpgradeState(props: { icon: React.ReactNode }) {
    const cardShadow = useColorModeValue('0px 2px 8px rgba(112, 144, 176, 0.3)', 'unset');

    return (
        <Card
            id="paymentsUpgradeState"
            boxShadow={cardShadow}
            justifyContent="center"
            alignContent={"center"}
            alignItems={"center"}
            h='100%' w='100%'

        >
            <Box maxW="50%" textAlign={"center"}>
                {props.icon}
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontWeight="bold" fontSize='3xl' color="orange.500">
                    {"CoPlay Purchasing and Subscription Management has not been enabled for your account"}
                </Text>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontSize='md' color="orange.700">
                    {"Please contact support to enable this feature."}
                </Text>
            </Box>
        </Card>
    )
}