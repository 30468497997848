import { Flex, HStack, Text } from "@chakra-ui/react";
import { useUserLimit } from "lib/coplay/contexts/ConfigurationContext";

export function FundingDetails(props: {
    coplayBalance: number,
    coplaySpend: number,
    users: number
}) {
    const { coplayBalance, coplaySpend, users: monthlyCards } = props;
    const userLimit = useUserLimit();
    return (
        <HStack spacing={8} >
            <Flex direction="column" align="center">
                <Text fontSize="sm" color="gray.500">Users</Text>
                <Text fontSize="xl" fontWeight="bold">{monthlyCards} / {userLimit}</Text>
            </Flex>
            <Flex direction="column" align="center">
                <Text fontSize="sm" color="gray.500"> Spent</Text>
                <Text fontSize="xl" fontWeight="bold">${coplaySpend.toFixed(2)}</Text>
            </Flex>
            <Flex direction="column" align="center">
                <Text fontSize="sm" color="gray.500">CoPlay Credit</Text>
                <Text fontSize="xl" fontWeight="bold">${coplayBalance.toFixed(2)}</Text>
            </Flex>
        </HStack>
    )
}