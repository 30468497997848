import React, { memo, useRef, useState, useEffect } from "react";
import memoize from 'memoize-one';
import { FixedSizeList as List, areEqual } from 'react-window';
import { XboxUser } from "lib/coplay/types/BackendTypes";
import { XboxUserCard } from "../xbox/components/user/XboxUserCard";
import { Box } from "@chakra-ui/react";
import { XboxUsersSet } from "lib/coplay/utils/XboxUsersSet";

const Row = memo(({ data, index, style }: {
    data: {
        users: XboxUser[],
        selectedUsers: XboxUsersSet,
        onSelect: (user: XboxUser, shiftKey: boolean) => void,
        onDeselect: (user: XboxUser) => void,
        onDeviceDetails: (user: XboxUser) => void,
        onAccountDetails: (user: XboxUser) => void,
        onStatusDetails: (user: XboxUser) => void,
    }, index: number, style: React.CSSProperties
}) => {
    const { users, selectedUsers, onSelect, onDeselect, onDeviceDetails, onAccountDetails, onStatusDetails } = data;
    const user = users[index];
    const isSelected = selectedUsers.has(user);

    return (
        <Box px={4} borderRadius='md' style={style}>
            <XboxUserCard
                user={user}
                isSelected={isSelected}
                onSelect={onSelect}
                onDeselect={onDeselect}
                onDeviceDetails={onDeviceDetails}
                onAccountDetails={onAccountDetails}
                onStatusDetails={onStatusDetails}
            />
        </Box>
    );
}, areEqual);

const createItemData = memoize((users, selectedUsers, onSelect, onDeselect, onDeviceDetails, onAccountDetails, onStatusDetails) => ({
    users,
    selectedUsers,
    onSelect,
    onDeselect,
    onDeviceDetails,
    onAccountDetails,
    onStatusDetails,
}));

export function ExperimentalList(props: {
    users: XboxUser[],
    selectedUsers: XboxUsersSet,
    onSelect: (user: XboxUser, shiftKey: boolean) => void,
    onDeselect: (user: XboxUser) => void,
    onDeviceDetails: (user: XboxUser) => void,
    onAccountDetails: (user: XboxUser) => void,
    onStatusDetails: (user: XboxUser) => void
}) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [itemSize, setItemSize] = useState(100);

    // Resize with wrap.
    useEffect(() => {
        const updateItemSize = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                // Calculate item size based on container width
                let itemSizeCandidate;
                // Standard breakpoints:
                // xs: 0-576px
                // sm: 576px-768px  
                // md: 768px-992px
                // lg: 992px-1200px
                // xl: 1200px+
                // if (containerWidth < 576) {
                //     itemSizeCandidate = 190; // Stack vertically on mobile
                // } else if (containerWidth < 768) {
                //     itemSizeCandidate = 190; // 2-3 items per row
                // } else if (containerWidth < 992) {
                //     itemSizeCandidate = 170; // 3-4 items per row
                // } else if (containerWidth < 1200) {
                //     itemSizeCandidate = 115; // 4-5 items per row
                // } else {
                //     itemSizeCandidate = 115; // 5+ items per row on large screens
                // }
                if (containerWidth < 400) {
                    itemSizeCandidate = 400; // Stack vertically on mobile
                } else if (containerWidth < 500) {
                    itemSizeCandidate = 300; // Stack vertically on mobile
                } else if (containerWidth < 964) {
                    itemSizeCandidate = 190; // 2-3 items per row
                } else {
                    itemSizeCandidate = 85; // 5+ items per row on large screens
                }

                setItemSize(itemSizeCandidate); // Example: 5 items per row
            }
        };

        updateItemSize();
        window.addEventListener('resize', updateItemSize);

        return () => {
            window.removeEventListener('resize', updateItemSize);
        };
    }, []);

    const itemData = createItemData(
        props.users,
        props.selectedUsers,
        props.onSelect,
        props.onDeselect,
        props.onDeviceDetails,
        props.onAccountDetails,
        props.onStatusDetails
    );

    return (
        <Box h="100%" ref={containerRef} id='experimental-list'>
            <List
                height={containerRef.current ? containerRef.current.offsetHeight : 0}
                itemCount={props.users.length}
                itemData={itemData}
                itemSize={itemSize}
                width="100%"
            >
                {Row}
            </List>
        </Box>
    );
}

export default ExperimentalList;