import { Box, Flex, Link, Spacer, Text, useColorModeValue } from "@chakra-ui/react";
import { Privilege } from "lib/coplay/types/BackendTypes";

export function XboxAccountPrivileges(props: { privileges: Privilege[] }) {
    return (
        <Box>
            <Text as='h3' fontSize='xl' fontWeight='bold'>Privileges</Text>
            <Text fontWeight='500' fontSize='sm'>Privileges are special permissions that allow a user to do certain things
                like play online. Privleges must be changed on the xbox website.
                These permissions can be adjusted from <PrivilegesLink />
            </Text>
            <Box my='6' mx='12' bg='gray.100'>
                <ul style={{ padding: 0, margin: 0 }}>
                    {props.privileges.map((privilege: Privilege) => {
                        return (
                            <li key={privilege.description}>
                                <Flex my='1' w='100%'>
                                    <Text as='span'>{privilege.description}</Text>
                                    <Spacer />
                                    <Text fontWeight='bold'>{privilege.value}</Text>
                                </Flex>
                            </li>
                        )
                    })}
                </ul>
            </Box>
        </Box>
    )
}


function PrivilegesLink() {
    const colorModeValue = useColorModeValue("brand.500", "white")

    return (
        <Link
            color={colorModeValue}
            href=
            {"https://account.xbox.com/en-us/settings?activetab=main:privilegetab"}
            target={"_blank"}
        >
            the Xbox site
        </Link>
    )
}