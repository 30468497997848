import { XMError } from "lib/coplay/types/BackendTypes";
import { emptyXMError } from "lib/coplay/utils/utils";
import { useState } from "react";

enum AddConsoleState {
    FINDING_CONSOLE = "FINDING_CONSOLE",
    FOUND_CONSOLES = "FOUND_CONSOLES",
    LINKING_CONSOLE = "LINKING_CONSOLE",
    ERROR = "ERROR"
}

export const useXboxConsoleLink = (emailAddress: string) => {
    // console.log(`[Rendering] useXboxConsoleLink ${emailAddress}`);
    const [progress, setProgress] = useState(AddConsoleState.FINDING_CONSOLE);
    const [xboxIds, setXboxIds] = useState<string[]>([]);
    const [selectedXboxId, setSelectedXboxId] = useState<string>("");
    const [error, setError] = useState<XMError>(emptyXMError());

    const onFoundConsoles = (xboxIds: string[]) => {
        setXboxIds(xboxIds);
        setProgress(AddConsoleState.FOUND_CONSOLES);
    }

    const onSelectedXboxId = (xboxId: string) => {
        setSelectedXboxId(xboxId);
        setProgress(AddConsoleState.LINKING_CONSOLE);
    }

    const onError = (error: XMError) => {
        setProgress(AddConsoleState.ERROR);
        setError(error);
    }

    const reset = () => {
        setProgress(AddConsoleState.FINDING_CONSOLE);
        setXboxIds([]);
        setSelectedXboxId("");
        setError(emptyXMError());
    }
    // console.log(`useXboxConsoleLink ${emailAddress} isOpen: ${emailAddress}, progress: ${progress}`);

    return {
        progress,
        xboxIds,
        selectedXboxId,
        error,
        onFoundConsoles,
        onSelectedXboxId,
        onError,
        reset,
    };
};