import { VendorMember } from "lib/coplay/types/PaymentTypes";
import { LinkFundingCrumb } from "./member/LinkFundingCrumb";
import { FundingCrumb } from "./member/FundingCrumb";

export function getFundingSource(member: VendorMember) {
    const coplayFunding = member.subscriptions.length > 0
        && member.subscriptions[0].coplayFunding
        ? member.subscriptions[0].coplayFunding
        : null;
    return coplayFunding ? <FundingCrumb funding={coplayFunding} /> : <LinkFundingCrumb />;
}