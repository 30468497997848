import { Flex, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { XboxIcon } from "./XboxIcon";
import { LinkXboxConsoleModal } from "../modals/xbox-link/LinkXboxConsoleModal";


export function AddXboxConsoleButton(props: { emailAddress: string }) {
    const textColor = useColorModeValue('brand.500', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        onOpen();
    };

    // console.log(`[Rendering] AddXboxConsoleButton ${props.emailAddress} isOpen: ${isOpen}`);

    return (
        <>
            {/* TODO: Lift this up to the parent component */}
            <LinkXboxConsoleModal
                emailAddress={props.emailAddress}
                isOpen={isOpen}
                onClose={onClose}
            />
            <Flex
                w='100%'
                textAlign={'center'}
                direction='column'
                onClick={handleClick}
                mt={-1}
                alignItems='center'
                _hover={{ cursor: 'pointer', textDecoration: 'underline' }}>
                <XboxIcon color={"#A0AEC0"} />
                <Text
                    w={'100%'}
                    color={textColor}
                    textAlign={'center'}
                    fontWeight='bold'
                    fontSize='xs'
                    _hover={{ cursor: 'pointer', }}
                    mt={-5}
                    zIndex={2}
                >
                    Link Xbox
                </Text>
            </Flex>
        </>
    );
}

export function AddLinkDeviceOverviewButton(props: { emailAddress: string }) {
    const textColor = useColorModeValue('brand.500', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        onOpen();
    };

    // console.log(`[Rendering] AddLinkDeviceOverviewButton ${props.emailAddress} isOpen: ${isOpen}`);

    return (
        <>
            {/* TODO: Lift this up to the parent component */}
            <LinkXboxConsoleModal
                emailAddress={props.emailAddress}
                isOpen={isOpen}
                onClose={onClose}
            />
            <Flex
                px={1}
                alignItems='center'
                onClick={handleClick}
                _hover={{ cursor: 'pointer', fontSize: 'md' }}>
                <XboxIcon size='100px' color={"#A0AEC0"} />
                <Text
                    ml={2}
                    w={'100%'}
                    color={textColor}
                    fontWeight='bold'
                >
                    Click here to link your console
                </Text>
            </Flex>
        </>
    );
}