import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { CoPlayFooter } from "lib/coplay/layout/CoPlayFooter";
import { ReactNode } from "react";

interface ContentAreaProps {
    children: ReactNode;
}

export function ContentArea(props: ContentAreaProps) {
    const bgColor = useColorModeValue('white', 'gray.800');
    return (
        <>
            <Flex
                direction='column'
                width='100%'
                px={2}
                pt={28}
                height='100%'
                w='100%'
                // maxHeight='97vh'
                bg={bgColor}
                overflowY='scroll'
                id='content-area'
            >
                {props.children}
                <Box py={1} width='100%'>
                    <CoPlayFooter />
                </Box>
            </Flex >
        </>
    );
}