import { Box, AvatarBadge, Icon, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";
import { IoTime } from "react-icons/io5";
import { ScheduleDetailsModal } from "./ScheduleDetailsModal";

export function ScheduleBadge(props: { scheduleIds: string[], emailAddress: string }) {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    let badgeColor = useColorModeValue('black', 'black');


    const onClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsModalOpen(true);
    }

    return (
        <>
            <Box onClick={onClick} _hover={{ cursor: 'pointer' }}>
                <AvatarBadge
                    _hover={{ boxSize: '25px' }}
                    border="none"
                    boxSize='40%'
                    color={badgeColor}
                    bg={'white'}
                >
                    <Icon as={IoTime} boxSize='100%' />
                </AvatarBadge>

                <ScheduleDetailsModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    scheduleIds={props.scheduleIds}
                    emailAddress={props.emailAddress}
                />
            </Box>
        </>
    );
}
