import { Box, Flex, HStack, Spacer, Text, useColorModeValue } from "@chakra-ui/react";
import { VendorMember } from "lib/coplay/types/PaymentTypes";
import { PaymentsMember } from "../member/PaymentsMember";
import { useState } from "react";
import ManageMemberModal from "../manage-member-modal/ManageMemberModal";


export function PaymentsViewLayout(props: {
    header: React.ReactNode,
    description: string,
    button: React.ReactNode,
    fundingDetails: React.ReactNode,
    members: VendorMember[]
}) {
    const cardShadow = useColorModeValue('0px 20px 20px -20px rgba(112, 144, 176, 0.3)', 'unset');
    const [selectedMember, setSelectedMember] = useState<VendorMember | null>(null);

    return (
        <Flex id='PaymentsViewLayout' direction={"column"} height="90%" width="100%">
            <Box pb={4} boxShadow={cardShadow}>
                <HStack spacing={8} justify="space-between" borderRadius="lg">
                    {props.header}
                    <Spacer />
                    {props.fundingDetails}
                    {props.button}
                </HStack>
                <Text mb={2} fontSize='md'>
                    {props.description}
                </Text>
            </Box>
            <Box h='100%' overflowY="auto" width="100%">
                {props.members.map((member) => (
                    <PaymentsMember key={member.id} member={member} setSelectedMember={setSelectedMember} />
                ))}
                <ManageMemberModal
                    isOpen={!!selectedMember}
                    onClose={() => setSelectedMember(null)}
                    member={selectedMember!}
                />
            </Box>
        </Flex>
    )
}