import { Input, Text, useColorModeValue } from "@chakra-ui/react"
import Card from "lib/horizon/components/card/Card"

export default function ContactDetail(props: {
    getEditing: boolean,
    inputRef: React.MutableRefObject<HTMLInputElement>,
    detailName: string,
    detailValue: string
}) {
    const textColor = useColorModeValue("secondaryGray.900", "white")

    return (
        <Card style={{ padding: '10px', fontWeight: 500 }}>
            <label>
                <Text style={{ fontSize: "14px" }} color={"gray.400"}>
                    {props.detailName}
                </Text>
                {props.getEditing ?
                    <Input
                        ref={props.inputRef}
                        p={2}
                        h={8}
                        defaultValue={props.detailValue}
                    />
                    :
                    <Text color={textColor}>
                        {props.detailValue}
                    </Text>
                }
            </label>
        </Card>
    )
}

