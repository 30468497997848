import { useEffect, useState } from 'react';
import { LogEntry } from 'lib/coplay/types/BackendTypes';
import { getXboxUserData } from 'lib/backend/firebase/service';

export const useXboxUserLogs = (emailAddress: string) => {
    const [logs, setLogs] = useState<LogEntry[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const unsubscribe = getXboxUserData().
            subscribeToUserLogs(emailAddress, (fetchedLogs) => {
                setLogs(fetchedLogs);
                setLoading(false);
            });
        return unsubscribe;
    }, [emailAddress]);

    return { logs, loading };
};