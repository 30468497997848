import { Button, MenuItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react"
import { refreshUsers } from "lib/backend/api"
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { XboxUsersSet } from "lib/coplay/utils/XboxUsersSet"
import { useContext } from "react";
import { MdSync } from "react-icons/md"

export function RefreshMenuItem(props: {
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <MenuItem icon={<MdSync />} onClick={onOpen}>
                Refresh
            </MenuItem>
            <RefreshUsersModal
                isOpen={isOpen}
                onClose={onClose}
            />
        </>
    )
}

export function RefreshUsersModal(props: {
    isOpen: boolean,
    onClose: () => void
}) {
    const toast = useToast();
    const { selectedUsers, clearSelectedUsers } = useContext(XboxUsersContext)
    const selectedEmails = selectedUsers.toArray().map(user => user.emailAddress)

    const handleConfirmPromise = () => {
        clearSelectedUsers()
        return refreshUsers(selectedEmails)
    }

    const handleConfirm = () => {
        toast.promise(handleConfirmPromise(), {
            success: (result) => ({
                title: "Refresh complete",
                description: `Refreshed ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,
            }),
            error: (error) => ({
                title: "Refresh failed",
                description: `Failed to refresh ${selectedUsers.size()} user(s) - ${error.message}`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,

            }),
            loading: {
                title: "Refreshing users...",
                description: `Refreshing ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,
            },
        });
        props.onClose();
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Refresh Users</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure you want to refresh {selectedUsers.size()} user(s)?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleConfirm}>
                        Confirm
                    </Button>
                    <Button variant="ghost" onClick={props.onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}