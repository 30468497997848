import { useState } from 'react';

export function useProfileListActions(onDelete: (event: React.MouseEvent) => void) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsOpen(true);
    };

    const handleConfirm = async (event: React.MouseEvent) => {
        setIsLoading(true);
        try {
            await onDelete(event);
        } finally {
            setIsOpen(false);
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setIsLoading(false);
    };

    return {
        isOpen,
        isLoading,
        handleDelete,
        handleConfirm,
        handleCloseModal,
    };
}