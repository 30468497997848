
import { PartialXMProfile, XMProfile } from "lib/coplay/types/BackendTypes"
import { CheckIcon, WarningIcon } from "@chakra-ui/icons"
import {
    useDisclosure, Text, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody
} from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { getXboxProfilesData } from "lib/backend/firebase/service"
import ProfileDetailsPage from "lib/coplay/views/profiles/components/ProfileDetailsPage"

const useXboxProfileLine = (profile?: PartialXMProfile) => {
    const disclosure = useDisclosure()
    const [fullProfile, setFullProfile] = useState<XMProfile | null>(null)

    useEffect(() => {
        if (!profile)
            return

        return getXboxProfilesData().subscribeToProfiles((profiles: XMProfile[]) => {
            setFullProfile(
                profiles.find((foundProfile: XMProfile) => foundProfile.id === profile?.id)
            )
        })
    }, [profile])

    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        disclosure.onOpen()
    }

    return {
        isOpen: disclosure.isOpen,
        onClose: disclosure.onClose,
        onClick,
        fullProfile
    }
}

export default function XboxProfileLine(props: { profile?: PartialXMProfile }) {
    const { isOpen, onClose, onClick, fullProfile } = useXboxProfileLine(props.profile)

    if (!props.profile) {
        return <></>
    }

    const color = props.profile.error ? "orange.500" : "green.500"
    const icon = props.profile.error ? <WarningIcon fontSize="xs" /> : <CheckIcon fontSize="xs" />

    return (
        <div style={{ display: "flex", alignItems: "left" }}>
            <Text
                color={color}
                fontWeight='bold'
                fontSize='xs'
                _hover={{ fontSize: "sm" }}
                onClick={onClick}
            >
                {icon} {props.profile.name}

                <Modal
                    isOpen={isOpen}
                    size={"6xl"}
                    onClose={onClose}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton style={{ zIndex: 1 }} />
                        <ModalBody>
                            {props.profile.error && (
                                <Text as="span" color="red.500" ml={2} fontSize="md">
                                    *{props.profile.error}
                                </Text>
                            )}
                            {fullProfile && <ProfileDetailsPage profile={fullProfile} />}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Text>
        </div>
    )
}
