import React from 'react';
import { FormLabel, Text, Input, InputGroup, InputRightElement, Icon } from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

interface PasswordInputProps {
    labelColor: string;
    inputColor: string;
    value: string;
    onChange: (value: string) => void;
    showPassword: boolean;
    setShowPassword: (show: boolean) => void;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
    labelColor,
    inputColor,
    value,
    onChange,
    showPassword,
    setShowPassword
}) => {
    return (
        <>
            <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={labelColor}
                display='flex'
                width="100%"
            >
                Password<Text color={inputColor}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
                <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Min. 8 characters'
                    mb='24px'
                    size='lg'
                    type={showPassword ? "text" : "password"}
                    variant='auth'
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                        color={"gray.400"}
                        _hover={{ cursor: "pointer" }}
                        as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                </InputRightElement>
            </InputGroup>
        </>
    );
};