import { BasicResponse } from "lib/coplay/types/BackendTypes";
import { Box, Button, Divider, Flex, ModalBody, ModalCloseButton, ModalHeader, Text, Icon } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

export function AggregationErrorModalScreen(props: { response: BasicResponse, close: () => void }) {
    return (
        <>
            <ModalHeader>There was an error adding your Xbox Live user</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Flex alignItems='center' direction='column' textAlign='center' borderRadius='md' >
                    <Box borderRadius='md' bg='red.100' p={2}>
                        <Icon as={WarningIcon} w={8} h={8} color='red.500' mb={2} />
                        <Text py={2} color='red.700'>{props.response.error?.userMessage}</Text>
                        <Text py={2} color='red.700'>{props.response.error?.suggestion}</Text>
                        <Box py='10px'>
                            <details>
                                <summary>More Info</summary>
                                <Box py='5px'>
                                    <Divider />
                                    <Box textAlign='left'>
                                        <Text>{props.response.emailAddress}</Text>
                                        <Text>ID: {props.response.requestId}</Text>
                                        <Text>{props.response.error?.errorMessage}</Text>
                                    </Box>
                                    <Divider />
                                </Box>
                            </details>
                        </Box>
                    </Box>
                    <Button mt={4} width='200px' variant="brand" onClick={props.close}>Close</Button>
                </Flex>
            </ModalBody>
        </>
    )
}