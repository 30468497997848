import { XboxUser, UserStatusMessage } from "../types/BackendTypes";
import { XboxUserFilters } from "../views/xbox/components/FilterDropdown";
import { SortOption } from "../views/xbox/components/SortOptionsDropdown";

export function applySearch(users: XboxUser[], searchText: string) {
    let searchResults: XboxUser[] = searchText === ''
        ? users
        : users.filter(
            user => (user.gamerTag && user.gamerTag.toLowerCase().includes(searchText.toLowerCase()))
                || (user.emailAddress && user.emailAddress.toLowerCase().includes(searchText.toLowerCase())
                    || (user.notes?.toLowerCase().includes(searchText.toLowerCase()))
                    || (user.xmProfile?.name.toLowerCase().includes(searchText.toLowerCase()))),
        );
    console.log("Search results: ", searchResults);
    return searchResults;
}

export function applyFilters(users: XboxUser[], filters: XboxUserFilters) {
    let filteredUsers = users;
    if (filters.profileNames.length > 0) {
        filteredUsers = filteredUsers.filter(user =>
            filters.profileNames.includes('None') ? !user.xmProfile :
                user.xmProfile && filters.profileNames.includes(user.xmProfile.name)
        );
    }

    if (filters.consoleStatus.length > 0) {
        filteredUsers = filteredUsers.filter(user =>
            filters.consoleStatus.includes(user.consoleState || 'None')
        );
    }

    if (filters.userStatus.length > 0) {
        filteredUsers = filteredUsers.filter(user =>
            filters.userStatus.includes(user.userStatus.statusMessage)
        );
    }

    if (filters.notesContains) {
        filteredUsers = filteredUsers.filter(user =>
            user.notes?.toLowerCase().includes(filters.notesContains.toLowerCase())
        );
    }

    return filteredUsers;
}

export function sortUsers(users: XboxUser[], option: SortOption, isDescending: boolean) {
    let sortedUsers = users;
    switch (option) {
        case SortOption.Email:
            sortedUsers = users.sort((a, b) => {
                if (a.emailAddress && b.emailAddress) {
                    if (a.emailAddress.toLowerCase() < b.emailAddress.toLowerCase()) {
                        return -1;
                    }
                    if (a.emailAddress.toLowerCase() > b.emailAddress.toLowerCase()) {
                        return 1;
                    }
                }
                return 0;
            });
            break;
        case SortOption.Xbox:
            sortedUsers = users.sort((first, second) => {
                if (first.consoleId) {
                    if (!second.consoleId) {
                        return -1
                    }
                } else if (second.consoleId) {
                    return 1
                }
                return 0
            })
            break
        case SortOption.GamerTag:
            sortedUsers = users.sort((a, b) => {
                if (a.gamerTag && b.gamerTag) {
                    if (a.gamerTag.toLowerCase() < b.gamerTag.toLowerCase()) {
                        return -1;
                    }
                    if (a.gamerTag.toLowerCase() > b.gamerTag.toLowerCase()) {
                        return 1;
                    }
                }
                return 0;
            });
            break;
        case SortOption.Conversations:
            sortedUsers = users.sort((a, b) => {
                if (a.numConversations < b.numConversations) {
                    return -1;
                }
                if (a.numConversations > b.numConversations) {
                    return 1;
                }
                return 0;
            });
            break;
        case SortOption.NumFriends:
            sortedUsers = users.sort((a, b) => {
                if (a.numFriends < b.numFriends) {
                    return -1;
                }
                if (a.numFriends > b.numFriends) {
                    return 1;
                }
                return 0;
            });
            break;
        case SortOption.Status:
            sortedUsers = users.sort((a, b) => {
                // HACK - Handle the fact that we are actually displaying user presence text, not status
                const aComp =
                    a.userStatus.statusMessage !== UserStatusMessage.Ready ? a.userStatus.statusMessage : a.presenceText;
                const bComp =
                    b.userStatus.statusMessage !== UserStatusMessage.Ready ? b.userStatus.statusMessage : b.presenceText;
                if (aComp < bComp) {
                    return -1;
                }
                if (aComp > bComp) {
                    return 1;
                }
                return 0;
            });
            break;
        case SortOption.UpdatedAt:
            sortedUsers = users.sort((a, b) => {
                if (a.updatedAt < b.updatedAt) {
                    return -1;
                }
                if (a.updatedAt > b.updatedAt) {
                    return 1;
                }
                return 0;
            }
            );
            break;

    }
    if (isDescending) {
        sortedUsers = sortedUsers.reverse();
    }
    return sortedUsers;
}
