import { FriendingAction, UserPrivacySettings, SearchedSoftware, XMProfile } from "lib/coplay/types/BackendTypes"

export type XboxAccountSettingsController = {
    friending: FriendingAction,
    setFriending: (value: FriendingAction) => void,
    clearConversations: boolean,
    setClearConversations: (value: boolean) => void,
    getEnablePrivacy: boolean,
    setEnablePrivacy: Function,
    privacySettings: UserPrivacySettings | null,
    setPrivacySettings: (value: UserPrivacySettings) => void
}

export type SoftwareSettingsController = {
    selected: SearchedSoftware[],
    add: (app: SearchedSoftware) => void,
    remove: (app: SearchedSoftware) => void,
    reinstall: boolean,
    setReinstall: (value: boolean) => void,
    removeOthers: boolean,
    setRemoveOthers: (value: boolean) => void
}

export const collateProfileData = (
    id: string,
    name: string,
    appController: SoftwareSettingsController,
    gameController: SoftwareSettingsController,
    accountController: XboxAccountSettingsController): XMProfile => {
    const date = new Date();
    if (!id) {
        id = `${name.replace(/\s/g, "_")}-${date.getTime().toString()}`
    }
    const privacySettings
        = accountController.getEnablePrivacy ? accountController.privacySettings : null

    const profile =
    {
        name: name,
        dateCreated: date.toISOString(),
        id,
        consoleSettings: {
            games: {
                software: gameController.selected,
                removeOthers: gameController.removeOthers,
                reinstall: gameController.reinstall
            },
            apps: {
                software: appController.selected,
                reinstall: appController.reinstall,
                removeOthers: appController.removeOthers
            },
        },
        accountSettings: {
            friending: accountController.friending,
            clearConversations: accountController.clearConversations,
            privacySettings
        }
    }

    return profile
}