import {
    Avatar,
    Box,
    Button,
    Flex, Input,
    InputGroup,
    InputRightElement,
    List,
    ListItem, Spacer,
    Text,
    VStack
} from "@chakra-ui/react";
import { useState } from "react";
import { SearchedSoftware } from "lib/coplay/types/BackendTypes";


const useSoftwareSearch = (props: { recomendedApps: SearchedSoftware[] }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredApps, setFilteredApps] = useState<SearchedSoftware[]>(props.recomendedApps);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);

        if (newSearchTerm === "") {
            setFilteredApps(props.recomendedApps);
        } else {
            setFilteredApps(
                props.recomendedApps.filter((item) =>
                    item.name.toLowerCase().includes(newSearchTerm.toLowerCase())
                )
            );
        }
    };

    return {
        searchTerm,
        filteredApps,
        handleChange
    };
}

export function ProfileAppSearch(props: {
    editing: boolean,
    onSelected: (app: SearchedSoftware) => void,
    recomended: SearchedSoftware[]
    placeholder: string
}) {

    const { searchTerm, filteredApps, handleChange } = useSoftwareSearch({ recomendedApps: props.recomended });

    const [isFocused, setIsFocused] = useState(false);

    let softwareSearch: JSX.Element
    if (props.editing) {
        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
        };


        const items =
            filteredApps.map((app, index) =>
                <ListItem
                    key={`${index}-${app.name}`}
                    p={2}
                    _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
                    onMouseDown={() => props.onSelected(app)}
                >
                    <Flex alignItems={'center'}
                    >
                        <Avatar
                            name={app.name}
                            marginRight="2"
                            src={app.imageUri}
                            w='50px'
                            h='50px'
                            borderRadius='20px'
                            me='16px'
                        />
                        {app.name}
                    </Flex>
                </ListItem>
            )

        const renderAppList = isFocused && filteredApps.length > 0
            ? <List
                mt={2}
                borderWidth={1}
                borderRadius="md"
                zIndex={1}
                position="absolute"
                width="100%"
                bg="white"
                maxHeight={'300px'}
                overflowY='scroll'
            >
                {items}
            </List>
            : <> </>

        softwareSearch = (
            <>
                <InputGroup>
                    <Input
                        placeholder={props.placeholder}
                        value={searchTerm}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    <InputRightElement children="🔍" />
                </InputGroup>
                {renderAppList}
            </>
        );
    } else {
        softwareSearch = null
    }

    return softwareSearch
}

export function ProfileAppItem(props: {
    app: SearchedSoftware, editing: boolean, remove: (app: SearchedSoftware) => void
}) {

    return (
        <Box
            position="relative"
            borderRadius='md'
            w='100px'
            minW="75px"
            overflow='hidden'
            border={'1px'}
        >
            <VStack p='5px 5px 4px'>
                <Avatar
                    m='0px'
                    p='0px'
                    name={props.app.name}
                    src={props.app.imageUri}
                    w='70px'
                    h='70px'
                    borderRadius='md'
                />
                <Box overflowY='hidden'>
                    <Text
                        fontSize={['xs', 'sm']} // Making it responsive
                        fontWeight='bold'
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        maxW="90px" // Max Width
                    >
                        {props.app.name}
                    </Text>
                </Box>
                <Spacer />

                <Button
                    isDisabled={!props.editing}
                    py='10px'
                    variant='action'
                    size='xs'
                    onClick={() => props.remove(props.app)}
                >Remove</Button>
            </VStack>
        </Box>
    );
}