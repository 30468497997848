import { addNewXboxUser } from "lib/backend/api";
import { useUserLimit } from "lib/coplay/contexts/ConfigurationContext";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { AggResponse, ResponseStatus } from "lib/coplay/types/BackendTypes";
import { useState, useContext, useEffect } from "react";

export function useAddNewXboxUser(isOpen: boolean) {
    const [viewState, setViewState] = useState<"form" | "loading" | "success" | "error" | "upgrade">("form");
    const userLimit = useUserLimit();
    const { xboxUsers } = useContext(XboxUsersContext);
    const [response, setResponse] = useState<AggResponse | null>(null);

    useEffect(() => {
        if (xboxUsers.size() >= userLimit) {
            setViewState("upgrade");
        }
    }, [xboxUsers, userLimit]);

    useEffect(() => {
        if (!isOpen) {
            setViewState("form");
            setResponse(null);
        }
    }, [isOpen]);

    const handleAdd = (loginCode: string) => {
        setViewState("loading");
        addNewXboxUser(loginCode).then((response) => {
            setResponse(response);
            if (response.status !== ResponseStatus.Success) {
                setViewState("error");
            } else {
                setViewState("success");
            }
        });
    };

    return { viewState, response, handleAdd };
}