import { scheduleAction } from "lib/backend/api";
import { ScheduleFrequency, OauthActions, XboxUser } from "lib/coplay/types/BackendTypes";
import { useState } from "react";
import { createBatchRequest, createFriendingRequest, createSyncProfileRequest, createScheduleEvent } from "./scheduling-utils";

export function useScheduleAction() {
    const startMin = new Date();
    const timezoneOffset = startMin.getTimezoneOffset();
    startMin.setMinutes(startMin.getMinutes() - timezoneOffset, 0, 0);

    const endGuess = new Date(startMin);
    endGuess.setUTCMonth(startMin.getUTCMonth() + 3);

    const [start, setStart] = useState<Date>(startMin);
    const [end, setEnd] = useState<Date>(endGuess);
    const [repeat, setRepeat] = useState<boolean>();
    const [frequency, setFrequency] = useState<ScheduleFrequency>(ScheduleFrequency.Daily);
    const [scheduleName, setScheduleName] = useState<string>('');

    const repeatFrequency = repeat ? frequency : ScheduleFrequency.OneTime;

    const incrementedStart = (frequency: ScheduleFrequency, startDate: Date) => {
        const endMin = new Date(startDate);
        if (frequency === ScheduleFrequency.Daily) {
            endMin.setDate(startDate.getDate() + 1);
        } else if (frequency === ScheduleFrequency.Weekly) {
            endMin.setDate(startDate.getDate() + 7);
        } else {
            endMin.setUTCMonth(startDate.getUTCMonth() + 1);
        }
        return endMin;
    };

    const handleFrequencyStart = (frequency: ScheduleFrequency, startDate: Date = start) => {
        setFrequency(frequency);
        setStart(startDate);

        const endMin = incrementedStart(frequency, startDate);
        if (endMin > end) {
            setEnd(endMin);
        }
    };

    const handleStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = new Date(`${event.target.value}Z`);
        if (!isNaN(newDate.getTime())) {
            handleFrequencyStart(frequency, newDate);
        }
    };

    const submitSchedule = async (
        action: OauthActions,
        selectedUsers: XboxUser[],
    ) => {
        try {
            const selectedEmailAddresses = selectedUsers.map(user => user.emailAddress);

            start.setMinutes(start.getMinutes() + timezoneOffset, 0);
            end.setMinutes(end.getMinutes() + timezoneOffset);

            let request;
            switch (action) {
                case OauthActions.ClearConversations:
                    request = await createBatchRequest(action, selectedEmailAddresses);
                    break;
                case OauthActions.RemoveFriends:
                case OauthActions.SyncFriends:
                    request = await createFriendingRequest(selectedEmailAddresses, ['all'], action);
                    break;
                case OauthActions.XMProfile:
                    request = await createSyncProfileRequest(selectedEmailAddresses);
                    break;
                default:
                    throw new Error('Unsupported action type');
            }

            const event = createScheduleEvent(request, repeatFrequency, start, end, scheduleName || undefined);

            const response = await scheduleAction(event).then(response => {
                setStart(startMin);
                setEnd(endGuess);
                setRepeat(false);
                setFrequency(ScheduleFrequency.Daily);
                setScheduleName('');
                return response;
            });
            return response;
        } catch (error) {
            console.error('[useScheduleAction] error:', error);
            throw error;
        }
    };

    return {
        start,
        end,
        repeat,
        frequency,
        scheduleName,
        repeatFrequency,
        startMin,
        setEnd,
        setRepeat,
        setScheduleName,
        handleStartChange,
        handleFrequencyStart,
        incrementedStart,
        submitSchedule
    };
}