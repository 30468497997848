import { Flex, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, useDisclosure } from "@chakra-ui/react"
import { FaWallet } from "react-icons/fa"

export function LinkFundingCrumb() {
    const { isOpen, onClose } = useDisclosure();
    const onFundingClick = () => {
        // TODO: Implement funding link
    }

    return (
        <>
            {/* Uncomment when not doing manually onClick={(e) => { e.stopPropagation(); onOpen() }} */}
            <Flex _hover={{ color: 'blue.500' }} alignItems="center" color="gray.500" as="button" onClick={(e) => { e.stopPropagation() }}>
                <Icon mx="10px" as={FaWallet} boxSize={6} />
                <Text fontWeight='bold' fontSize='xs' _hover={{ fontSize: 'sm', cursor: 'not-allowed' }}>
                    Request Funding
                </Text>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Request Funding</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            Request funding to manage your subscriptions more effectively. Do you want to proceed?
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onFundingClick}>
                            Confirm
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}