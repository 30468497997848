import { ListItem, Flex, Avatar, List } from "@chakra-ui/react"
import { XMSuggestions } from "lib/coplay/types/FrontendTypes"

export function XboxGameSuggestionsList(props: {
    suggestions: XMSuggestions[],
    isLoading: boolean,
    onClick: (game: XMSuggestions) => void
}) {

    const loadingText = props.isLoading ? "Loading..." : "No results found"

    const loadingElem = (
        <ListItem p={2}>
            {loadingText}
        </ListItem>
    )

    const items = props.suggestions.map((suggest, index) => {
        return (
            <ListItem
                key={`${index}-${suggest.Title}`}
                p={2}
                _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
                onMouseDown={() => props.onClick(suggest)}
            >
                <Flex alignItems={'center'}
                >
                    <Avatar
                        name={suggest.Title}
                        marginRight="2"
                        src={suggest.ImageUrl}
                        w='50px'
                        h='50px'
                        borderRadius='20px'
                        me='16px'
                    />
                    {suggest.Title}
                </Flex>
            </ListItem>
        )
    })

    return (
        <List
            mt={2}
            borderWidth={1}
            borderRadius="md"
            zIndex={1}
            position="absolute"
            width="100%"
            bg="white"
            maxHeight={'300px'}
            overflowY='scroll'>
            {items.length > 0 ? items : loadingElem}
        </List>
    )
}