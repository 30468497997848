import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button, Flex,
    HStack
} from '@chakra-ui/react';
import { VendorMember } from 'lib/coplay/types/PaymentTypes';
import MemberDetails from './components/MemberDetails';
import MemberFinancials from './components/MemberFinancials';

interface ManageMemberModalProps {
    isOpen: boolean;
    onClose: () => void;
    member: VendorMember | null;
}

const ManageMemberModal: React.FC<ManageMemberModalProps> = ({ isOpen, onClose, member }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <HStack>
                        <Button variant="ghost" onClick={onClose}>
                            &lt;
                        </Button>
                    </HStack>
                </ModalHeader>
                <ModalBody>
                    <Flex>
                        <MemberDetails member={member} />
                        <MemberFinancials member={member} />
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ManageMemberModal;