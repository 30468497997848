import { Box, Card, Flex, Heading, HStack } from "@chakra-ui/react";
import { ProfileAppItem } from "./ProfileAppSearch";
import { SearchedSoftware } from "lib/coplay/types/BackendTypes";

export function ProfileSoftwareContainer(props: {
    apps: SearchedSoftware[],
    editing: boolean,
    remove: (app: SearchedSoftware) => void,
    softwareCategory: string
}) {

    if (props.apps.length === 0) {
        return (
            <Card border={'1px'} w='100%' h={'185px'}>
                <Flex
                    h='100%'
                    style={{ flexDirection: "column" }}
                    justifyContent='center'
                    alignItems='center'
                >
                    <Heading size='md'>
                        {`No ${props.softwareCategory}s selected`}
                    </Heading>
                    <p style={{ height: "24px" }}>
                        <SearchSuggestion
                            editing={props.editing}
                            softwareCategory={props.softwareCategory}
                        />
                    </p>
                </Flex>
            </Card>
        )
    }

    let appItems = props.apps.map((app, index) =>
        <ProfileAppItem
            key={`${app.name}-${index}`}
            app={app}
            editing={props.editing}
            remove={props.remove}
        />
    )


    return (
        <Box border={'1px'} style={{ padding: "8px 0", }} w='100%' overflowX='scroll'>
            <HStack px='10px' spacing="20px" w='100%'>
                {appItems}
            </HStack>
        </Box>
    )
}

const SearchSuggestion = (props: { editing: boolean, softwareCategory: string }) => {
    let searchSuggestion: string
    if (props.editing) {
        searchSuggestion = `Search for ${props.softwareCategory}s below`
    }

    return (
        <>
            {searchSuggestion}
        </>
    )
}