import 'lib/horizon/assets/css/App.css';
import { Route, Routes } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { AppSidebar } from 'lib/coplay/components/layout/AppSidebar';
import Brand from 'lib/horizon/components/sidebar/components/Brand';
import { ContentArea } from 'lib/coplay/layout/ContentArea';
import AppNavBar from 'lib/coplay/layout/AppNavbar';
import { XboxUsersProvider } from 'lib/coplay/contexts/XboxUsersProvider';
import SchedulesView from 'lib/coplay/views/schedules/SchedulesView';
import { CoPlaySidebarContent } from 'lib/coplay/components/layout/CoPlaySidebarContent';
import { XboxDashboard } from 'lib/coplay/views/xbox/XboxDashboard';
import { ProfilesView } from 'lib/coplay/views/profiles/ProfilesView';
import UsageView from 'lib/coplay/views/usage/XboxUsageView';
import CoPlayAccountView from 'lib/coplay/views/coplay-account/CoPlayAccountView';
import ThirdPartyXboxApiView from 'lib/coplay/views/third-party-api/ThirdPartyXboxApiView';
import NotificationsView from 'lib/coplay/views/notifications/NotificationsView';
import { DisneyPaymentsView } from 'lib/coplay/views/payments/DisneyPaymentsView';
import { XboxPaymentsView } from 'lib/coplay/views/payments/XboxPaymentsView';
// Chakra imports

export default function App() {

  return (
    <>
      <XboxUsersProvider>
        <Flex height='100vh'>

          <AppNavBar />

          <AppSidebar >
            <Brand key="Brand" />
            <CoPlaySidebarContent />
          </AppSidebar >

          <ContentArea >
            <Routes>
              <Route path='test' element={<>Test</>} />
              <Route path='dashboard' element={<XboxDashboard />} />
              <Route path='schedules' element={<SchedulesView />} />
              <Route path='profiles' element={<ProfilesView />} />
              <Route path='usage' element={<UsageView />} />
              <Route path='account' element={<CoPlayAccountView />} />
              <Route path='api' element={<ThirdPartyXboxApiView />} />
              <Route path='notifications' element={<NotificationsView />} />
              <Route path='subscriptions/disney' element={<DisneyPaymentsView />} />
              <Route path='subscriptions/xbox' element={<XboxPaymentsView />} />
            </Routes>
          </ContentArea>

        </Flex >

      </XboxUsersProvider >
    </>
  );
}