
import { useState } from "react";
import { Box, Collapse, Divider, Flex, Text } from "@chakra-ui/react";
import { ReportRenderer } from "./ReportRenderer";
import { ResponseRenderer } from "./ResponseRenderer";
import { BasicReport, BasicResponse, ResponseStatus } from "lib/coplay/types/BackendTypes";
import { formatOauthAction, getStatusColor } from "../xbox-usage-utils";

export function ResultRenderer(props: { result: BasicReport | BasicResponse, isOpen: boolean }) {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const status = 'status' in props.result ? props.result.status : props.result.statusCode;
    const action = props.result.oauthAction;
    const date = new Date(props.result.requestId).toLocaleString();


    return (
        <>
            <Divider />
            <Box onClick={() => setIsOpen(!isOpen)} p={2} cursor="pointer">
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                        <Text fontWeight="bold" mr={1}>Action:</Text>
                        <Text>{formatOauthAction(action)}</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Flex textAlign="left" mx={4} as="span" >
                            <Text fontWeight="bold" mr={1}>Date:</Text>
                            <Text>{date}</Text>
                        </Flex>

                        <Text fontWeight="bold" mr={1}>Status:</Text>
                        <Box as="span" color={getStatusColor(status)}>
                            {ResponseStatus[status]}
                        </Box>
                    </Flex>
                </Flex>
                <Collapse in={isOpen} animateOpacity>
                    <Box mt={2}>
                        {('status' in props.result) ? <ResponseRenderer response={props.result as BasicResponse} /> :
                            ('statusCode' in props.result) ? <ReportRenderer report={props.result as BasicReport} /> : 'N/A'}
                    </Box>
                </Collapse>
            </Box>
        </>
    );
}