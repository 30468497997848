import { FriendingAction, UserPrivacySettings } from "lib/coplay/types/BackendTypes"
import { useState, useEffect } from "react"
import { XboxAccountSettingsController } from "../profile-utils"
import { DefaultPrivacySettings } from "lib/coplay/utils/profile-settings-utils"

export const useXboxAccountSettings = (accountSettings: { [key: string]: any } = {
    friending: null,
    clearConversations: null
}, setSetting: Function): XboxAccountSettingsController => {
    const [friending, setFriending]
        = useState<FriendingAction | null>(accountSettings.friending)
    const [clearConversations, setClearConversations]
        = useState<boolean | null>(accountSettings.clearConversations)
    const [getEnablePrivacy, setEnablePrivacy]
        = useState<boolean>(Boolean(accountSettings.privacySettings))
    const [privacySettings, setPrivacySettings] = useState<UserPrivacySettings>(
        getEnablePrivacy ? accountSettings.privacySettings : DefaultPrivacySettings
    )

    useEffect(() => {
    }, [clearConversations]);

    return {
        friending, setFriending: setSetting(setFriending),
        clearConversations, setClearConversations: setSetting(setClearConversations),
        getEnablePrivacy,
        setEnablePrivacy: setSetting(setEnablePrivacy),
        privacySettings, setPrivacySettings: setSetting(setPrivacySettings)
    }
}