import { Box, Flex, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { XboxIcon } from "lib/coplay/components/xbox/XboxIcon";


export const FoundXboxConsolesScreen = (props: { xboxIds: string[], onSelected: (xboxId: string) => void }) => {

    if (props.xboxIds.length === 0) {
        return (<FoundConsolesZeroState />)
    }

    return (
        <FoundConsolesList xboxIds={props.xboxIds} onSelected={props.onSelected} />
    )
}


const FoundConsolesZeroState = () => {
    return (
        <>
            <Box>
                <strong>We couldn't find any Xboxes!</strong>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }}>
                    We couldn't find any xboxes linked to your account. Make sure you are logged in on the console you want to link.
                </Text>
            </Box>
        </>
    )
}

const FoundConsolesList = (props: { xboxIds: string[], onSelected: (xboxId: string) => void }) => {
    const enabledColor = useColorModeValue('#000000', 'white');
    const secondaryColor = useColorModeValue('gray.400', 'white');

    const xboxList = props.xboxIds.map((xboxId) => {
        const onClick = () => {
            props.onSelected(xboxId);
        }
        return (
            <li key={xboxId} style={{ listStyleType: 'none' }}>
                <Flex border={'1px'} style={{ padding: "10px" }} _hover={{ bg: secondaryColor, cursor: 'pointer' }} alignContent={'center'} onClick={onClick} >
                    <XboxIcon color={enabledColor} />
                    <Text alignSelf={'center'} style={{ paddingLeft: "10px" }}>Xbox ID: {xboxId}</Text>
                </Flex>
            </li>
        )
    })

    return (
        <>
            <Box height={'250px'} overflowY={'scroll'}>
                <Text pb="10px" textAlign={"center"} style={{ paddingTop: "20px" }}>
                    We found your account on the following xboxes! Select the Xbox you want to link to CoPlay
                </Text>
                {xboxList}
            </Box>
        </>
    )

}
