import { Box, Card, Flex, Icon, Spinner, Spacer, Text } from "@chakra-ui/react";
import { MonthMenu } from "./components/MonthMenu";
import { useUsageData } from "./hooks/useXboxUsage";
import { MdOutlineErrorOutline } from "react-icons/md";
import UsageActivityGraph from "./components/UsageActivityGraph";
import { RequestLogRenderer } from "./components/ResultsRenderer";

function UsageLoadingSpinner() {
    return (
        <Card alignItems='center' flexDirection='column' w='100%' justifyContent='center'>
            <Flex align='center' justify='center' w='100%' px='15px' py='10px'>
                <Spinner size="xl" />
            </Flex>
        </Card>
    );
}

function ZeroState() {
    return (
        <Card alignItems='center' flexDirection='column' w='100%' justifyContent='center'>
            <Flex align='center' justify='center' w='100%' px='15px' py='10px'>
                <Icon w='32px' h='32px' as={MdOutlineErrorOutline} color='gray.500' />
                <Box ml='10px' color='gray.500'>
                    No data available
                </Box>
            </Flex>
        </Card>
    );
}

export default function UsageView() {
    const { monthlyReport, logs, selectedMonth, setSelectedMonth, isLoading } = useUsageData();

    return (
        <Box display="flex" flexDirection="column" minH="100vh">

            <Flex p={4}>
                <Box>
                    <Text me='auto' fontSize='34px' fontWeight='700' lineHeight='100%'>
                        CoPlay XM Usage
                    </Text>
                    <Text py={2} fontSize='14px' fontWeight='400' lineHeight='100%'>
                        See how much CoPlay is doing for your Xbox users
                    </Text>
                </Box>
                <Spacer />
                <MonthMenu selectedMonth={selectedMonth} setMonth={setSelectedMonth} />
            </Flex>

            <Box mb="30px" flex="1" display="flex" flexDirection="column">
                {isLoading && <UsageLoadingSpinner />}
                {!isLoading && monthlyReport && logs && (
                    <>
                        <UsageActivityGraph monthlyReport={monthlyReport} month={selectedMonth} />
                        <Box mt="20px">
                            <RequestLogRenderer requestLog={logs} />
                        </Box>
                    </>
                )}
                {!isLoading && !monthlyReport && <ZeroState />}
            </Box>
        </Box >
    );
}

