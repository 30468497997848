import React from 'react';
import { Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { IoGameController } from 'react-icons/io5';

interface AuthHeaderProps {
    title: string;
    subtitle: string;
}

export const AuthHeader: React.FC<AuthHeaderProps> = ({ title, subtitle }) => {
    const brandColor = useColorModeValue("brand.500", "brand.400");
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <>
            <IoGameController size='120px' color={brandColor} />
            <Heading color={textColor} fontSize='36px' px='10px' maxWidth='50%' textAlign='center'>
                {title}
            </Heading>
            <Text
                mb='26px'
                ms='4px'
                color={"gray.400"}
                fontWeight='400'
                fontSize='md'
                textAlign='center'>
                {subtitle}
            </Text>
        </>
    );
};