import { Box, Flex, Text } from "@chakra-ui/react";
import { BasicReport, BasicResponse } from "lib/coplay/types/BackendTypes";

export function ReportRenderer(props: { report: BasicReport }) {
    return (
        <Box mt={2}>
            <Flex flexWrap="wrap" gap={4}>
                <Flex alignItems="center">
                    <Text fontWeight="bold" mr={1}>Num Users:</Text>
                    <Text>{props.report.numUsers}</Text>
                </Flex>
                <Flex alignItems="center">
                    <Text fontWeight="bold" mr={1}>Num Success:</Text>
                    <Text>{props.report.numSuccess}</Text>
                </Flex>
                <Flex alignItems="center">
                    <Text fontWeight="bold" mr={1}>Num Fails:</Text>
                    <Text>{props.report.numFails}</Text>
                </Flex>
            </Flex>

            {props.report.errors && props.report.errors.length > 0 && (
                <Box mt={4}>
                    <Text fontWeight="bold" mb={2}>Errors:</Text>
                    {props.report.errors.map((error: BasicResponse, index: number) => (
                        <Box key={index} mb={4} pl={2} borderLeft="2px" borderColor="red.500">
                            <Text fontWeight="bold">Email: {error.emailAddress}</Text>
                            {error.error && (
                                <>
                                    <Text>User Message: {error.error.userMessage}</Text>
                                    <Text>Error Message: {error.error.errorMessage}</Text>
                                    <Text>Suggestion: {error.error.suggestion}</Text>
                                </>
                            )}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
}