import { changeSettings, clearConversations } from "lib/backend/api";
import { getXboxUserData } from "lib/backend/firebase/service";
import { XboxUser, XboxPeople, XBLCollatedConversations, XboxConsole, UserPrivacySettings, Privilege, XboxFriends } from "lib/coplay/types/BackendTypes";
import { useState, useEffect } from "react";

export const useXboxDrawer = (emailAddress: string) => {
    const [user, setUser] = useState<XboxUser | null>(null);
    const [friends, setFriends] = useState<XboxFriends | null>(null);
    const [conversations, setConversations] = useState<XBLCollatedConversations | null>(null);
    const [xboxConsole, setXboxConsole] = useState<XboxConsole | null>(null);
    const [settings, setSettings] = useState<UserPrivacySettings | null>(null);
    const [privileges, setPrivileges] = useState<Privilege[]>([])

    useEffect(() => {
        const unsubUser = getXboxUserData().subscribeToXboxUser(emailAddress, setUser);
        const unsubFriends = getXboxUserData().subscribeToXboxFriends(emailAddress, setFriends);
        const unsubConversations = getXboxUserData().subscribeToConversations(emailAddress, setConversations)
        const unsubSettings = getXboxUserData().subscribeToSettings(emailAddress, setSettings);
        const unsubscribePrivileges = getXboxUserData().subscribeToPrivileges(emailAddress, setPrivileges)
        const unsubConsole = getXboxUserData()
            .getDeviceSidekick()
            .subscribeToDeviceDetails(emailAddress, setXboxConsole);

        return () => {
            unsubUser();
            unsubFriends();
            unsubConversations();
            unsubSettings();
            unsubscribePrivileges()
            unsubConsole();
        }

    }, [emailAddress]);

    // TODO: Move async
    const updateNotes = (notes: string) => {
        if (user)
            getXboxUserData().updateNotes(user, notes);
    }

    const updateSettings = async (settings: UserPrivacySettings) => {
        if (user)
            await changeSettings([user.emailAddress], settings);
    }

    const clearConvos = async () => {
        if (user)
            await clearConversations([user.emailAddress]);
    }

    return {
        user,
        friends,
        conversations,
        xboxConsole,
        settings,
        privileges,
        updateNotes,
        updateSettings,
        clearConvos
    }
}
