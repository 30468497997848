import { Box, Button, Flex, Spacer, Text } from "@chakra-ui/react";
import { XBLCollatedConversations, XBLConversation } from "lib/coplay/types/BackendTypes";
import { isLastMessageText } from "lib/coplay/utils/utils";
import { useState } from "react";

// TODO: Add zero state and loading/submitting state
export function XboxAccountMessages(props: { conversations?: XBLCollatedConversations, onClearConversations: () => Promise<void> }) {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClearConversations = async () => {
        setIsSubmitting(true);
        props.onClearConversations().finally(() => setIsSubmitting(false));
    }

    const primaryMessages: JSX.Element[] =
        createMessageElement(props.conversations?.primary.conversations)
    const secondaryMessages: JSX.Element[] =
        createMessageElement(props.conversations?.secondary.conversations)
    const groupedMessages: JSX.Element[] =
        createMessageElement(props.conversations?.grouped.conversations)

    const noMessagesElem = <Text>No messages</Text>

    return (
        <Box mb='4'>
            <Flex wrap='wrap'>
                <Box>
                    <Text as='h3' fontSize='lg' fontWeight='bold'>Conversations</Text>
                    <Text fontWeight='500'>See the last message sent in each conversation the user is having.</Text>
                </Box>

                <Spacer />
                <Button mb='1' variant='action' onClick={handleClearConversations} isLoading={isSubmitting}>
                    Clear Conversations
                </Button>
            </Flex>
            <Box p='4' maxHeight='400px' overflowY='scroll' bg='gray.100'>
                <Flex key='primary'>
                    <Text fontWeight='bold'>Primary Inbox</Text>
                </Flex>
                {primaryMessages.length > 0 ? primaryMessages : noMessagesElem}

                <Flex key='secondary' py="10px">
                    <Text fontWeight='bold'>Secondary Inbox</Text>
                </Flex>
                {secondaryMessages.length > 0 ? secondaryMessages : noMessagesElem}

                <Flex key='secondary' py="10px">
                    <Text fontWeight='bold'>Group Chats</Text>
                </Flex>
                {groupedMessages.length > 0 ? groupedMessages : noMessagesElem}
            </Box>
        </Box>
    )
}


function createMessageElement(conversations?: XBLConversation[]) {
    const elems: JSX.Element[] = [];

    if (conversations) {
        for (var convo of conversations) {
            const msgText = isLastMessageText(convo)
                ? convo.lastMessage.contentPayload.content?.parts[0].text as string
                : `(Message is not text)`

            elems.push(
                <Box
                    border='1px solid'
                    borderColor='gray.200'
                    borderRadius='md'
                    p='1'
                    mb='2'
                    bg='white'
                    boxShadow='sm'
                    key={convo.conversationId}
                >
                    <Text fontWeight='medium' color='gray.700'>{msgText}</Text>
                </Box>
            )
        }
    }
    return elems;
}