import { User } from "firebase/auth";
import { collection, doc, documentId, Firestore, getDoc, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { XboxDeviceSidekick } from "./xbox-device-sidekick";
import { BasicResponse, XboxPeople, LogEntry, OauthActions, UserPrivacySettings, XBLCollatedConversations, XboxUser } from "lib/coplay/types/BackendTypes";
import { convertDocumentToXboxUser } from "lib/coplay/utils/utils";

// Todo: Consolidate into XboxData with members variables for users, consoles, schedules, etc.
export class XboxUserData {
    _user: User;
    _db: Firestore;

    _xboxDeviceSidekick: XboxDeviceSidekick;

    constructor(user: User, db: Firestore) {
        this._user = user;
        this._db = db;
        this._xboxDeviceSidekick = new XboxDeviceSidekick(user, db);
    }


    //
    // PATHS
    //

    // TODO: Possbibly make these global constants
    getXboxUserCollectionPath = () => {
        return `${this._user.email}/data/users`;
    }

    getXboxUserPath = (emailAddress: string) => {
        return `${this.getXboxUserCollectionPath()}/${emailAddress}`;
    }

    getXboxFriendsCollectionPath = () => {
        return `${this._user.email}/data/friends/`;
    }

    getXboxConversationsCollectionPath = () => {
        return `${this._user.email}/data/conversations/`;
    }

    getXboxPrivacyCollectionPath = () => {
        return `${this._user.email}/data/privacy/`;
    }

    getXboxUserErrorDetailsPath = (action: OauthActions, requestId: number, emailAddress: string) => {
        return `${this._user.email}/data/errors/${action}/${requestId}/${emailAddress}`;
    }


    //
    // COLLECTIONS
    //


    getXboxUsersCollectionRef = () => {
        return collection(this._db, this.getXboxUserCollectionPath());
    }

    getXboxUserError = () => {
        const xboxUsersCollectionRef = this.getXboxUsersCollectionRef();
        const unsubscribe = onSnapshot(xboxUsersCollectionRef, (snapshot) => {
            console.log(snapshot.docs.map((doc) => doc.data()));
        });
        return unsubscribe;
    }

    getDeviceSidekick = () => {
        return this._xboxDeviceSidekick;
    }


    //
    // Data
    //


    deleteDeviceCrumb = async (emailAddress: string) => {
        const userPath = this.getXboxUserPath(emailAddress);
        const userDoc = doc(this._db, userPath);
        const userSnapshot = await getDoc(userDoc);

        if (!userSnapshot.exists()) {
            throw new Error(`Could not find user for ${emailAddress}`);
        }
        const userData = userSnapshot.data() as XboxUser;
        delete userData.consoleOS;
        delete userData.consoleId;
        delete userData.consoleName;
        delete userData.consoleMsg;
        delete userData.consoleState;

        await setDoc(userDoc, userData);
    }

    subscribeToXboxUser = (emailAddress: string, callback: (xboxUser: XboxUser) => void) => {
        try {
            let path = `${this._user.email}/data/users/`;
            const q = query(collection(this._db, path), where(documentId(), "==", emailAddress));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const user = convertDocumentToXboxUser(snapshot.docs[0]);
                if (user) callback(user)
            });
            return unsubscribe;
        } catch (e) {
            console.error(`[subscribeToXboxUser] Error: ${e}`);
        }
    }

    subscribeToXboxFriends = (emailAddress: string, callback: (friends: XboxPeople) => void) => {
        try {
            const q = query(collection(this._db, this.getXboxFriendsCollectionPath()), where(documentId(), "==", emailAddress));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const data = snapshot.docs[0].data() as XboxPeople;
                callback(data);
            });
            return unsubscribe;
        } catch (e) {
            console.error(`[subscribeToXboxFriends] Error: ${e}`);
        }
    }

    subscribeToConversations = (emailAddress: string, callback: (conversations: XBLCollatedConversations) => void) => {
        try {
            const q = query(collection(this._db, this.getXboxConversationsCollectionPath()), where(documentId(), "==", emailAddress));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                if (snapshot.empty)
                    return callback(null);
                else
                    callback(snapshot.docs[0].data() as XBLCollatedConversations);
            });
            return unsubscribe;
        } catch (e) {
            console.error(`[subscribeToConversations] Error: ${e}`);
        }
    }

    subscribeToSettings = (emailAddress: string, callback: (settings: UserPrivacySettings) => void) => {
        try {
            const q = query(collection(this._db, this.getXboxPrivacyCollectionPath()), where(documentId(), "==", emailAddress));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                if (snapshot.empty) {
                    return callback(null);
                } else
                    callback(snapshot.docs[0].data() as UserPrivacySettings);
            });
            return unsubscribe;
        } catch (e) {
            console.error(`[subscribeToSettings] Error: ${e}`);
        }
    }

    subscribeToPrivileges = (emailAddress: string, setPrivileges: Function) => {
        try {
            return onSnapshot(
                doc(this._db, `${this._user.email}/data/privleges/${emailAddress}`),
                (snapshot) => setPrivileges(snapshot.data().privleges)
            )
        } catch (error) {
            console.error(`[subscribePrivileges] Error: ${error}`)
        }
    }

    subscribeToUserLogs = (emailAddress: string, callback: (logs: LogEntry[]) => void) => {
        try {
            const userLogsRef = doc(this._db, `${this._user.email}/data/userLogs/${emailAddress}`);
            const unsubscribe = onSnapshot(userLogsRef, (snapshot) => {
                let logs: LogEntry[] = [];

                logs = (snapshot.exists() && snapshot.data().entries) ? (snapshot.data().entries as LogEntry[]) : [];
                callback(logs);
            });
            return unsubscribe;
        } catch (e) {
            console.log(`[subscribeToUserLogs] Error: ${e}`);
        }
    }

    updateNotes = async (xboxUser: XboxUser, notes: string) => {
        try {
            const userRef = doc(this._db, this.getXboxUserPath(xboxUser.emailAddress));
            await updateDoc(userRef, {
                notes: notes
            });
        } catch (e) {
            console.error(`[updateNotes] Error: ${e}`);
        }
    };

    getXboxUserErrorDetails = async (action: OauthActions, requestId: number, emailAddress: string) => {
        try {
            const docRef = doc(
                this._db,
                this.getXboxUserErrorDetailsPath(action, requestId, emailAddress));
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                return docSnapshot.data() as BasicResponse;
            } else {
                return null;
            }
        } catch (e) {
            console.log(`[getXboxUserErrorDetails] Error: ${e}`);
        }
    };
}
