import { Text } from "@chakra-ui/react"
import { ScheduleEvent } from "lib/coplay/types/BackendTypes"

function StartTime(props: { localeTime: string }) {
    return (
        <>
            {props.localeTime.slice(0, -6)} {props.localeTime.slice(-2)}
        </>
    )
}

export function SchedulingTimingDetails(props: { selectedSchedule: ScheduleEvent }) {
    const startDate = new Date(props.selectedSchedule.startDate)

    return (
        <>
            <Text fontSize="md">From: {startDate.toLocaleDateString('en-US')}</Text>
            <Text fontSize="md">To: {new Date(props.selectedSchedule.endDate).toLocaleDateString('en-US')}</Text>
            <p>
                Start Time: <StartTime localeTime={startDate.toLocaleTimeString()} />
            </p>
        </>
    )
}