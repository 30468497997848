import { Text } from "@chakra-ui/react";
import { VendorSubscription } from "lib/coplay/types/PaymentTypes";

export function SubscriptionRenderer(props: { subscriptions: VendorSubscription[] }) {

    if (props.subscriptions.length === 0) {
        return (
            <Text px="15px" fontWeight='bold' fontSize='sm'>
                No active subscriptions
            </Text>
        )
    }

    // Only show the first subscription?
    return (
        <>
            <Text px="15px" fontWeight='bold' fontSize='sm'>
                {props.subscriptions[0].name}
            </Text>
            <Text px="15px" fontWeight='bold' fontSize='sm'>
                Days Left: {props.subscriptions[0].daysRemaining}
            </Text>
        </>
    )
}