import { FormControl, FormLabel, RadioGroup, Stack, Radio, Checkbox, Flex, Text } from "@chakra-ui/react"
import { FriendingAction } from "lib/coplay/types/BackendTypes"
import { XboxAccountSettingsController } from "../profile-utils"
import PrivacySwitch from "./ProfilePrivacySwitch"
import { PrivacySettings } from "./ProfilePrivacySettings"

export function ProfileUserSettings(props: {
    editing: boolean, controller: XboxAccountSettingsController
}) {

    return (
        <>
            <FormControl>
                <Text py='10px' fontSize={"xl"} fontWeight="black"> Account Settings </Text>

                <FormLabel>Friending options</FormLabel>

                <RadioGroup
                    isDisabled={!props.editing}
                    defaultValue={props.controller.friending}
                    onChange={(value) => props.controller.setFriending(value as FriendingAction)}
                >
                    <Stack>
                        <Radio value={FriendingAction.RemoveAll}>Remove All Friends</Radio>
                        <Radio value={FriendingAction.SyncAll}>Sync Friends to CoPlay XM</Radio>
                        {/* <Radio value={FriendingAction.AddAll}>Sync Friends to other users with same profile</Radio> */}
                    </Stack>
                </RadioGroup>

            </FormControl>

            <FormControl>
                <FormLabel>Conversations And Messages</FormLabel>
                <Checkbox
                    isDisabled={!props.editing}
                    onChange={() => props.controller.setClearConversations(!props.controller.clearConversations)}
                    defaultChecked={props.controller.clearConversations}
                    checked={props.controller.clearConversations}
                >
                    Clear all conversations and messages
                </Checkbox>
            </FormControl>

            <FormControl>
                <FormLabel>Privacy Settings</FormLabel>
                <PrivacySwitch
                    editing={props.editing} controller={props.controller}
                />
                <PrivacySettings
                    editing={props.editing} controller={props.controller}
                />
            </FormControl>

            <Flex w='100%' direction={'row'} justifyContent='left' alignItems='center'>
                <Text pe='10px' py='10px' fontSize={"xl"} justifyContent='left' fontWeight="black"> Xbox Console Settings </Text>
            </Flex>
        </>
    )
}