import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Button, Text } from "@chakra-ui/react";
import { useCreateKeyModal } from "./useCreateKeyHook";
import { CoPlayLoadingSpinner } from "lib/coplay/components/CoPlayLoading";
import { CreateKeyResponseScreen } from "./CreateKeyResponseScreen";

export default function CreateKeyModal(props: {
    isOpen: boolean,
    onClose: () => void,
}) {
    const {
        viewState,
        response,
        newKeyName,
        setNewKeyName,
        resetModal,
        generateKey,
    } = useCreateKeyModal(); // Use the hook

    const handleClose = () => {
        resetModal();
        props.onClose();
    };

    return (
        <Modal isOpen={props.isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create new secret key</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {viewState === 'loading' && <CoPlayLoadingSpinner />}
                    {viewState === 'error' && <Text>Hmmm... Something went wrong. Try again later</Text>}
                    {viewState === 'success' && <CreateKeyResponseScreen response={response!} />}
                    {viewState === 'form' && (
                        <FormControl mb={4}>
                            <FormLabel>Name (Optional)</FormLabel>
                            <Input
                                value={newKeyName}
                                onChange={(e) => setNewKeyName(e.target.value)}
                                placeholder="My Test key" />
                        </FormControl>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={handleClose}>
                        {viewState === 'success' ? 'Close' : 'Cancel'}
                    </Button>
                    {viewState === 'form' && (
                        <Button colorScheme="teal" onClick={generateKey}>
                            Create secret key
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}