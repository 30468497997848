import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Text } from "@chakra-ui/react";
import { getXboxUserData } from "lib/backend/firebase/service";
import { CoPlayLoadingSpinner } from "lib/coplay/components/CoPlayLoading";
import { BasicResponse, OauthActions, XboxUser } from "lib/coplay/types/BackendTypes";
import { useEffect, useState } from "react";
import { XboxStatusRefreshButton, XboxStatusResetButton } from "./components/XboxUserStatusButtons";


const useXboxStatusModal = (props: {
    lastAction: OauthActions,
    lastRequestId: number,
    emailAddress: string,
    onClose: () => void
}) => {
    const [errorDetails, setErrorDetails] = useState<BasicResponse | null>(null);
    const [view, setView] = useState<JSX.Element>(<CoPlayLoadingSpinner />)

    const onOpen = () => {
        getXboxUserData().
            getXboxUserErrorDetails(props.lastAction, props.lastRequestId, props.emailAddress).then((errorDetails) => {
                if (errorDetails == null) {
                    setView(<EmptyErrorView />)
                    setErrorDetails(null)
                } else {
                    setErrorDetails(errorDetails)
                    setView(<ErrorDetailsView errorDetails={errorDetails} >
                        <XboxStatusRefreshButton emailAddress={props.emailAddress} setView={setView} onClose={props.onClose} />
                        <XboxStatusResetButton emailAddress={props.emailAddress} setView={setView} onClose={props.onClose} />
                    </ErrorDetailsView>)
                }
            })
    }

    useEffect(() => {
        onOpen()
    }, [props.lastAction, props.lastRequestId, props.emailAddress])


    return {
        view,
    };
}


export function XboxStatusModal(props: { user: XboxUser, onClose: () => void }) {
    const { view } = useXboxStatusModal({
        lastAction: props.user.userStatus.lastAction.oauthAction,
        lastRequestId: props.user.userStatus.lastAction.requestID,
        emailAddress: props.user.emailAddress,
        onClose: props.onClose
    });


    return (
        <>
            <Modal isOpen={true} onClose={props.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>{props.user.userStatus.statusMessage} Details</ModalHeader>
                    {view}
                </ModalContent>
            </Modal>
        </>
    )
}


function EmptyErrorView() {
    return (
        <ModalBody>
            <Text>No error details found</Text>
            <Text>Please Refresh and try again</Text>
        </ModalBody>
    )
}

function ErrorDetailsView(props: { errorDetails: BasicResponse, children?: React.ReactNode }) {
    const userMessage = props.errorDetails.error?.userMessage || "No user message"
    const errorMessage = props.errorDetails.error?.errorMessage || "No error message"
    const suggestion = props.errorDetails.error?.suggestion || "No suggestion"

    return (
        <>
            <ModalBody>
                <Text py='10px' fontWeight='bold'>{props.errorDetails.emailAddress}</Text>
                <Text py='10px'>{userMessage}</Text>
                <Text py='10px'>{suggestion}</Text>
                <details>
                    <summary>Click for more details</summary>
                    <Text>{errorMessage}</Text>
                </details>
            </ModalBody>
            <ModalFooter>
                {props.children}
            </ModalFooter>
        </>
    )
}