import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Collapse, Flex, Icon, IconButton, List, ListItem, Text, useColorModeValue } from "@chakra-ui/react";
import { AddSubscriptionsModal } from "lib/coplay/views/payments/AddSubscriptionsModal";
import { useState } from "react";
import { FaXbox } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

export function CoPlaySidebarContent() {
    const [platformOpen, setPlatformOpen] = useState(true);

    return (

        <Box
            as="aside"
            borderLeft="1px solid"
            borderColor="gray.200"
            pl={4}
            pt={2}
        >
            {/* Example Section: Platform */}
            <Box mb={8} w='100%'>
                <Flex align="center" justify="space-between" cursor="pointer" onClick={() => setPlatformOpen(!platformOpen)} >
                    <Text fontSize="md" fontWeight="semibold" textTransform="uppercase" color="gray.600">
                        Platforms
                    </Text>
                    <IconButton
                        size="sm"
                        variant="ghost"
                        icon={platformOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        aria-label="Toggle Platform Menu"
                    />
                </Flex>

                {/* Xbox Header */}
                <Box pl={2} fontSize={'md'} fontWeight={'bold'}>
                    <Flex alignItems={'center'} >
                        <Flex h={7} w={7} alignItems={'center'} justifyContent={'center'} borderRadius={4}  >
                            <Icon zIndex={4} size={6} color={'green.500'} as={FaXbox} />
                        </Flex>
                        <Text ml={2} >Xbox</Text>
                    </Flex>
                </Box>

                <Collapse in={platformOpen} animateOpacity >
                    <List ml={5} spacing={2} mt={2} pl={4} borderLeft="1px solid" borderColor="gray.200">
                        <CoPlayListItem path="/app/dashboard" label="Dashboard" />
                        <CoPlayListItem path="/app/profiles" label="Profiles" />
                        <CoPlayListItem path="/app/schedules" label="Schedules" />
                        <CoPlayListItem path="/app/usage" label="Usage" />
                        <CoPlayListItem path="/app/api" label="Developer" />
                    </List>
                </Collapse>
            </Box>


            {/* Other Sections */}
            <Box mb={8}>
                <Text fontSize="md" fontWeight="semibold" textTransform="uppercase" color="gray.600" mb={2}>
                    Subscriptions
                </Text>
                <List spacing={2}>
                    <CoPlayListItem path="/app/subscriptions/disney" label="Disney+" />
                    <CoPlayListItem path="/app/subscriptions/xbox" label="Xbox Game Pass" />
                    <AddSubscriptionsModal />
                </List>
            </Box>

            {/* Settings */}
            <Box mb={8}>
                <Text fontSize="md" fontWeight="semibold" textTransform="uppercase" color="gray.600" mb={2}>
                    Settings
                </Text>
                <List spacing={2}>
                    <CoPlayListItem path="/app/notifications" label="Notifications" />
                    <CoPlayListItem path="/app/account" label="Account" />
                </List>
            </Box>

            <Box>
                <Text fontSize="md" fontWeight="semibold" textTransform="uppercase" color="gray.600" mb={2}>
                    Support
                </Text>
                <List spacing={2}>
                    <CoPlayListItem path="https://coplay.io/blog/" label="Help Center" />
                </List>
            </Box>

        </Box >
    )
}

function CoPlayListItem(props: { path: string, label: string }) {
    const location = useLocation();
    const isActive = location.pathname === props.path;
    const brandColor = useColorModeValue('brand.600', 'brand.500');

    return (
        <ListItem
            cursor="pointer"
            fontSize="md"
            h='25px'
            _hover={{ color: brandColor }}
            fontWeight={isActive ? "bold" : "normal"}
            borderRight={isActive ? "4px solid blue" : "none"}
            w='100%'
        >
            <Box as={Link} to={props.path} display="block" w="100%" h="100%">
                {props.label}
            </Box>
        </ListItem>
    )
}