import React from "react";
import { VStack, Heading, Avatar, Divider, Box, Text } from "@chakra-ui/react";
import { VendorMember, SupportedVendors } from "lib/coplay/types/PaymentTypes";
import DisneyPlus from '../../../../assets/disney_plus_icon.png';
import XboxIcon from '../../../../assets/xbox_icon.png';
import { getFundingSource } from "../../payment-utils";

interface MemberDetailsProps {
    member: VendorMember;
}

const MemberDetails: React.FC<MemberDetailsProps> = (props: { member: VendorMember }) => {

    return (
        <>
            <VStack width={'380px'}>
                <Heading as='h3' size='lg'>{props.member.vendor}</Heading>
                <Avatar
                    src={props.member.vendor === SupportedVendors.Disney ? DisneyPlus : XboxIcon}
                    size="xl"
                    mr={2}
                />
                <Divider />

                <Heading as='h4' size='md'>{props.member.email}</Heading>

                <Text>Status: <strong>Ready</strong></Text>

                <Box w='85%'>
                    <Text fontWeight="bold">Card Note</Text>
                    <Text>{props.member.notes || 'Add a note...'}</Text>
                </Box>
                <Box w='85%'>
                    <Text fontWeight="bold">Aggregated</Text>
                    <Text>{new Date(props.member.createdAt).toLocaleString()}</Text>
                    <Text fontWeight="bold">Updated on</Text>
                    <Text>{new Date(props.member.updatedAt).toLocaleString()}</Text>
                </Box>
                <Box w='85%' py={2}>
                    <Text pb={2} fontWeight="bold">Funding Source: </Text>
                    {getFundingSource(props.member)}
                </Box>
            </VStack>
        </>
    );
};

export default MemberDetails;
