import { DocumentData } from "firebase/firestore";
import { ConsoleCrumb, LastTextMessage, ResponseStatus, XboxUser, XMError } from "../types/BackendTypes";

// updatedAt is actually a firebase timestamp
// convert it to a Date object
// SUPER HACK
export function convertDocumentToXboxUser(doc: DocumentData) {
    const user = doc.data()
    try {
        user.updatedAt = user.updatedAt.toDate();
        return user as XboxUser;
    } catch (e) {
        console.error("Error converting document to user: ", doc.id, e, user)
    }
    return null;
}

// HACK: this is a temporary function to check if a response is a Coplay response
// (to handle certain kinds of non standard error codes)
export function isCoplayResponse(status: number) {
    const converted = String(status)

    for (const coplayStatus in ResponseStatus) {
        if (converted === coplayStatus)
            return true
    }

    return false
}

export function isXMError(error: any): error is XMError {
    return error && error.userMessage && error.errorMessage && error.suggestion;
}

export function emptyXMError(): XMError {
    return {
        userMessage: "",
        errorMessage: "",
        suggestion: ""
    }
}

export function isStandardError(error: any): error is Error {
    return error && error.message && error.stack;
}

// TODO: Yikes. What a hack. Fix this later.
export function createXMError(
    error: any,
    userMessage: string = "Uh Oh, something went wrong!",
    suggestion: string = "Try Again Later"
): XMError {
    // Check if error
    if (isStandardError(error)) {
        return {
            userMessage: userMessage,
            errorMessage: error.toString(),
            suggestion: suggestion
        }
    }
    // check if object
    if (typeof (error) === "object") {
        return {
            userMessage: userMessage,
            errorMessage: JSON.stringify(error),
            suggestion: suggestion
        }
    }

    if (typeof (error) === "string") {
        return {
            userMessage: userMessage,
            errorMessage: error,
            suggestion: suggestion
        }
    }

    if (isXMError(error)) {
        return error;
    }

    return {
        userMessage: "Unknown Error",
        errorMessage: "Unknown Error",
        suggestion: "Unknown Error"
    }
}

export function createConsoleCrumb(user: XboxUser): ConsoleCrumb {
    return {
        consoleId: user.consoleId,
        consoleName: user.consoleName,
        consoleOS: user.consoleOS,
        consoleState: user.consoleState,
        consoleMsg: user.consoleMsg,
    }
}

// shared
export function isLastMessageText(conversation: any): conversation is LastTextMessage {
    if (conversation.lastMessage && conversation.lastMessage.contentPayload
        && conversation.lastMessage.contentPayload.content
        && conversation.lastMessage.contentPayload.content.parts
        && conversation.lastMessage.contentPayload.content.parts.length > 0 &&
        conversation.lastMessage.contentPayload.content.parts[0].contentType === "text") {
        return true;
    } else {
        return false;
    }
}