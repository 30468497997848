import { Button, Image, Modal, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { AggregateXboxUserModalScreen } from "./components/AggregateXboxUserModalScreen";
import { UpgradeYourPlanModalScreen } from "./components/UpgradePlanModalScreen";
import { CoPlayLoadingSpinner } from "lib/coplay/components/CoPlayLoading";
import { AggregationSuccessScreen } from "./components/AggregationSuccessScreen";
import { AggregationErrorModalScreen } from "./components/AggregationErrorModalScreen";
import { useAddNewXboxUser } from "./hooks/useAddNewXboxUser";
import XboxIcon from 'lib/coplay/assets/xbox_icon.png';


const xboxUserIcon = <Image src={XboxIcon} width={7} height={7} borderRadius='full' />

export function AddNewXboxUserButton() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button variant='action' color='green.600' onClick={onOpen} leftIcon={xboxUserIcon}>Add Xbox User</Button>
            <AddNewXboxUserModal isOpen={isOpen} onClose={onClose} />
        </>
    );
}


export function AddNewXboxUserModal(props: {
    isOpen: boolean,
    onClose: () => void,
}) {
    const { viewState, response, handleAdd } = useAddNewXboxUser(props.isOpen);

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                {viewState === "form" && <AggregateXboxUserModalScreen close={props.onClose} handler={handleAdd} />}
                {viewState === "loading" && <CoPlayLoadingSpinner />}
                {viewState === "success" && <AggregationSuccessScreen close={props.onClose} response={response} />}
                {viewState === "error" && <AggregationErrorModalScreen response={response} close={props.onClose} />}
                {viewState === "upgrade" && <UpgradeYourPlanModalScreen />}
            </ModalContent>
        </Modal>
    );
}