import { Box, ListItem, Tabs, TabList, Tab, TabPanels, TabPanel, List, Flex, VStack, Button, Text } from "@chakra-ui/react";
import { InstalledSoftware, BasicResponse, InstallEvent, XboxConsole } from "lib/coplay/types/BackendTypes";
import { useState, useEffect } from "react";
import { MemoSoftwareItem } from "./DeviceSoftwareItem";
import { uninstallSoftware } from "lib/backend/api";

function useListSelection(installedApps: InstalledSoftware[], installedGames: InstalledSoftware[]) {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const hasSelected: boolean = selectedItems.length > 0;

    useEffect(() => {
        setSelectedItems([]);
    }, [installedApps, installedGames])

    const handleItemSelect = (itemId: string, isSelected: boolean) => {
        setSelectedItems((prevSelected) =>
            isSelected
                ? [...prevSelected, itemId]
                : prevSelected.filter((id) => id !== itemId)
        );
    };

    return { selectedItems, handleItemSelect, hasSelected };
}

function useUninstall(emailAddress: string, consoleId: string, selectedItems: string[]) {
    const [isUninstalling, setIsUninstalling] = useState<boolean>(false);
    const [hasUninstallError, setHadError] = useState<boolean>(false);

    if (selectedItems.length === 0 || isUninstalling) {
        return { isUninstalling, uninstallSelected: () => { } };
    }

    const uninstallSelected = () => {
        setIsUninstalling(true);
        uninstallSoftware(emailAddress, consoleId, selectedItems)
            .then((result: BasicResponse) => {
                setIsUninstalling(false);
                setHadError(false);
            })
            .catch((error: any) => {
                setIsUninstalling(false);
                setHadError(true);
                console.error("Error uninstalling software: ", error);
            });
    };

    return { isUninstalling, uninstallSelected, hasUninstallError };
}

export const DeviceDetailsList = (props: { emailAddress: string, console: XboxConsole }) => {
    const { selectedItems, handleItemSelect, hasSelected } = useListSelection(props.console.installedApps, props.console.installedGames);
    const { isUninstalling, uninstallSelected, hasUninstallError } = useUninstall(props.emailAddress, props.console.id, selectedItems);
    const consoleLogs = props.console.logs

    const isEmptySoftwareList = (softwareList: InstalledSoftware[]) => {
        return softwareList === undefined || softwareList.length === 0;
    }

    if (isEmptySoftwareList(props.console.installedApps)
        && isEmptySoftwareList(props.console.installedGames)) {
        return (
            <Box>
                <Text>Hmmm... No Software Installed</Text>
            </Box>
        )
    }

    const installingGames = props.console.installedGames.filter(game => game.isInstalling);
    const installedGames = props.console.installedGames.filter(game => !game.isInstalling);

    const games: JSX.Element[] = [];
    const apps: JSX.Element[] = [];

    const createSoftwareItem = (software: InstalledSoftware) => {
        return <MemoSoftwareItem
            key={software.instanceId}
            emailAddress={props.emailAddress}
            consoleId={props.console.id}
            software={software}
            isSelected={selectedItems.includes(software.instanceId)}
            onSelect={(isSelected) => handleItemSelect(software.instanceId, isSelected)}
            isUninstalling={isUninstalling}
        />
    }
    if (installingGames.length > 0)
        games.push(<Text key="installing-heading" fontWeight='bold' fontSize='md'>Installing...</Text>);

    if (installingGames.length > 0) {
        installingGames.forEach(element => {
            games.push(createSoftwareItem(element));
        });

    }

    if (installedGames.length > 0)
        games.push(<Text key="installed-heading" fontWeight='bold' fontSize='md'>Installed</Text>);

    installedGames.forEach(element => {
        games.push(createSoftwareItem(element));
    });
    props.console.installedApps.forEach(element => {
        apps.push(createSoftwareItem(element))
    });

    const renderInstallEvents = (events: InstallEvent[]) => {
        return events.map((event, index) => (
            <ListItem key={index}>
                <Text fontWeight='bold'>{event.productName}</Text>
                <Text fontSize='sm'>Event: {event.eventType}</Text>
                <Text fontSize='sm'>Time: {new Date(event.eventTime).toLocaleString()}</Text>
            </ListItem>
        ));
    };

    console.log(`Disabled: ${!hasSelected || isUninstalling}`)

    return (
        <Box flexGrow={1}>
            <Text fontWeight='bold' fontSize='md' py='10px' textAlign={'center'}>Installed Software</Text>

            <Tabs isFitted variant='enclosed'>
                <TabList mb='1em'>
                    <Tab key='Games'>Games & Other</Tab>
                    <Tab key='AppsOther'>Apps</Tab>
                    <Tab key='Logs'>Logs</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <List spacing={3} height="325px" overflow={"scroll"}>
                            {games}
                        </List>
                    </TabPanel>
                    <TabPanel>
                        <List spacing={3} height="325px" overflow={"scroll"}>
                            {apps}
                        </List>
                    </TabPanel>
                    <TabPanel>
                        <List spacing={3} height="325px" overflow={"scroll"}>
                            {renderInstallEvents(consoleLogs.installEvents)}
                        </List>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <Flex flexDirection={'column'} alignItems='center' py='5px'>
                <VStack>
                    <Button
                        textAlign={'center'}
                        colorScheme="red"
                        size={"md"}
                        variant="outline"
                        isDisabled={!hasSelected || isUninstalling}
                        onClick={uninstallSelected}
                        isLoading={isUninstalling}
                        loadingText='Submitting'
                    >
                        Uninstall Selected
                    </Button>
                    {hasUninstallError && <Text color='red.500' fontSize='sm' textAlign={'center'}>Error uninstalling software, Try again later</Text>}
                </VStack>

            </Flex>
        </Box >
    )
}