import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { UserStatusMessage, XboxUser } from "lib/coplay/types/BackendTypes";

function UserStatusContainer(props: { children: React.ReactNode }) {
    return (
        <Flex width="150px" alignItems="center" textAlign="center" justifyContent="center" >
            {props.children}
        </Flex>
    );
}

export function XboxUserStatus(props: { user: XboxUser, children?: React.ReactNode }) {
    const colorToken = determineColorToken(props.user.userStatus.statusMessage);
    const textColor = useColorModeValue(colorToken, colorToken);

    return (
        <UserStatusContainer>

            {/* Notification Button */}
            {props.children}

            {/* Status */}
            <Text
                maxH="60px"
                fontWeight='bold'
                fontSize='sm'
                color={textColor}
                overflow="hidden"
            >
                {props.user.userStatus.statusMessage === UserStatusMessage.Ready ||
                    props.user.userStatus.statusMessage === UserStatusMessage.Error ?
                    props.user.presenceText :
                    props.user.userStatus.statusMessage}
            </Text>

        </UserStatusContainer>
    );
}

function determineColorToken(statusMessage: UserStatusMessage) {
    return statusMessage === UserStatusMessage.Error ? 'orange.400' :
        statusMessage === UserStatusMessage.Warning ? 'orange.400' :
            statusMessage === UserStatusMessage.Ready ? 'green.400' : 'black';
}