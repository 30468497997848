import { Button, Flex, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, useDisclosure, Text, Modal, ModalOverlay, ModalContent, Image, Card } from "@chakra-ui/react";

import { MenuItem } from "@chakra-ui/react";
import { batchInstallSoftware } from "lib/backend/api";
import { XboxGameSearch } from "lib/coplay/components/xbox/gamesearch/XboxGameSearch";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { SearchedSoftware } from "lib/coplay/types/BackendTypes";
import { XboxUsersSet } from "lib/coplay/utils/XboxUsersSet";
import { useContext, useState } from "react";
import { MdAdd } from "react-icons/md";

const allHaveXboxConsoles = (selectedUsers: XboxUsersSet) => {
    let allHaveConsoles: boolean = true

    for (const user of selectedUsers.getAll()) {
        if (!user.consoleId) {
            allHaveConsoles = false
            break
        }
    }

    return allHaveConsoles
};


export function BatchInstallMenuItem() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { selectedUsers } = useContext(XboxUsersContext)

    return (
        <>
            <BatchInstallModal
                isOpen={isOpen}
                onClose={onClose}
                allHaveConsoles={allHaveXboxConsoles(selectedUsers)}
            />
            <MenuItem
                icon={<MdAdd />}
                onClick={onOpen}
                isDisabled={!allHaveXboxConsoles(selectedUsers)}>
                Install Software
            </MenuItem>
        </>
    )
}

export function SelectedXboxSoftware(props: {
    searchedSoftware: SearchedSoftware,
    onRemove: () => void
}) {
    return <Card m={2} align='center'>
        <Text maxWidth='80%' p={2} size='xl' fontWeight='bold' textAlign='center' >{props.searchedSoftware.name}</Text>
        <Image p={2} src={props.searchedSoftware.imageUri} width='100px' alt={props.searchedSoftware.name} />
        <Button onClick={props.onRemove}>Remove</Button>
    </Card>
}


export function BatchInstallModal(props: {
    isOpen: boolean,
    onClose: () => void,
    allHaveConsoles: boolean,
}) {
    const [selectedSoftware, setSelectedSoftware] = useState<SearchedSoftware | null>(null)
    const { selectedUsers, clearSelectedUsers } = useContext(XboxUsersContext)
    const clearOnClose = () => {
        setSelectedSoftware(null)
        props.onClose()
    }

    // TODO: Actually install software
    const handleConfirmPromise = () => {
        clearSelectedUsers()
        props.onClose()
        return batchInstallSoftware(selectedUsers.map(user => user.emailAddress), [selectedSoftware])
    }

    return (
        <Modal isOpen={props.isOpen} onClose={clearOnClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Batch Install Software</ModalHeader>
                <ModalCloseButton />
                <ModalBody >
                    {props.allHaveConsoles ?
                        <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                            {selectedSoftware != null &&
                                <SelectedXboxSoftware
                                    searchedSoftware={selectedSoftware}
                                    onRemove={() => { setSelectedSoftware(null) }}
                                />}
                            <Text py='25px'>Search for the game you would like to install</Text>
                            <XboxGameSearch
                                editing={true}
                                onSelected={setSelectedSoftware}
                            />
                            <Button width='200px' variant="brand" disabled={selectedSoftware == null} onClick={handleConfirmPromise}>Confirm</Button>
                        </Flex> :
                        <Text> Uh oh, not every selected user has a linked xbox!</Text>
                    }
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}