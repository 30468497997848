import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import Card from 'lib/horizon/components/card/Card';

export function PaymentsZeroStateLayout(props: { icon: React.ReactNode, title: string, description: string, button: React.ReactNode }) {
    const cardShadow = useColorModeValue('0px 2px 8px rgba(112, 144, 176, 0.3)', 'unset');

    return (
        <Card
            id="paymentsZeroState"
            boxShadow={cardShadow}
            justifyContent="center"
            alignContent={"center"}
            alignItems={"center"}
            h='100%' w='100%'>
            <Box maxW="50%" textAlign={"center"}>
                {props.icon}
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontWeight="black" fontSize='3xl'>
                    {props.title}
                </Text>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontSize='md'>
                    {props.description}
                </Text>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontSize='md'>
                    Click below to add add an account.
                </Text>
                {props.button}
            </Box>
        </Card>
    )
}