import { useContext, useEffect } from "react";
import { createContext, ReactNode } from "react";
import { useState } from "react";
import { AuthContext } from "./AuthProvider";
import { onSnapshot } from "firebase/firestore";
import { convertDocumentToXboxUser } from "../utils/utils";
import { XboxUsersSet } from "../utils/XboxUsersSet";
import { XboxUser } from "../types/BackendTypes";
import { getXboxUsersCollectionRef } from "lib/backend/firebase/service";

export type XboxUsersContextType = {
    xboxUsers: XboxUsersSet,
    selectedUsers: XboxUsersSet,
    setSelectedUsers: (users: XboxUsersSet) => void,
    addSelectedUser: (user: XboxUser) => void,
    addMultipleSelectedUsers: (users: XboxUser[]) => void,
    removeSelectedUser: (user: XboxUser) => void,
    clearSelectedUsers: () => void,
    loading: boolean,
}

export const XboxUsersContext = createContext<XboxUsersContextType | undefined>(undefined);

export const XboxUsersProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { currentUser } = useContext(AuthContext);
    const [xboxUsers, setXboxUsers] = useState<XboxUsersSet>(new XboxUsersSet());
    const [selectedUsers, setSelectedUsers] = useState<XboxUsersSet>(new XboxUsersSet());
    const [loading, setLoading] = useState(true);


    const addSelectedUser = (user: XboxUser) => {
        const newSelectedUsers = new XboxUsersSet(selectedUsers);
        newSelectedUsers.add(user);
        setSelectedUsers(newSelectedUsers);
    }

    const addMultipleSelectedUsers = (users: XboxUser[]) => {
        const newSelectedUsers = new XboxUsersSet(selectedUsers);
        users.forEach(user => newSelectedUsers.add(user));
        setSelectedUsers(newSelectedUsers);
    }

    const removeSelectedUser = (user: XboxUser) => {
        const newSelectedUsers = new XboxUsersSet(selectedUsers);
        newSelectedUsers.delete(user);
        setSelectedUsers(newSelectedUsers);
    }

    const clearSelectedUsers = () => {
        setSelectedUsers(new XboxUsersSet());
    }

    useEffect(() => {
        if (!currentUser) return;
    }, [currentUser]);


    useEffect(() => {
        if (!currentUser) return;
        const unsubscribe = onSnapshot(getXboxUsersCollectionRef(currentUser), (snapshot) => {
            const xboxUsers: XboxUsersSet = new XboxUsersSet()
            snapshot.forEach((doc) => {
                const user = convertDocumentToXboxUser(doc);
                if (user) xboxUsers.add(user);
            });
            console.log("xboxUsers", xboxUsers); // TODO: Remove
            setXboxUsers(xboxUsers);
            setLoading(false);
        });
        return unsubscribe;
    }, [currentUser]);


    const value = {
        xboxUsers,
        selectedUsers,
        setSelectedUsers,
        addSelectedUser,
        addMultipleSelectedUsers,
        removeSelectedUser,
        clearSelectedUsers,
        loading,
    }

    return (
        <XboxUsersContext.Provider value={value}>
            {children}
        </XboxUsersContext.Provider>
    )
}