import { Flex, Box, CircularProgress, Icon, Spacer, Menu, MenuButton, IconButton, MenuList, MenuItem, Text, useColorModeValue } from "@chakra-ui/react";
import { FaCheckCircle, FaEllipsisV } from "react-icons/fa";
import { VendorSubscription } from "lib/coplay/types/PaymentTypes";


export function FundingCrumb(props: { funding: VendorSubscription['coplayFunding'] }) {
    const secondaryColor = useColorModeValue('gray.400', 'white');


    return (
        <Flex alignItems="center">
            <Box position="relative" width="40px" height="40px">
                <CircularProgress
                    value={(props.funding.amountUsed / (props.funding.amountUsed + props.funding.amountRemaining)) * 100}
                    color="blue.400"
                    trackColor="gray.200"
                    size="40px"
                />
                <Flex
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text fontSize="xs" fontWeight="bold">
                        {Math.round((props.funding.amountUsed / (props.funding.amountUsed + props.funding.amountRemaining)) * 100)}%
                    </Text>
                </Flex>
            </Box>
            <Box ml="10px">
                <Text fontWeight='bold' fontSize='xs'>
                    Balance: ${props.funding.amountUsed.toFixed(2)} / ${(props.funding.amountUsed + props.funding.amountRemaining).toFixed(2)}
                </Text>
                <Text fontWeight='bold' fontSize='xs' color="blue.500">
                    <Flex alignItems="center">
                        <Icon as={FaCheckCircle} mr={1} />
                        CoPlay Managed
                    </Flex>
                </Text>
                <Text color={secondaryColor} fontSize='xs'>
                    {props.funding.status}
                </Text>
            </Box>
            <Spacer />
            <Menu>
                <MenuButton
                    as={IconButton}
                    icon={<Icon as={FaEllipsisV} />}
                    color="gray.200"
                    variant="ghost"
                    onClick={(e) => e.stopPropagation()}
                />
                <MenuList>
                    <MenuItem onClick={(e) => { e.stopPropagation(); }}>Delete Funding</MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    )
}