import { Avatar, Box, Button, Flex, Heading, Image } from '@chakra-ui/react';
import { PaymentsLoadingState } from './components/PaymentsLoadingState';
import { SupportedVendors, VendorMember } from 'lib/coplay/types/PaymentTypes';
import { FundingDetails } from './components/FundingDetails';
import DisneyPlus from 'lib/coplay/assets/disney_plus_icon.png';
import { PaymentsViewState, usePaymentsViewHook } from './usePaymentsViewHook';
import { PaymentsZeroStateLayout } from './components/PaymentsZeroState';
import { PaymentsViewLayout } from './components/PaymentsViewLayout';
import { PaymentsUpgradeState } from './components/PaymentsUpgradeState';


export function DisneyPaymentsView() {
    const { viewState, vendorMembers, coplayFunding } = usePaymentsViewHook(SupportedVendors.Disney);

    return (
        <Box h='100%' w='100%' >
            <Flex h='100%' overflowY='scroll' p={2} >
                {viewState === PaymentsViewState.LOADING &&
                    <PaymentsLoadingState selectedService={SupportedVendors.Disney} />}
                {viewState === PaymentsViewState.ZERO_STATE
                    && <DisneyPaymentsZeroState />}
                {viewState === PaymentsViewState.LIST
                    && <DisneyPaymentsList members={vendorMembers} />}
                {viewState === PaymentsViewState.UPGRADE
                    && <PaymentsUpgradeState
                        icon={<Avatar src={DisneyPlus} size="2xl" />}
                    />}
            </Flex>
        </Box>
    );
}



export function DisneyPaymentsList(props: { members: VendorMember[] }) {
    const DisneyPlusIcon = <Image src={DisneyPlus} width={8} height={8} borderRadius='full' />

    // TODO: Make this use virtualized list
    return (
        <PaymentsViewLayout
            header={<Heading size="lg" fontWeight="bold" color="blue.500">YOUR DISNEY+ ACCOUNTS</Heading>}
            description={'Aggregate your Disney+ accounts and manage your payments and billing in one place.'}
            button={
                <Button
                    leftIcon={DisneyPlusIcon}
                    variant="action"
                    color="blue.500"
                    isDisabled={true}
                >
                    Add Account
                </Button>}
            fundingDetails={
                <FundingDetails coplayBalance={0} coplaySpend={0} users={props.members.length} />
            }
            members={[]}
        />
    )
}


function DisneyPaymentsZeroState() {

    return (
        <PaymentsZeroStateLayout
            icon={<Avatar src={DisneyPlus} size="2xl" />}
            title="No Managed Payments Or Subscription Accounts Found"
            description='Aggregate third-party services like Disney+ or Xbox Game Pass, and manage your payments and billing in one place.'
            button={
                <Button mt={4} variant="action" color="blue.500" maxWidth="300px" minWidth="200px" onClick={() => { }} isDisabled={true}>
                    Add Disney+ Account
                </Button>
            }
        />
    )
}