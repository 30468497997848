import { Button, MenuItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import { handleXboxFriending } from "lib/backend/api";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { OauthActions } from "lib/coplay/types/BackendTypes";
import { XboxUsersSet } from "lib/coplay/utils/XboxUsersSet";
import { useContext } from "react";
import { MdSyncAlt } from "react-icons/md";

export function SyncFriendsMenuItem() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <MenuItem icon={<MdSyncAlt />} onClick={onOpen}>
                Sync Friends
            </MenuItem>
            <SyncFriendsModal
                isOpen={isOpen}
                onClose={onClose}
            />
        </>
    )
}

export function SyncFriendsModal(props: {
    isOpen: boolean,
    onClose: () => void
}) {
    const { selectedUsers, clearSelectedUsers } = useContext(XboxUsersContext)
    const toast = useToast();

    const selectedEmails = selectedUsers.toArray().map(user => user.emailAddress)
    const xboxFriendIds = selectedUsers.toArray().map(user => user.xuid)


    const handleConfirmPromise = () => {
        clearSelectedUsers()
        return handleXboxFriending(selectedEmails, xboxFriendIds, OauthActions.SyncFriends)
    }

    const handleConfirm = () => {
        toast.promise(handleConfirmPromise(), {
            success: (result) => ({
                title: "Syncing Friends complete",
                description: `Synced friends for ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,
            }),
            error: (error) => ({
                title: "Syncing Friends failed",
                description: `Failed to sync friends for ${selectedUsers.size()} user(s) - ${error.message}`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,

            }),
            loading: {
                title: "Syncing Friends...",
                description: `Syncing friends for ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,
            },
        });
        props.onClose();
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Sync Friends</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure you want to sync friends for {selectedUsers.size()} user(s)?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleConfirm}>
                        Confirm
                    </Button>
                    <Button variant="ghost" onClick={props.onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}