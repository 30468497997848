import { Box, Flex } from "@chakra-ui/react";
import Card from "lib/horizon/components/card/Card";
import { useProfilesView } from "./hooks/useProfilesViewHook"; // Import the hook
import { ProfileViewState } from "./hooks/useProfilesViewHook";
import { useNavigate } from "react-router-dom";
import { ProfileLoadingState } from "./components/ProfilesLoadingState";
import { ProfilesList } from "./ProfilesListView";
import { CreateProfile } from "./CreateProfilesView";
import { ProfileDetails } from "./ProfileDetailsView";

export function ProfilesView() {
    const {
        profiles,
        selectedProfile,
        viewState,
        onCreated,
        onDetails,
        onDelete,
        onDuplicate,
    } = useProfilesView(); // Use the hook
    const navigate = useNavigate();

    const viewStateMap = {
        [ProfileViewState.LOADING]: <ProfileLoadingState />,
        [ProfileViewState.LIST]: <ProfilesList
            profiles={profiles}
            onCreate={() => navigate("/app/profiles", { state: ProfileViewState.CREATE })}
            onDetails={onDetails}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
        />,
        [ProfileViewState.CREATE]: <CreateProfile
            onCreated={onCreated}
            onBack={() => navigate("/app/profiles", { state: ProfileViewState.LIST })}
        />,
        [ProfileViewState.DETAILS]: <ProfileDetails
            profile={selectedProfile}
            onDelete={onDelete}
            onBack={() => navigate("/app/profiles", { state: ProfileViewState.LIST })}
        />
    };

    return (
        <Box pb="10px" h="100%">
            <Card h='100%' overflowY='scroll' >
                <Flex
                    align={{ sm: 'flex-start', lg: 'center' }}
                    justify='space-between'
                    w='100%'
                >
                </Flex>
                {viewStateMap[viewState]}
            </Card>
        </Box>
    );
}