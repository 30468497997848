import { initializeApp } from 'firebase/app';
import { confirmPasswordReset, getAuth, sendPasswordResetEmail, User } from 'firebase/auth';
import { FirebaseConfig } from 'config/generated';
import { collection, getFirestore } from 'firebase/firestore';
import { XboxUserData } from './xbox-user-data';
import { XboxSchedulingData } from './xbox-scheduling-data';
import { XboxProfilesData } from './xbox-profiles-data';
import { XboxLogsUsageData } from './xbox-logs-usage-data';
import { CoplayConfigData } from './coplay-config-data';
import { VendorData } from './payments-data';

const app = initializeApp(FirebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

let serviceUser: User | null = null;
let xboxUserData: XboxUserData | null = null;
let xboxSchedulingData: XboxSchedulingData | null = null;
let xboxProfilesData: XboxProfilesData | null = null;
let xboxLogsUsageData: XboxLogsUsageData | null = null;
let coplayConfigData: CoplayConfigData | null = null;
let vendorData: VendorData | null = null;
//
// AUTHENTICATION
//

const resetPassword = async (email: string) => {
    return await sendPasswordResetEmail(getAuth(), email)
}

const confirmThePasswordReset = async (resetCode: string, newPassword: string) => {
    return await confirmPasswordReset(getAuth(), resetCode, newPassword)
}

const setServiceUser = (user: User | null) => {
    serviceUser = user;
    xboxUserData = user ? new XboxUserData(user, db) : null;
    xboxSchedulingData = user ? new XboxSchedulingData(user, db) : null;
    xboxProfilesData = user ? new XboxProfilesData(user, db) : null;
    xboxLogsUsageData = user ? new XboxLogsUsageData(user, db) : null;
    coplayConfigData = user ? new CoplayConfigData(user, db) : null;
    vendorData = user ? new VendorData(user, db) : null;
}

const getRequestToken = async () => {
    if (!serviceUser) {
        throw new Error('Firebase user does not exist');
    }
    return await serviceUser.getIdToken(true);
}

const getServiceUser = () => {
    return serviceUser;
}

//
// Services
//

const getXboxUserData = () => {
    if (!xboxUserData) {
        throw new Error('Xbox data is not initialized');
    }
    return xboxUserData;
}

const getXboxSchedulingData = () => {
    if (!xboxSchedulingData) {
        throw new Error('Xbox scheduling is not initialized');
    }
    return xboxSchedulingData;
}

const getXboxProfilesData = () => {
    if (!xboxProfilesData) {
        throw new Error('Xbox profiles data is not initialized');
    }
    return xboxProfilesData;
}

const getXboxLogsUsageData = () => {
    if (!xboxLogsUsageData) {
        throw new Error('Xbox logs usage data is not initialized');
    }
    return xboxLogsUsageData;
}

const getCoplayConfigData = () => {
    if (!coplayConfigData) {
        throw new Error('Coplay config data is not initialized');
    }
    return coplayConfigData;
}

const getVendorData = () => {
    if (!vendorData) {
        throw new Error('Vendor data is not initialized');
    }
    return vendorData;
}

//
// COLLECTIONS
//

const getXboxUsersCollectionRef = (user: User) => {
    return collection(db, `${user.email}/data/users`);
}

export {
    app,
    auth,
    resetPassword,
    confirmThePasswordReset,
    setServiceUser,
    getServiceUser,
    getRequestToken,
    getXboxUserData,
    getXboxSchedulingData,
    getXboxProfilesData,
    getXboxLogsUsageData,
    getXboxUsersCollectionRef,
    getCoplayConfigData,
    getVendorData
};