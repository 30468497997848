import { ModalHeader, ModalCloseButton, ModalBody, Box, FormLabel, Input, Flex, Button, ModalFooter, Text, Link } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { XboxLoginUrl } from "config/generated";
import { useState, ChangeEvent } from "react";

export function AggregateXboxUserModalScreen(props: { close: () => void, handler: (loginCode: string) => void }) {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const [loginCode, setLoginCode] = useState<string>("");

    const loginLink: JSX.Element = <Link
        fontWeight='900'
        color={textColorBrand}
        href={XboxLoginUrl}
        target="_blank"
        rel="noopener noreferrer">
        special login link
    </Link>

    return (
        <>
            <ModalHeader>Add A New Xbox Live® User</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Box>
                    <strong>Adding a new Xbox Live® user to CoPlay XM is easy! All you need to do is get a login code from Microsoft. To do this:</strong>
                    <Text style={{ paddingTop: "20px" }}> 1. Click on our {loginLink} </Text>
                    <p>2. Log into your Xbox Live® account </p>
                    <p>3. Copy the login code you obtain after logging in</p>
                    <p>4. Paste the login code below</p>
                    <p>5. Click 'Submit Login Code'</p>
                    <br></br>
                </Box>

                <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Paste your login code here
                </FormLabel>
                <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Paste your login code here'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='outline'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setLoginCode(e.target.value)}
                />
                <Flex justifyContent='center' w='100%'>
                    <Button width='200px' variant="brand" mr={3} onClick={() => { props.handler(loginCode) }}>Submit Login Code</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}


export function ResetXboxUserModalScreen(props: { emailAddress: string, close: () => void, handler: (loginCode: string) => void }) {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const [loginCode, setLoginCode] = useState<string>("");

    const loginUrl = XboxLoginUrl + encodeURIComponent("&login_hint=" + props.emailAddress)

    const loginLink: JSX.Element = <Link
        fontWeight='900'
        color={textColorBrand}
        href={loginUrl}
        target="_blank"
        rel="noopener noreferrer">
        special login link
    </Link>

    return (
        <>
            <ModalHeader>{"Resetting Xbox Live® account for " + props.emailAddress}</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Box>
                    <strong>Sometimes a user's Xbox Live® account can get into a bad state. If you're having trouble, try resetting their account. To do this: </strong>
                    <Text style={{ paddingTop: "20px" }}> 1. Click on our {loginLink} </Text>
                    <p>2. Log into your Xbox Live® account </p>
                    <p>3. Copy the login code you obtain after logging in</p>
                    <p>4. Paste the login code below</p>
                    <p>5. Click 'Submit Login Code'</p>
                    <br></br>
                </Box>

                <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Paste your login code here
                </FormLabel>
                <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Paste your login code here'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='outline'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setLoginCode(e.target.value)}
                />
                <Flex justifyContent='center' w='100%'>
                    <Button width='200px' variant="brand" mr={3} onClick={() => { props.handler(loginCode) }}>Submit Login Code</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}