import { Button, Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { deleteApiKey } from "lib/backend/api";
import { XMAPIKeyCrumb } from "lib/coplay/types/BackendTypes";
import { useState } from "react";

export default function APIKeysTable(props: { keys: XMAPIKeyCrumb[] }) {
    const cardShadow = useColorModeValue('0px 0px 20px rgba(112, 144, 176, 0.12)', 'unset');

    return (
        <Flex boxShadow={cardShadow} w='100%' borderRadius={8}>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Secret Key</Th>
                        <Th>Created</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {props.keys.map((key, index) => (
                        <Tr key={index}>
                            <Td>{key.name}</Td>
                            <Td>{key.crumb}</Td>
                            <Td>{new Date(key.createdAt).toLocaleDateString()}</Td>
                            <Td>
                                <DeleteKeyButton keyid={key.keyId} />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Flex>
    )
}


function DeleteKeyButton(props: { keyid: string }) {

    const [loading, setLoading] = useState(false);

    const onDeleteClick = () => {
        setLoading(true);
        deleteApiKey(props.keyid).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Button
            aria-label="Delete"
            size="sm"
            colorScheme="red"
            onClick={() => onDeleteClick()}
            isLoading={loading}
        >
            Delete
        </Button>
    )
}