import { mintApiKey } from "lib/backend/api";
import { MintKeyResponse } from "lib/coplay/types/BackendTypes";
import { useState } from "react";

export function useCreateKeyModal() {
    const [viewState, setViewState] = useState<'loading' | 'error' | 'success' | 'form'>('form');
    const [response, setResponse] = useState<MintKeyResponse | null>(null);
    const [newKeyName, setNewKeyName] = useState('');

    const resetModal = () => {
        setViewState('form');
        setResponse(null);
        setNewKeyName('');
    };

    const generateKey = async () => {
        try {
            setViewState('loading');
            const response = await mintApiKey(newKeyName);
            setResponse(response);
            setViewState('success');
        } catch (error) {
            console.error("Failed to generate key:", error);
            setViewState('error');
        }
    };

    return {
        viewState,
        response,
        newKeyName,
        setNewKeyName,
        resetModal,
        generateKey,
    };
}