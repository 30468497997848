import React from 'react';
import { Flex, Spacer } from '@chakra-ui/react';
import AuthFooter from 'lib/coplay/views/auth/components/AuthFooter';



export const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {


    return (
        <Flex flexDirection='column' justifyContent="center" alignItems="center" height="100vh" width="100vw">
            <Spacer />
            <Flex direction='column' justifyContent='center' alignItems='center' width={{ base: "100%", md: "450px" }} height={{ base: "100%", md: "600px" }}>
                <Flex w='100%' me='auto' direction='column' justifyContent='center' alignItems='center'>
                    {children}
                </Flex>
            </Flex>
            <Spacer />
            <Flex direction='column' mt='auto' mb={2}>
                <AuthFooter />
            </Flex>
        </Flex>
    );
};