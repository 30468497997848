import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, Flex, useColorModeValue, Icon, Button, Spacer } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { ScheduleEvent } from "lib/coplay/types/BackendTypes";

import { IoTime } from "react-icons/io5";
import { removeFromSchedule } from "lib/backend/api";
import { getXboxSchedulingData } from "lib/backend/firebase/service";

const useScheduleDetails = (scheduleIds: string[]) => {
    const [schedules, setSchedules] = useState<ScheduleEvent[] | null>(null);

    useEffect(() => {
        if (scheduleIds.length === 0) {
            setSchedules(null);
        }
        getXboxSchedulingData().getSchedulesById(scheduleIds).then((schedules) => {
            setSchedules(schedules);
        })
    }, [scheduleIds])

    return {
        schedules,
        setSchedules
    }
}

export function ScheduleListItem(props: {
    schedule: ScheduleEvent,
    onSelect?: (event: ScheduleEvent) => void,
    emailAddress?: string
}) {
    const textColor = useColorModeValue("navy.700", "white");
    const [isLoading, setIsLoading] = useState(false);

    const onRemove = () => {
        setIsLoading(true);
        removeFromSchedule(props.schedule.scheduleId, props.emailAddress).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            console.error('[ScheduleListItem] Error removing schedule:', error);
            setIsLoading(false);
        })
    }


    const ScheduleDetailsText: React.FC<{ label: string, value: string }> = ({ label, value }) => {
        return (
            <Text
                fontSize='sm'
                lineHeight='100%'
                mb={1}
                fontWeight='bold'
                color={textColor}> {label}:
                <Text
                    as='span'
                    fontSize='sm'
                    lineHeight='100%'
                    ml={1}
                    fontWeight='normal'
                    color={textColor}>{value}
                </Text>
            </Text>
        )
    }

    return (
        <li key={props.schedule.scheduleId} style={{ listStyleType: 'none' }}>
            <Flex my={2} alignItems='center'>
                <Flex
                    justify='center'
                    align='center'
                    borderRadius='50%'
                    mx={2}
                    minH={{ base: "60px", md: "70px" }}
                    h={{ base: "60px", md: "70px" }}
                    minW={{ base: "60px", md: "70px" }}
                    w={{ base: "60px", md: "70px" }}
                    me='14px'
                    bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'>
                    <Icon as={IoTime} color='white' w={8} h={8} />
                </Flex>
                <Flex flexDirection='column'>
                    <Text
                        mb='5px'
                        fontWeight='bold'
                        color={textColor}
                        fontSize={{ base: "md", md: "md" }}>
                        {props.schedule.name || `${props.schedule.scheduledRequest.oauthAction}-${props.schedule.frequency}`}
                    </Text>

                    <ScheduleDetailsText
                        label="Length"
                        value={`${new Date(props.schedule.startDate).toLocaleDateString('en-US')} - ${new Date(props.schedule.endDate).toLocaleDateString('en-US')}`} />
                    <ScheduleDetailsText
                        label="Start Time"
                        value={`${new Date(props.schedule.startDate).toLocaleTimeString().slice(0, -6)} ${new Date(props.schedule.startDate).toLocaleTimeString().slice(-2)}`} />
                    <ScheduleDetailsText
                        label="Frequency"
                        value={`${props.schedule.frequency}`} />
                    <ScheduleDetailsText
                        label="Created On"
                        value={`${new Date(props.schedule.scheduledRequest.requestId).toLocaleDateString('en-US')}`} />
                </Flex>
                <Spacer />
                <Button size='sm' colorScheme='red' variant='ghost' isLoading={isLoading} onClick={onRemove}>Remove</Button>
            </Flex>
        </li>
    );
}

export function SchedulesList(props: {
    schedules: ScheduleEvent[],
    onSelect?: (event: ScheduleEvent) => void,
    emailAddress?: string
}) {
    const { schedules, onSelect, emailAddress } = props;

    const scheduleElements = schedules.map((schedule) =>
        <ScheduleListItem schedule={schedule} onSelect={onSelect} emailAddress={emailAddress} />
    )

    return (
        <ul>
            {scheduleElements}
        </ul>
    )
}


export function ScheduleDetailsModal(props: {
    scheduleIds: string[];
    emailAddress: string;
    isOpen: boolean;
    onClose: () => void;
}) {
    const { scheduleIds, emailAddress, isOpen, onClose } = props;
    const { schedules, } = useScheduleDetails(scheduleIds);

    const renderDetails = () => {
        if (!schedules) {
            return <Text>No schedules found.</Text>
        } else {
            return <SchedulesList schedules={schedules} onSelect={null} emailAddress={emailAddress} />
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Schedule Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {renderDetails()}
                </ModalBody>
            </ModalContent>

        </Modal>

    );
}