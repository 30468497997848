import { Flex, useColorModeValue } from "@chakra-ui/react";

export function XboxDashboardHeader(props: {
    children?: React.ReactNode
}) {
    const cardShadow = useColorModeValue('0px 20px 20px -20px rgba(112, 144, 176, 0.3)', 'unset');


    return (
        <Flex mb={4} pb={2} alignItems='center' gap='2' wrap='wrap' boxShadow={cardShadow} borderRadius={8} id='xbox-dashboard-header'>
            {props.children}
        </Flex>
    )
}