import { linkConsole } from "lib/backend/api";
import { getXboxUserData } from "lib/backend/firebase/service";
import { XboxConsole } from "lib/coplay/types/BackendTypes";
import { useState, useEffect } from "react";

export const useConsoleDetails = (emailAddress: string, onClose: () => void) => {
    const [progress, setProgress] = useState<"LOADING" | "ERROR" | "NO_CONSOLE" | "SUCCESS">("LOADING");
    const [consoleDetails, setConsoleDetails] = useState<XboxConsole>();

    useEffect(() => {
        const unsub =
            getXboxUserData()
                .getDeviceSidekick()
                .subscribeToDeviceDetails(emailAddress, (console: XboxConsole | undefined) => {
                    if (console) {
                        setConsoleDetails(console);
                        setProgress("SUCCESS");
                    } else {
                        setProgress("NO_CONSOLE");
                    }
                });
        return unsub;
    }, [emailAddress]);

    const unlinkConsole = () => {
        if (consoleDetails === undefined) {
            console.warn("No Console to unlink");
            return;
        }

        linkConsole(emailAddress, consoleDetails.id, false).then(() => {
            onClose();
        }).catch((error) => {
            console.log(error);
            setProgress("ERROR");
        });
        setProgress("LOADING");
    };

    return { progress, consoleDetails, unlinkConsole };
};