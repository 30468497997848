import { Button, DrawerBody, Avatar, Flex, Box, Text, useColorModeValue, Tab, TabList, TabPanel, TabPanels, Tabs, Icon, Spacer } from "@chakra-ui/react";
import { ScheduleBadge } from "../user/ScheduleBadge";
import { FaSyncAlt } from "react-icons/fa";
import { XboxAccountOverview } from "./components/XboxAccountOverview";
import { XboxAccountMessages } from "./components/XboxAccountMessages";
import { useXboxDrawer } from "./hooks/useXboxDrawerHook";
import { XboxAccountFriends } from "./components/XboxAccountFriends";
import { XboxAccountPrivileges } from "./components/XboxAccountPrivileges";
import { XboxAccountPrivacySettings } from "./components/XboxAccountPrivacySettings";
import { XboxAccountLogs } from "./components/XboxAccountLogs";
import { refreshUsers } from "lib/backend/api";
import { useState } from "react";


export function XboxAccountDrawer(props: { selectedXboxEmail?: string, onClose: () => void }) {
    const secondaryColor = useColorModeValue('gray.400', 'white');
    const {
        user,
        friends,
        conversations,
        xboxConsole,
        settings,
        privileges,
        updateNotes,
        updateSettings,
        clearConvos
    } = useXboxDrawer(props.selectedXboxEmail);

    const [isLoading, setIsLoading] = useState(false);
    const refreshUser = () => {
        if (user?.emailAddress) {
            setIsLoading(true);
            refreshUsers([user?.emailAddress]).finally(() => {
                setIsLoading(false);
            });
        }
    }

    return (
        <>
            <Flex alignItems='center'>
                <Avatar size='xl' src={user?.profilePicUrl} mr='2' >
                    {user?.schedules?.length > 0 &&
                        <ScheduleBadge
                            scheduleIds={user?.schedules}
                            emailAddress={user?.emailAddress}
                        />
                    }
                </Avatar>
                <Flex w='250px' maxW='250px' overflow='hidden' alignItems="center">
                    <Box>
                        <Text
                            fontWeight='bold'
                            fontSize='md'
                            maxHeight='50px'
                        >
                            {user?.emailAddress}
                        </Text>
                        <Text fontWeight='bold' fontSize='xs'>
                            {user?.gamerTag}
                        </Text>
                        <Text color={secondaryColor} fontWeight='bold' fontSize='xs'>
                            Last Updated: {user?.updatedAt.toLocaleString()}
                        </Text>
                    </Box>
                </Flex>
                <Spacer />

                <Button m='4' variant='outline' size='lg' isLoading={isLoading} onClick={refreshUser}>
                    <Icon as={FaSyncAlt} />
                </Button>

            </Flex>
            <DrawerBody h='100%'>
                <Tabs h='95%' colorScheme='brand'>
                    <TabList>
                        <Tab>Overview</Tab>
                        <Tab>Social</Tab>
                        <Tab>Settings</Tab>
                        <Tab>Logs</Tab>
                    </TabList>

                    <TabPanels h='100%'>
                        <TabPanel id='overview'>
                            <XboxAccountOverview user={user} updateNotes={updateNotes} />
                        </TabPanel>
                        <TabPanel id='social'>
                            <XboxAccountMessages onClearConversations={clearConvos} conversations={conversations} />
                            <XboxAccountFriends emailAddress={user?.emailAddress} friends={friends} />
                        </TabPanel>
                        <TabPanel id='settings'>
                            <XboxAccountPrivileges privileges={privileges} />
                            <XboxAccountPrivacySettings onSettings={updateSettings} settings={settings} />
                        </TabPanel>
                        <TabPanel h='100%' id='logs'>
                            <XboxAccountLogs emailAddress={user?.emailAddress} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </DrawerBody>
        </>
    )
}