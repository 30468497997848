import { User } from "firebase/auth";
import { collection, deleteDoc, doc, DocumentSnapshot, Firestore, getDoc, onSnapshot, query, setDoc } from "firebase/firestore";
import { XMAPIKeyCrumb, XMNotification } from "lib/coplay/types/BackendTypes";
import { ContactDetails, FrontendConfiguration } from "lib/coplay/types/FrontendTypes";

export class CoplayConfigData {
    _user: User;
    _db: Firestore;

    constructor(user: User, db: Firestore) {
        this._user = user;
        this._db = db;
    }

    getKeysPath = () => {
        return `${this._user.email}/data/keys`;
    }

    getConfigPath = () => {
        return `${this._user.email}/config`;
    }

    getContactPath = () => {
        return `${this._user.email}/data/contact/me`;
    }

    getNotificationsPath = () => {
        return `${this._user.email}/data/notifications`;
    }

    subscribeToApiKeys = (callback: (fetchedKeys: XMAPIKeyCrumb[]) => void) => {
        const apiKeysRef = collection(this._db, this.getKeysPath());
        const unsubscribe = onSnapshot(apiKeysRef, (snapshot) => {
            const apiKeys: XMAPIKeyCrumb[] = [];
            snapshot.forEach((doc) => {
                const key = doc.data() as XMAPIKeyCrumb;
                apiKeys.push(key);
            });
            callback(apiKeys);
        });
        return unsubscribe;
    };

    subscribeToConfiguration = (callback: (config: FrontendConfiguration) => void) => {
        try {
            return onSnapshot(
                doc(this._db, this.getConfigPath()),
                (doc: DocumentSnapshot) => {
                    const config = doc.data() as FrontendConfiguration
                    if (config) {
                        callback(config)
                    } else {
                        console.error(`[subscribeToConfiguration] No config found`)
                        callback({})
                    }
                }
            )
        } catch (error) {
            console.error(`[subscribeToConfiguration] Error: ${error}`)
        }
    }

    getContactDetails = async (callback: (contactDetails: ContactDetails) => void) => {
        try {
            const contactRef = doc(this._db, this.getContactPath());
            const docSnap = await getDoc(contactRef);
            const data = docSnap.data() as ContactDetails;
            if (data) {
                callback(data);
            } else {
                console.warn(`[getContactDetails] No contact details found`)
            }
            return;
        } catch (e) {
            console.error(`[getContactDetails] Error: ${e}`);
        }
    };

    updateContactDetails = (enteredDetails: { [key: string]: string }) => {
        setDoc(doc(this._db, this.getContactPath()), enteredDetails, { merge: true })
            .catch((error: any) => console.log(`[updateContactDetails] Error: ${error}`))
    }

    subscribeToXMNotifications = (callback: (notifications: XMNotification[]) => void) => {
        try {
            const q = query(collection(this._db, this.getNotificationsPath()));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const notifications: XMNotification[] = [];
                snapshot.forEach((doc) => {
                    notifications.push(doc.data() as XMNotification);
                });
                callback(notifications);
            });
            return unsubscribe;
        } catch (e) {
            console.error(`[subscribeToNotifications] Error: ${e}`);
            throw e;
        }
    };

    markXMNotificationAsRead = async (notification: XMNotification): Promise<void> => {
        try {
            // get the doc
            const ref = doc(this._db, this.getNotificationsPath(), notification.id);
            const notificationDoc = await getDoc(ref);
            if (notificationDoc.exists()) {
                const notification = notificationDoc.data() as XMNotification;
                notification.read = true;
                await setDoc(ref, notification);
            }
        } catch (e) {
            console.error(`[markNotificationAsRead] Error: ${e}`);
            throw e;
        }
    }

    deleteXMNotification = async (notificationId: string): Promise<void> => {
        try {
            const ref = doc(this._db, this.getNotificationsPath(), notificationId);
            await deleteDoc(ref);
        } catch (e) {
            console.error(`[deleteNotification] Error: ${e}`);
            throw e;
        }
    }
}