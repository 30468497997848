import { WarningIcon, WarningTwoIcon, InfoIcon } from "@chakra-ui/icons"
import { Tooltip, IconButton, Button } from "@chakra-ui/react"
import { useColorModeValue } from "@chakra-ui/system"
import { reAggregateUser, refreshUsers } from "lib/backend/api"
import { CoPlayLoadingSpinner } from "lib/coplay/components/CoPlayLoading"
import { XboxUser, UserStatusMessage } from "lib/coplay/types/BackendTypes"
import { ResetXboxUserModalScreen } from "../../../../add-new-xbox-user/components/AggregateXboxUserModalScreen"

export function XboxUserStatusNotificationButton(props: { user: XboxUser, onOpen: () => void }) {
    const getStatusIcon = (user: XboxUser) => {
        switch (user.userStatus.statusMessage) {
            case UserStatusMessage.Error:
                return <WarningIcon />
            case UserStatusMessage.Warning:
                return <WarningTwoIcon />
            case UserStatusMessage.Pending:
                return <InfoIcon />
            default:
                return <WarningIcon />
        }
    }

    let color = 'orange';
    let buttonMessage = `Click for Details`;
    if (props.user.userStatus.statusMessage === UserStatusMessage.Warning) {
        color = 'orange';
    }
    else if (props.user.userStatus.statusMessage === UserStatusMessage.Pending) {
        color = 'brand';
        buttonMessage = "Click to continue"
    }
    let buttonStyle = useColorModeValue(color, 'white');

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onOpen();
    }

    return (
        <>
            <Tooltip label={buttonMessage} hasArrow>
                <IconButton
                    aria-label="Status"
                    mr="0px"
                    icon={getStatusIcon(props.user)}
                    onClick={onClick}
                    color={buttonStyle}
                    variant="ghost"
                    size="sm"
                    _hover={{ transform: 'scale(1.4)' }}
                />
            </Tooltip>
        </>
    )
}

export function XboxStatusResetButton(props: { emailAddress: string, setView: (view: JSX.Element) => void, onClose: () => void }) {
    const resetHandler = () => {
        function handleReset(loginCode: string) {
            props.setView(<CoPlayLoadingSpinner />)
            reAggregateUser(loginCode).then((response) => {
                props.onClose()
            }).catch((error) => {
                console.log(error)
            })
        }
        props.setView(<ResetXboxUserModalScreen handler={handleReset} close={props.onClose} emailAddress={props.emailAddress} />)
    }

    return (<Button colorScheme="red" mr={3} onClick={resetHandler}>Reset/Re-Authorize</Button>)
}

export function XboxStatusRefreshButton(props: { emailAddress: string, setView: (view: JSX.Element) => void, onClose: () => void }) {
    const refreshHandler = () => {
        props.setView(<CoPlayLoadingSpinner />)
        refreshUsers([props.emailAddress]).finally(() => {
            props.onClose()
        })
    }

    return (<Button variant='brand' mr={3} onClick={refreshHandler}>Refresh</Button>)
}