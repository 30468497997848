import { Box } from "@chakra-ui/react";
import { MonthlyReport, UsageMetrics } from "lib/coplay/types/BackendTypes";
import Card from 'lib/horizon/components/card/Card';
import BarChart from "lib/horizon/components/charts/BarChart";
import { createActivityGraphOptions } from "../xbox-usage-utils";


function formatMetricName(metricKey: string): string {
    return metricKey
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
        .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
}

export default function UsageActivityGraph(props: {
    monthlyReport: MonthlyReport,
    month: string,
}) {

    let chartData = Object.keys(UsageMetrics).map(metricKey => {
        const metric = UsageMetrics[metricKey as keyof typeof UsageMetrics];
        return {
            name: formatMetricName(metricKey),
            data: props.monthlyReport.days.map(day => day.metrics[metric] || 0)
        };
    });

    return (
        <Card alignItems='center' flexDirection='column' w='100%'>
            <Box minH="60vh" h='100%' mt='auto' w='100%'>
                <BarChart chartData={chartData} chartOptions={createActivityGraphOptions()} />
            </Box>
        </Card>
    );
}