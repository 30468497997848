import { VStack, Box, HStack, Spacer, Menu, MenuButton, IconButton, Spinner, MenuList, MenuItem, Flex, Button, Progress, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { XboxConsole, ConsoleCrumb, XboxPower } from 'lib/coplay/types/BackendTypes';
import { FaUnlink, FaSync } from 'react-icons/fa';
import { IoMdPower } from 'react-icons/io';
import { IoMoonOutline } from 'react-icons/io5';
import { MdSettingsPower } from 'react-icons/md';
import { XboxIcon } from '../../XboxIcon';
import { useDeviceHeader } from '../hooks/useDeviceDetailsHeaderHook';
import { EditableDeviceName } from './EditableDeviceName';
import { DeviceStatus } from './DeviceStatus';


export const DeviceDetailsHeader = (props: {
    emailAddress: string,
    device: XboxConsole,
    onUnlink: () => void,
    crumb?: ConsoleCrumb
}) => {
    const iconColor = useColorModeValue('#000000', 'white');
    const {
        isRefreshing,
        xboxType,
        storageDevices,
        refreshConsole,
        togglePower
    } = useDeviceHeader(props.emailAddress, props.device);

    return (
        <VStack alignItems="center" spacing={4} minW='300px' maxW='400px'>
            <EditableDeviceName device={props.device} emailAddress={props.emailAddress} />

            <Box m={0} w="85%">
                <HStack my={-1} >
                    <Text><Text as="span" fontWeight="bold">Power: </Text>{props.device.powerState}</Text>
                    <Spacer />
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            size="sm"
                            _hover={{ bg: 'secondaryGray.200' }}
                            variant='outline'
                            icon={isRefreshing ? <Spinner size="sm" /> : <MdSettingsPower />}
                            aria-label="Power Options"
                            isDisabled={isRefreshing}
                        />
                        <MenuList>
                            <MenuItem icon={<IoMdPower />} onClick={() => togglePower(XboxPower.WakeUp)}>
                                Wake up
                            </MenuItem>
                            <MenuItem icon={<IoMoonOutline />} onClick={() => togglePower(XboxPower.TurnOff)}>
                                Sleep
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            </Box>

            <XboxIcon color={iconColor} consoleState={props.crumb?.consoleState} />

            <Flex flexDirection="column" alignItems="flex-start" w="100%">
                <HStack mb={4} mt={0} justifyContent="center" width="100%">
                    <Button onClick={props.onUnlink} size="xs" py={'15px'} colorScheme="red" variant="solid" leftIcon={<FaUnlink />}>
                        Unlink Console
                    </Button>
                    <Button onClick={refreshConsole} isLoading={isRefreshing} size="xs" py={'15px'} colorScheme="green" variant="solid" leftIcon={<FaSync />}>
                        Refresh Console
                    </Button>
                </HStack>
                <DeviceStatus errorCode={props.crumb?.consoleState} errorMessage={props.crumb?.consoleMsg} />

                <Box w="85%">
                    <Text fontWeight="bold">Type</Text>
                    <Text>{xboxType}</Text>
                </Box>
                <Box w="85%">
                    <Text fontWeight="bold">ID</Text>
                    <Text>{props.device.id}</Text>
                </Box>

                <Box w="85%">
                    <Text fontWeight="bold">Updated at:</Text>
                    <Text>{new Date(props.device.aggregatedAt).toLocaleString()}</Text>
                </Box>
                <Box w="85%">
                    {storageDevices().map(dev => (
                        <Box key={dev.name}>
                            <Text fontWeight="bold">{dev.name}:</Text>
                            <Text>{dev.freeSpaceGB} GB Free ({dev.totalSpaceGB} GB Total)</Text>
                            <Progress w="100%" backgroundColor="gray.200" value={dev.usedPercentage} size="sm" colorScheme="green" />
                        </Box>
                    ))}
                </Box>
            </Flex>

            <Spacer />

        </VStack>
    );
};