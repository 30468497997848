import { MonthlyReport, BasicReport, BasicResponse } from "lib/coplay/types/BackendTypes";
import { getXboxLogsUsageData } from "lib/backend/firebase/service";
import { useEffect, useState } from "react";
import { MonthOptions } from "../components/MonthMenu";

export function useUsageData() {
    const [monthlyReport, setMonthlyReport] = useState<MonthlyReport | null>(null);
    const [logs, setLogs] = useState<(BasicReport | BasicResponse)[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<string>(new Date().toLocaleString('default', { month: 'long' }));
    const [year,] = useState<string>(new Date().getFullYear().toString());
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);
        const monthString = MonthOptions[selectedMonth].toString();
        getXboxLogsUsageData().getMonthlyUsageReports(year, monthString).then((report: MonthlyReport) => {
            setMonthlyReport(report);
            setIsLoading(false);
        }).catch((error) => {
            console.error('[Usage] Error getting reports: ', error);
            setIsLoading(false);
        })
    }, [selectedMonth, year]);

    useEffect(() => {
        getXboxLogsUsageData().getXMLogs().then((logs: (BasicReport | BasicResponse)[]) => {
            setLogs(logs);
        }).catch((error) => {
            console.error('[Usage] Error getting logs: ', error);
        })
    }, []);

    return { monthlyReport, logs, selectedMonth, setSelectedMonth, isLoading };
}