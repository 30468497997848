// useRemoteBrowserWorker.ts

import { useEffect, useRef, useState } from 'react';
import { BrowserStates, Message, WorkerCommands, HostCommands, SupportedVendors } from '../../types/PaymentTypes';
import { BrowserStrategy } from './browser-strategies';
import { getRequestToken } from 'lib/backend/firebase/service';

function useRemoteBrowserWorker(canvasElement: HTMLCanvasElement | null, strategy: BrowserStrategy) {
    const workerRef = useRef<Worker | null>(null);
    const [browserState, setBrowserState] = useState<BrowserStates>(BrowserStates.Ready);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        if (!canvasElement) return;

        workerRef.current = new Worker(new URL('remoteBrowser.worker.ts', import.meta.url));

        const updateCanvas = (imageData: string) => {
            if (canvasElement) {
                const ctx = canvasElement.getContext('2d');
                if (ctx) {
                    const img = new Image();
                    img.onload = () => {
                        ctx.drawImage(img, 0, 0);
                    };
                    img.src = `data:image/png;base64,${imageData}`;
                }
            }
        };

        workerRef.current.onmessage = (event: MessageEvent<Message>) => {
            const { command, data } = event.data;
            switch (command) {
                case WorkerCommands.screencastFrame:
                    updateCanvas(data);
                    break;
                case WorkerCommands.startComplete:
                    setBrowserState(BrowserStates.InProgress);
                    break;
                case WorkerCommands.inProgress:
                    setBrowserState(BrowserStates.InProgress);
                    break;
                case WorkerCommands.loading:
                    setBrowserState(BrowserStates.Loading);
                    break;
                case WorkerCommands.error:
                    setBrowserState(BrowserStates.Error);
                    setError(data);
                    break;
                case WorkerCommands.browserClose:
                    setBrowserState(BrowserStates.Finished);
                    break;
                case WorkerCommands.runComplete:
                    setBrowserState(BrowserStates.Finished);
                    break;
                default:
                    console.log('Received unknown command: ', command);
            }
        };

        workerRef.current.postMessage({
            command: HostCommands.setStrategy,
            data: {
                action: strategy.action,
                vendor: strategy.vendor,
            },
        });

        const handleScroll = (event: WheelEvent) => {
            event.preventDefault();
            event.stopPropagation();

            const data = {
                type: 'mouseWheel',
                x: event.clientX,
                y: event.clientY,
                deltaX: event.deltaX,
                deltaY: -event.deltaY,
                button: 'none',
                clickCount: 1,
            };

            workerRef.current?.postMessage({
                command: 'Input.emulateTouchFromMouseEvent',
                data,
            });
        };

        const handleMouseMoved = (event: MouseEvent) => {
            if (!canvasElement) return;

            const rect = canvasElement.getBoundingClientRect();
            const data = {
                type: 'mouseMoved',
                x: Math.floor(event.clientX - rect.left),
                y: Math.floor(event.clientY - rect.top),
                button: 'left',
                clickCount: 1,
            };

            workerRef.current?.postMessage({
                command: 'Input.emulateTouchFromMouseEvent',
                data,
            });
        };

        const handleMouseEvent = (event: MouseEvent) => {
            const types: any = {
                mousedown: 'mousePressed',
                mouseup: 'mouseReleased',
                touchstart: 'mousePressed',
                touchend: 'mouseReleased',
            };

            if (!(event.type in types)) {
                console.log('Unsupported event type: ', event.type);
                return;
            }

            const rect = canvasElement.getBoundingClientRect();

            const data = {
                type: types[event.type],
                x: Math.floor(event.clientX - rect.left),
                y: Math.floor(event.clientY - rect.top),
                button: 'left',
                clickCount: 1,
            };

            workerRef.current?.postMessage({
                command: 'Input.emulateTouchFromMouseEvent',
                data,
            });
        };

        const handleKeyboardEvent = (event: KeyboardEvent) => {
            let type: 'keyDown' | 'keyUp' | 'char';

            // Prevent backspace from going back in history
            if (event.keyCode === 8) {
                event.preventDefault();
            }

            switch (event.type) {
                case 'keydown':
                    type = 'keyDown';
                    break;
                case 'keyup':
                    type = 'keyUp';
                    break;
                case 'keypress':
                    type = 'char';
                    break;
                default:
                    return;
            }

            let text = type === 'char' ? String.fromCharCode(event.charCode) : undefined;

            if (event.type === 'keydown' && (event.ctrlKey || event.metaKey) && event.key === 'v') {
                event.preventDefault();
                navigator.clipboard.readText().then((text) => {
                    for (const char of text) {
                        const data = {
                            type,
                            text: char,
                            unmodifiedText: char.toLowerCase(),
                            keyIdentifier: (event as any).keyIdentifier,
                            code: event.code,
                            key: event.key,
                            windowsVirtualKeyCode: event.keyCode,
                            nativeVirtualKeyCode: event.keyCode,
                            autoRepeat: false,
                            isKeypad: false,
                            isSystemKey: false,
                        };

                        workerRef.current?.postMessage({
                            command: 'Input.dispatchKeyEvent',
                            data,
                        });
                    }
                });
                return;
            } else {
                const data = {
                    type,
                    text,
                    unmodifiedText: text ? text.toLowerCase() : undefined,
                    keyIdentifier: (event as any).keyIdentifier,
                    code: event.code,
                    key: event.key,
                    windowsVirtualKeyCode: event.keyCode,
                    nativeVirtualKeyCode: event.keyCode,
                    autoRepeat: false,
                    isKeypad: false,
                    isSystemKey: false,
                };

                workerRef.current?.postMessage({
                    command: 'Input.dispatchKeyEvent',
                    data,
                });
            }
        };

        // Add event listeners to canvas
        canvasElement.addEventListener('wheel', handleScroll, { passive: false });
        canvasElement.addEventListener('mousemove', handleMouseMoved);
        canvasElement.addEventListener('mousedown', handleMouseEvent);
        canvasElement.addEventListener('mouseup', handleMouseEvent);
        canvasElement.addEventListener('keydown', handleKeyboardEvent);
        canvasElement.addEventListener('keyup', handleKeyboardEvent);
        canvasElement.addEventListener('keypress', handleKeyboardEvent);

        return () => {
            console.log('unmounting')
            // Clean up worker on component unmount
            if (workerRef.current) {
                workerRef.current.postMessage({ command: HostCommands.close });
                workerRef.current.terminate();
            }

            canvasElement.removeEventListener('wheel', handleScroll);
            canvasElement.removeEventListener('mousemove', handleMouseMoved);
            canvasElement.removeEventListener('mousedown', handleMouseEvent);
            canvasElement.removeEventListener('mouseup', handleMouseEvent);
            canvasElement.removeEventListener('keydown', handleKeyboardEvent);
            canvasElement.removeEventListener('keyup', handleKeyboardEvent);
            canvasElement.removeEventListener('keypress', handleKeyboardEvent);
        };
    }, [canvasElement, strategy]); // Depend on canvasElement


    useEffect(() => {
        workerRef.current?.postMessage({
            command: HostCommands.setStrategy,
            data: {
                action: strategy.action,
                vendor: strategy.vendor,
            },
        });

        setBrowserState(BrowserStates.Ready);

    }, [strategy]);
    const handleStartClick = () => {

        getRequestToken().then((token) => {
            const config = strategy.createConfig(token);
            workerRef.current?.postMessage({
                command: HostCommands.start,
                data: {
                    browserWSEndpoint: config.wsUrl,
                    quality: config.quality,
                },
            });
            setBrowserState(BrowserStates.Loading);
        })
    };

    const handleVendorChange = (vendor: string) => {
        workerRef.current?.postMessage({
            command: HostCommands.setStrategy,
            data: {
                strategy: vendor,
            },
        });

        setBrowserState(BrowserStates.Ready);
    };

    return { browserState, error, handleStartClick, handleVendorChange };
}

export default useRemoteBrowserWorker;
