import { UserPrivacySettings } from "lib/coplay/types/BackendTypes";
import { EnrichedPrivacySetting, enrichSettings } from "lib/coplay/utils/profile-settings-utils";
import { useState, useEffect } from 'react';

// TODO: Move this to it's own file
export function useXboxPrivacySettings(settings?: UserPrivacySettings) {
    const [enrichedSettings, setEnrichedSettings] = useState<EnrichedPrivacySetting[]>([]);
    const [originalSettings, setOriginalSettings] = useState<UserPrivacySettings | undefined>(undefined);
    const [currentSettings, setCurrentSettings] = useState<UserPrivacySettings | undefined>(undefined);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (settings !== undefined && settings !== null) {
            setEnrichedSettings(enrichSettings(settings));
            setOriginalSettings(settings);
            setCurrentSettings(settings);
            setIsDirty(false);
        }
    }, [settings]);

    const handleSettingChange = (setting: string, value: string) => {
        if (currentSettings !== undefined) {
            const newSettings = {
                ...currentSettings,
                settings: currentSettings.settings.map(s => s.setting === setting ? { ...s, value } : s)
            };
            setCurrentSettings(newSettings as UserPrivacySettings);
            setIsDirty(true);
        }
    };

    const handleSaveClick = (onSettings: (settings: UserPrivacySettings) => Promise<void>) => {
        setIsLoading(true);
        onSettings(currentSettings as UserPrivacySettings).then(() => {
            setIsLoading(false);
            setOriginalSettings(currentSettings);
            setIsDirty(false);
        });
    };

    return {
        enrichedSettings,
        originalSettings,
        currentSettings,
        isDirty,
        isLoading,
        handleSettingChange,
        handleSaveClick
    };
}