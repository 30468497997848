import { useColorModeValue, Link } from "@chakra-ui/react"

export default function ContactLink() {
    const color = useColorModeValue("brand.500", "white")

    return (
        <Link
            style={{ fontWeight: 900 }}
            color={color}
            href={"mailto:braxton@coplay.io"}
        >
            contact us
        </Link>
    )
}