import { Button, Card, Flex, Radio, RadioGroup, Stack, Text, useColorModeValue, Wrap, WrapItem, Box } from "@chakra-ui/react";
import { UserPrivacySettings } from "lib/coplay/types/BackendTypes";
import { SettingsHumanReadableToValue } from "lib/coplay/utils/profile-settings-utils";
import { useXboxPrivacySettings } from "../hooks/useXboxPrivacySettigsHook";

export function XboxAccountPrivacySettings(props: { onSettings: (settings: UserPrivacySettings) => Promise<void>, settings?: UserPrivacySettings }) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('gray.700', 'gray.300');

    const {
        enrichedSettings,
        originalSettings,
        currentSettings,
        isDirty,
        isLoading,
        handleSettingChange,
        handleSaveClick
    } = useXboxPrivacySettings(props.settings);

    const enrichedSettingsElem = enrichedSettings.map((setting, index) => {
        const originalValue = originalSettings?.settings.find(s => s.setting === setting.setting)?.value;
        const currentValue = currentSettings?.settings.find(s => s.setting === setting.setting)?.value;
        const isChanged = originalValue !== undefined && originalValue !== currentValue;

        return (
            <WrapItem key={index} w='350px' maxH='175px' p='2' border='1px solid gray'>
                <div style={{ width: '250px' }}>
                    <Text color={textColorPrimary} fontWeight={"bold"}>
                        {setting.description}
                    </Text>
                    <Text color={textColorSecondary}>
                        {setting.details}
                    </Text>
                </div>
                <RadioGroup ml='2' w={'100px'} value={currentValue} onChange={e => handleSettingChange(setting.setting, e as string)}>
                    <Stack direction="column">
                        {setting.options.map((option, i) => (
                            <Radio key={i} value={SettingsHumanReadableToValue[option]}>
                                {option}
                            </Radio>
                        ))}
                    </Stack>
                </RadioGroup>
            </WrapItem>
        );
    });

    return (
        <>
            <Text as='h3' fontSize='xl' fontWeight='bold'>Privacy Settings</Text>
            <Text fontWeight='500' mb='4' fontSize='sm'>Privacy settings are a way to control what information is shared with other users. You can find change these from CoPlay.</Text>
            <Card overflowY='scroll'>
                <Box position="fixed" m='3' bottom="20px" right="20px" zIndex="1000">
                    <Button
                        w='125px'
                        zIndex="1000"
                        isLoading={isLoading}
                        colorScheme="blue"
                        isDisabled={!isDirty || isLoading}
                        onClick={() => handleSaveClick(props.onSettings)}
                    >
                        Save Settings
                    </Button>
                </Box>
                <Wrap>
                    {enrichedSettingsElem.length > 0 ? enrichedSettingsElem : <SettingsZeroState />}
                </Wrap>
            </Card >
        </>
    );
}

function SettingsZeroState() {
    return (
        <Card h='100%' w='100%'>
            <Flex align='center' h='100%' w='100%' justifyContent='center' alignContent={'center'} mb='30px'>
                <Text fontWeight='bold' fontSize='2xl' mb='4px'>
                    Hmmm... It looks like you don't have any settings yet. Try refreshing your account.
                </Text>
            </Flex>
        </Card>
    )

}