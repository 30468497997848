import { AggRequest, AggResponse, BasicResponse, BatchInstallRequest, BatchPowerRequest, BatchRequest, DeleteKeyRequest, DeleteResponse, FriendingRequest, LinkConsoleRequest, LinkConsoleResponse, MintKeyRequest, MintKeyResponse, PartialXMProfile, QueryConsoleRequest, QueryConsoleResponse, RemoveScheduleRequest, RenameConsoleRequest, ScheduleEvent, ScheduleRequest, SearchedSoftware, SettingsRequest, UninstallRequest, UserPrivacySettings, XboxPower, XMProfileRequest } from "lib/coplay/types/BackendTypes";

import { OauthActions } from "lib/coplay/types/BackendTypes";
import { isCoplayResponse } from "lib/coplay/utils/utils";
import { BackendConfig } from 'config/generated';
import { getRequestToken } from "./firebase/service";
import { XMSuggestions } from "lib/coplay/types/FrontendTypes";

async function makeApiPostRequest(payload: string): Promise<Response> {
    const requestHeaders: Headers = new Headers();
    requestHeaders.append('Accept', 'application/json');
    requestHeaders.append('content-type', 'application/json');
    const response: Response = await fetch(BackendConfig.url, {
        method: 'POST',
        headers: requestHeaders,
        body: payload
    });

    return response;
}

async function handleCoplayResponse(response: Response, errorMessage: string) {
    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error(errorMessage + ": " + text);
    }
}

export async function addNewXboxUser(xAuthCode: string): Promise<AggResponse> {
    const payload: AggRequest = {
        oauthAction: OauthActions.Aggregate,
        xAuthCode: xAuthCode,
        firebaseToken: await getRequestToken(),
        requestId: Date.now()
    }

    const response: Response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json() as AggResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to add a new user: " + text);
    }
}

export async function reAggregateUser(xAuthCode: string): Promise<AggResponse> {
    const payload: AggRequest = {
        oauthAction: OauthActions.ReAggregate,
        xAuthCode: xAuthCode,
        firebaseToken: await getRequestToken(),
        requestId: Date.now()
    }

    const response: Response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json() as AggResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to re-aggregate user: " + text);
    }
}

export async function deleteUsers(emailAddresses: string[]): Promise<DeleteResponse> {
    const payload: BatchRequest = {
        oauthAction: OauthActions.Delete,
        firebaseToken: await getRequestToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json() as DeleteResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to refresh users: : " + text);
    }
}

export async function refreshUsers(emailAddresses: string[]) {
    const payload: BatchRequest = {
        oauthAction: OauthActions.Refresh,
        firebaseToken: await getRequestToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to refresh users: : " + text);
    }
}

export async function handleXboxFriending(
    emailAddresses: string[],
    friendXuids: string[],
    oauthAction:
        OauthActions.AddFriends | OauthActions.RemoveFriends | OauthActions.SyncFriends) {
    const payload: FriendingRequest = {
        oauthAction: oauthAction,
        firebaseToken: await getRequestToken(),
        requestId: Date.now(),
        emailAddresses: emailAddresses,
        friendXuids: friendXuids
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error(`Failed to handle friending ${oauthAction}: ${text}`);
    }
}

export async function clearConversations(emailAddresses: string[]) {
    const payload: BatchRequest = {
        oauthAction: OauthActions.ClearConversations,
        firebaseToken: await getRequestToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    return await handleCoplayResponse(
        response,
        `Failed to clear conversations for ${emailAddresses.length} users`
    );
}

export async function changeSettings(emailAddress: string[], settings: UserPrivacySettings) {
    const payload: SettingsRequest = {
        oauthAction: OauthActions.ChangeSettings,
        firebaseToken: await getRequestToken(),
        requestId: Date.now(),
        emailAddresses: emailAddress,
        settings: settings
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as BasicResponse;
        if (res.error)
            throw (res.error);
        return res;
    } else {
        const text = await response.text();
        throw new Error(`Failed to Change user settings ${text}`);
    }
}

/*
Possible Requests
- Refresh
- Sync
- Remove all 
- Clear all conversations
*/
export async function scheduleAction(scheduledEvent: ScheduleEvent) {
    const token = await getRequestToken();
    const payload: ScheduleRequest = {
        oauthAction: OauthActions.AddSchedule,
        firebaseToken: token,
        requestId: Date.now(),
        scheduledEvent,
        emailAddresses: scheduledEvent.scheduledRequest.emailAddresses
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    return await handleCoplayResponse(
        response,
        `Failed to schedule action ${scheduledEvent.scheduleId}`
    );
}

export async function removeFromSchedule(scheduleId: string, emailAddress?: string): Promise<BasicResponse> {
    const payload: RemoveScheduleRequest = {
        oauthAction: OauthActions.RemoveSchedule,
        firebaseToken: await getRequestToken(),
        requestId: Date.now(),
        scheduleId: scheduleId,
        emailAddress: emailAddress ? emailAddress : null
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    return await handleCoplayResponse(
        response,
        `Failed to unschedule action ${scheduleId} for ${emailAddress ? emailAddress : 'all'}`
    );
}


//
// Xbox Device Link
//

export async function getConsolesForUser(emailAddress: string): Promise<string[]> {
    const payload: QueryConsoleRequest = {
        oauthAction: OauthActions.QueryConsoles,
        firebaseToken: await getRequestToken(),
        requestId: Date.now(),
        emailAddress: emailAddress
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as QueryConsoleResponse;
        return res.consoleIds;
    } else {
        const text = await response.text();
        throw new Error(`Failed to get consoles for ${emailAddress}: ${text}`);
    }
}

export async function linkConsole(emailAddress: string, consoleId: string, enable: boolean = true) {
    const oauthAction = enable ? OauthActions.LinkConsole : OauthActions.UnlinkConsole;
    const payload: LinkConsoleRequest = {
        oauthAction,
        firebaseToken: await getRequestToken(),
        requestId: Date.now(),
        emailAddress,
        consoleId
    };

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as LinkConsoleResponse;
        if (res.error) throw new Error(res.error.errorMessage);
        if (res.consoleId === consoleId) return res.consoleId;
    } else {
        const text = await response.text();
        throw new Error(`Failed to link console ${consoleId} for ${emailAddress}: ${text}`);
    }
}



//
// Xbox Device Requests
//



export async function refreshConsoles(emailAddresses: string[]) {
    const payload: BatchRequest = {
        oauthAction: OauthActions.RefreshConsole,
        firebaseToken: await getRequestToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));
    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to refresh consoles: : " + text);
    }
}


export async function batchTogglePower(emailAddresses: string[], powerState: XboxPower) {
    const payload: BatchPowerRequest = {
        oauthAction: OauthActions.ToggleConsolePower,
        firebaseToken: await getRequestToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now(),
        powerState
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to toggle power: " + text);
    }
}

export async function renameConsole(emailAddress: string, consoleId: string, name: string) {
    const payload: RenameConsoleRequest = {
        oauthAction: OauthActions.RenameConsole,
        firebaseToken: await getRequestToken(),
        requestId: Date.now(),
        emailAddress: emailAddress,
        consoleId,
        name
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as LinkConsoleResponse; // TODO: Add RenameConsoleResponse
        if (res.error)
            throw (res.error);
        if (res.consoleId === consoleId) {
            return res.consoleId;
        }
    } else {
        const text = await response.text();
        throw new Error(`Failed to link console ${consoleId} for ${emailAddress}: ${text}`);
    }
}

export async function uninstallSoftware(emailAddress: string, consoleId: string, instanceIds: string[]): Promise<BasicResponse> {
    const payload: UninstallRequest = {
        oauthAction: OauthActions.UninstallSoftware,
        firebaseToken: await getRequestToken(),
        requestId: Date.now(),
        emailAddress: emailAddress,
        consoleId: consoleId,
        instanceIds: instanceIds
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as BasicResponse;
        if (res.error)
            throw (res.error);

        return res;
    } else {
        const text = await response.text();
        throw new Error(`Failed to uninstall software ${text}`);
    }
}

export async function batchInstallSoftware(emailAddresses: string[], software: SearchedSoftware[]) {
    const payload: BatchInstallRequest = {
        oauthAction: OauthActions.BatchInstall,
        firebaseToken: await getRequestToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now(),
        software
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to batch install software: " + text);
    }
}




//
// CoPlay Profile Requests
//



export async function changeXMProfile(emailAddress: string[], action: 'unset' | 'sync' | 'set', xmProfile: PartialXMProfile) {
    const payload: XMProfileRequest = {
        oauthAction: OauthActions.XMProfile,
        firebaseToken: await getRequestToken(),
        requestId: Date.now(),
        emailAddresses: emailAddress,
        action: action,
        profile: xmProfile
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as BasicResponse;
        if (res.error)
            throw (res.error);

        return res;
    } else {
        const text = await response.text();
        throw new Error(`Failed to change user profile ${text}`);
    }
}

export async function syncXMProfile(emailAddress: string[]) {
    const payload: XMProfileRequest = {
        oauthAction: OauthActions.XMProfile,
        firebaseToken: await getRequestToken(),
        requestId: Date.now(),
        emailAddresses: emailAddress,
        action: 'sync'
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as BasicResponse;
        if (res.error)
            throw (res.error);

        return res;
    } else {
        const text = await response.text();
        throw new Error(`Failed to change user profile ${text}`);
    }
}





//
// Xbox Game Search
//



export async function getXboxGameSuggestions(searchText: string, abortSignal: AbortSignal): Promise<XMSuggestions[]> {
    if (searchText === '')
        return []

    const res = await fetch(BackendConfig.xboxGameSearchUrl, {
        signal: abortSignal,
        method: 'POST',
        body: JSON.stringify({
            action: "getSuggestion",
            query: searchText
        })
    })

    if (!res.ok) {
        throw Error(`Error occured while getting game suggestions: ${JSON.stringify(res)}`)
    }

    const data = await res.json()
    if (data.suggestions && data.suggestions.length > 0) {
        return data.suggestions
    } else {
        console.warn("[getXboxGameSuggestions] No suggestions returned")
        return []
    }
}

export async function getXboxGameSuggestionDetails(gameId: string) {
    try {
        const res = await fetch(BackendConfig.xboxGameSearchUrl, {
            method: 'POST',
            body: JSON.stringify({
                action: "getSuggestionDetails",
                bigCatId: gameId
            })
        })
        const data = await res.json()

        if (Array.isArray(data.details) && data.details.length > 0) {
            return data.details[0]
        }

        throw new Error("Could not get error details")
    } catch (err) {
        console.warn("Error getting suggestion details: ", err)
        return null
    }
}

//
// API Keys
//

export async function mintApiKey(name?: string): Promise<MintKeyResponse> {
    const payload: MintKeyRequest = {
        oauthAction: OauthActions.MintKey,
        firebaseToken: await getRequestToken(),
        name: name?.trim() || undefined,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));
    if (isCoplayResponse(response.status)) {
        const mintKeyResponse: MintKeyResponse = await response.json() as MintKeyResponse;
        return mintKeyResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to mint key: " + text);
    }
}

export async function deleteApiKey(keyid: string): Promise<BasicResponse> {
    const payload: DeleteKeyRequest = {
        oauthAction: OauthActions.DeleteKey,
        firebaseToken: await getRequestToken(),
        keyId: keyid,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));
    if (isCoplayResponse(response.status)) {
        return await response.json() as BasicResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to delete key: " + text);
    }
}
