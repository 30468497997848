import DaySelector from "./DaySelector"
import { ScheduleFrequency } from "lib/coplay/types/BackendTypes"

export default function DaySelectors(
    props: { frequency: ScheduleFrequency, getStart: Date, frequencyStart: Function }
) {
    let daySelectors: JSX.Element
    if (props.frequency === ScheduleFrequency.Daily
        || props.frequency === ScheduleFrequency.Weekly) {
        const selectorsList = [1, 2, 3, 4, 5, 6, 0].map((day: number) => {
            return (
                <DaySelector
                    key={day}
                    day={day}
                    frequency={props.frequency}
                    getStart={props.getStart}
                    frequencyStart={props.frequencyStart}
                />
            )
        })

        daySelectors = (
            <div style={{ padding: "16px 68px 0", display: "flex", width: "100%", flexWrap: "wrap" }}>
                {selectorsList}
            </div>
        )
    } else {
        daySelectors = null
    }

    return daySelectors
}