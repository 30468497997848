import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Button, ModalFooter, MenuItem, Text, useToast, ModalHeader } from "@chakra-ui/react";
import { changeXMProfile } from "lib/backend/api";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { PartialXMProfile } from "lib/coplay/types/BackendTypes";
import { useContext } from "react";
import { MdOutlinePlaylistRemove } from "react-icons/md";


const useRemoveProfile = () => {
    const { selectedUsers, clearSelectedUsers } = useContext(XboxUsersContext)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();


    const handleConfirm = () => {
        const selected: string[] = [];
        selectedUsers.forEach(user => selected.push(user.emailAddress))

        if (selected.length === 0) return;


        const emptyProfile: PartialXMProfile = {
            id: "",
            name: "",
        }

        const req = new Promise((resolve, reject) => {
            clearSelectedUsers()
            onClose()
            changeXMProfile(selected, 'unset', emptyProfile).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })

        toast.promise(req, {
            success: (result) => ({
                title: "Removing Profiles complete",
                description: `Synced profile to ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 1000,
            }),
            error: (error) => ({
                title: "Removing Profiles failed",
                description: `Failed to remove profile from ${selectedUsers.size()} user(s) - ${error.message}`,
                position: "bottom-right",
                isClosable: true,
            }),
            loading: {
                title: "Removing Profiles...",
                description: `Removing profile from ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 1000,
            },
        });
    }


    return {
        handleConfirm,
        numUsers: selectedUsers.size(),
        isOpen,
        onOpen,
        onClose
    }
}

export function RemoveProfileMenuItem() {
    const { handleConfirm, isOpen, onOpen, onClose, numUsers } = useRemoveProfile()

    return (<>
        <MenuItem icon={<MdOutlinePlaylistRemove />} onClick={onOpen}>
            Remove Profile
        </MenuItem>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Remove Profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Are you sure you want to remove the profile of {numUsers} user(s)?</Text>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleConfirm}>Confirm</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>)
}