import { useDisclosure, Box, InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { SearchedSoftware } from "lib/coplay/types/BackendTypes";
import { XMSuggestions } from "lib/coplay/types/FrontendTypes";
import React, { useState } from "react";
import { useDebouncedSearch } from "./debouncedSearchHook";
import { XboxGameSuggestionsList } from "./XboxGameSuggestionsList";
import XboxGameDetailsModal from "./XboxGameDetailsModal";

export function XboxGameSearch(props: {
    onSelected: (app: SearchedSoftware) => void,
    editing: boolean,

}) {
    const { inputValue, handleChange, gameList, isLoading } = useDebouncedSearch(props.onSelected);
    const [selectedGame, setSelectedGame] = React.useState<XMSuggestions | null>(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isFocused, setIsFocused] = useState(false);

    if (props.editing) {

        const onCloseModal = () => {
            setSelectedGame(null)
            onClose()
            setIsFocused(true)
        }

        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
        };

        const onSelect = (game: XMSuggestions) => {
            setSelectedGame(game)
            onOpen()
        }

        return (
            <>
                <XboxGameDetailsModal
                    game={selectedGame}
                    onSelected={props.onSelected}
                    isOpen={isOpen}
                    onClose={onCloseModal}
                />
                <Box w="100%" maxW="md" pb="15px" position="relative">
                    <InputGroup>
                        <Input
                            placeholder="Search for a Game"
                            value={inputValue}
                            onChange={(e) => {
                                handleChange(e.target.value);
                            }}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            isDisabled={!props.editing}
                        />
                        <InputRightElement children="🔍" />
                    </InputGroup>
                    {isFocused &&
                        <XboxGameSuggestionsList
                            suggestions={gameList}
                            isLoading={isLoading}
                            onClick={onSelect}
                        />
                    }
                </Box>
            </>
        )
    } else {
        return <></>
    }


}