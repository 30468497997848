import { Avatar, Box, Card, Flex, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Spacer, Text, useColorModeValue, Wrap, WrapItem } from "@chakra-ui/react";
import { SupportedVendors, VendorMember } from "lib/coplay/types/PaymentTypes";
import { FaEllipsisV } from "react-icons/fa";

import DisneyPlus from '../../../assets/disney_plus_icon.png';
import XboxIcon from '../../../assets/xbox_icon.png';
import { SubscriptionRenderer } from "./SubscriptionRenderer";
import { getFundingSource } from "../payment-utils";

export function PaymentsMember(props: { member: VendorMember, setSelectedMember: (member: VendorMember) => void }) {
    const onHoverColor = useColorModeValue('brand.500', 'white');
    const secondaryColor = useColorModeValue('gray.400', 'white');
    const cardShadow = useColorModeValue('0px 0px 20px rgba(112, 144, 176, 0.12)', 'unset');
    const bgHover = useColorModeValue('secondaryGray.300', 'whiteAlpha.50');


    return (
        <Card
            key={props.member.id}
            background='transparent'
            _hover={{ color: onHoverColor, cursor: 'pointer', background: bgHover }}
            width="100%"
            boxShadow={cardShadow}
            mb='10px'
            p={{ base: '10px', md: '15px', lg: '15px', sm: '25px' }}
            onClick={() => props.setSelectedMember(props.member)}
        >
            <Wrap align="center" spacingY="20px">
                <WrapItem>
                    <Flex alignItems="center" style={{ flexWrap: "wrap" }}>
                        <Avatar src={props.member.vendor === SupportedVendors.Disney ? DisneyPlus : XboxIcon} />
                        <Flex style={{ width: "247px" }} alignItems="center">
                            <Box>
                                <Text px="15px" fontWeight='bold' fontSize='md' style={{ overflowWrap: "anywhere" }}>
                                    {props.member.email}
                                </Text>
                                <Text px="15px" fontWeight='bold' fontSize='xs'>
                                    {props.member.vendor}
                                </Text>
                                <Text color={secondaryColor} px="15px" fontWeight='bold' fontSize='xs'>
                                    Last Updated: {new Date(props.member.updatedAt).toLocaleString()}
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                </WrapItem>

                <WrapItem>
                    {getFundingSource(props.member)}
                </WrapItem>

                <Spacer />
                <WrapItem>
                    <Box width="200px">
                        <SubscriptionRenderer subscriptions={props.member.subscriptions} />
                    </Box>
                </WrapItem>
                <WrapItem overflow={'hidden'} textOverflow={'ellipsis'} h="50px" w="180px">
                    <Text textAlign="center" color={secondaryColor} minW="150px" fontWeight='bold' fontSize='sm'>
                        {props.member.notes || "Notes"}
                    </Text>
                </WrapItem>
                <WrapItem>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            icon={<Icon as={FaEllipsisV} />}
                            variant="ghost"
                            onClick={(e) => e.stopPropagation()}
                        />
                        <MenuList>
                            {/* <MenuItem onClick={(e) => { e.stopPropagation(); deleteVendorMember(props.member.email) }}>Delete User</MenuItem> */}
                            <MenuItem onClick={(e) => { e.stopPropagation(); }}>Delete User</MenuItem>
                        </MenuList>
                    </Menu>
                </WrapItem>
            </Wrap>
        </Card>
    )
}