import { Box, Text, Badge, Flex } from '@chakra-ui/react';
import { LogEntry, ResponseStatus, XMError, XMResponse } from 'lib/coplay/types/BackendTypes';

const statusToTextMap = {
    [ResponseStatus.Success]: 'Success',
    [ResponseStatus.Fail]: 'Fail',
    [ResponseStatus.Mixed]: 'Mixed',
    [ResponseStatus.Warning]: 'Warning',
    [ResponseStatus.Pending]: 'Pending',
    [ResponseStatus.LockFail]: 'Lock Fail',
}

const statusToColorMap = {
    [ResponseStatus.Success]: 'green',
    [ResponseStatus.Fail]: 'red',
    [ResponseStatus.Mixed]: 'yellow',
    [ResponseStatus.Warning]: 'orange',
    [ResponseStatus.Pending]: 'blue',
    [ResponseStatus.LockFail]: 'purple',
}

export function XboxAccountLogItem(props: { logEntry: LogEntry }) {
    const { logEntry } = props;
    const { msg, type, response, timestamp } = logEntry;

    const renderResponse = (response: XMResponse) => {
        if (!response) return null;

        return (
            <Box p={1} borderWidth="1px" borderRadius="lg">
                <Text fontWeight="bold">Response:</Text>
                <Text>Action: {response.oauthAction}</Text>
                <StatusRenderer status={response.status} />
                {response.error && <LogError error={response.error} />}
            </Box>
        );
    };

    return (
        <Box borderWidth="1px" borderRadius="lg">
            <Flex alignItems="center">
                {type && (
                    <>
                        <Box textAlign='center' mx={2}>
                            <Badge w='75px' colorScheme={type === 'user' ? 'green' : 'blue'}>
                                {type}
                            </Badge>
                        </Box>
                        <Box>
                            <Text fontWeight="bold">Message: {msg}</Text>
                            {timestamp && (
                                <Text fontSize="sm" color="gray.600">
                                    {new Date(timestamp).toLocaleString()}
                                </Text>
                            )}
                        </Box>
                    </>
                )}

            </Flex>
            {response && renderResponse(response)}
        </Box>

    );
};

function StatusRenderer(props: { status: ResponseStatus }) {
    // HACK
    const text = statusToTextMap[props.status as keyof typeof statusToTextMap] || 'Unknown';
    const color = statusToColorMap[props.status as keyof typeof statusToColorMap] || 'gray';

    return (
        <Flex>
            <Text>Status: <Text as="span" fontWeight="bold" color={color}>{text}</Text></Text>
        </Flex>
    );
}

function LogError(props: { error: XMError }) {
    return (
        <Box mt={2} p={2} borderWidth="1px" borderRadius="lg">
            <Text fontWeight="bold">Error:</Text>
            <Text>Message: {props.error.userMessage}</Text>
            <Text>Suggestion: {props.error.suggestion}</Text>
            <Text>Raw: {props.error.errorMessage}</Text>
        </Box>
    );
};