import { User } from "firebase/auth";
import { Firestore, collection, documentId, onSnapshot, query, where } from "firebase/firestore";
import { XboxConsole } from "lib/coplay/types/BackendTypes";

export class XboxDeviceSidekick {
    _user: User;
    _db: Firestore;

    constructor(user: User, db: Firestore) {
        this._user = user;
        this._db = db;
    }

    getXboxDeviceCollectionRef = () => {
        return collection(this._db, `${this._user.email}/data/consoles/`);
    }

    subscribeToDeviceDetails = (emailAddress: string, callback: (consoleDetails: XboxConsole) => void) => {
        try {
            const q = query(this.getXboxDeviceCollectionRef(), where(documentId(), "==", emailAddress))
            const unsubscribe = onSnapshot(q, (snapshot) => {
                if (snapshot.empty) {
                    console.warn(`[XboxDeviceSidekick.subscribeToDetails] No console details found for ${emailAddress}`);
                    return callback(undefined);
                }
                snapshot.forEach((doc) => {
                    callback(doc.data() as XboxConsole);
                })
            });
            return unsubscribe;
        } catch (e) {
            console.error(`[XboxDeviceSidekick.subscribeToDetails] Error: ${e}`);
        }
    }


}
