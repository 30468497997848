import { Select } from "@chakra-ui/react";

export enum SortOption {
    Email = 'Email',
    Xbox = "Xbox",
    GamerTag = 'GamerTag',
    Friends = 'Friends',
    Conversations = 'Conversations',
    NumFriends = 'NumFriends',
    Status = "Status",
    UpdatedAt = 'UpdatedAt',
}

export function SortOptionsDropdown(props: {
    sortOption: SortOption,
    onSortChange: (sortOption: SortOption) => void,
}) {

    return (
        <Select
            width={"256px"}
            variant='filled'
            size="md"
            onChange={(e) => props.onSortChange(e.target.value as SortOption)}
            value={props.sortOption}
        >
            <option value={SortOption.Email}>Sort By Email Address</option>
            <option value={SortOption.Xbox}>
                Sort By Xbox
            </option>
            <option value={SortOption.GamerTag}>Sort By Gamertag</option>
            <option value={SortOption.Conversations}>Sort By Conversations</option>
            <option value={SortOption.NumFriends}>Sort By Number Of Friends</option>
            <option value={SortOption.Status}>Sort By Status</option>
            <option value={SortOption.UpdatedAt}>Sort By Last Updated</option>
        </Select>
    )
}