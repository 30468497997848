import { useEffect, useState } from "react";

export function useXboxNotes(notes?: string, onNotesUpdate?: (notes: string) => void) {
    const [originalNotes, setOriginalNotes] = useState<string>(notes || '');
    const [updateIndicator, setUpdateIndicator] = useState(false);
    const saveButtonDisabled = originalNotes === notes || originalNotes === '';

    useEffect(() => {
        setOriginalNotes(notes || '');
    }, [notes]);

    const handleNotesChange = (e: { target: { value: string }; }) => {
        const updatedNotes = e.target.value;
        setOriginalNotes(updatedNotes);
    };

    const handleSaveClick = () => {
        if (onNotesUpdate) {
            onNotesUpdate(originalNotes);
            setUpdateIndicator(true);
            setTimeout(() => setUpdateIndicator(false), 2000); // Reset indicator after 2 seconds
        }
    };

    return {
        originalNotes,
        handleNotesChange,
        handleSaveClick,
        saveButtonDisabled,
        updateIndicator
    };
}
