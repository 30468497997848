import { SearchedSoftware } from "./BackendTypes";

export type XMSuggestions = {
    Title: string;
    Url: string;
    ImageUrl: string;
    BigCatalogId: string;
}

export type FrontendConfiguration = {
    numUsers?: number,
    hasPayments?: boolean
}

export type ContactDetails = {
    firstName: string,
    lastName: string,
    emailAddress: string,
    phone: string,
    title: string,
    url: string,
    companyName: string,
    companyLoc: string,
    joinedDate: string
}

export const EmptyContactDetails: ContactDetails = {
    firstName: "Empty",
    lastName: "Empty",
    emailAddress: "Empty",
    phone: "Empty",
    title: "Empty",
    url: "Empty",
    companyName: "Empty",
    companyLoc: "Empty",
    joinedDate: "Empty",
}

export const CommonApps: SearchedSoftware[] = [
    {
        name: 'Afterglow',
        productId: '9MVK44X6R37D',
        uniqueId: 'PerformanceDesignedProduc.Afterglow_xgavg6chk0qcj',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.45152.13862318860728181.8f97c621-ed9f-4820-bb49-cf2e6c4e6e0d.92f5d689-bac2-4bee-ba8c-e8c8634d8cea'
    },
    {
        name: 'AirServer Xbox Edition',
        productId: '9NBLGGH52NB8',
        uniqueId: 'F3F176BD.53203526D8F6C_p8qzvses5c8me',
        imageUri: ''
    },
    {
        name: 'Amazon Music (Xbox)',
        productId: '9MV42N00241D',
        uniqueId: 'AmazonMobileLLC.AmazonMusicXbox_kc6t79cpj4tp0',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.40249.13800305864973389.fc492248-d3a3-493f-8838-24d0f4b268f8.d6c9fa48-3fa2-4de9-be5a-a1c557e860a8'
    },
    {
        name: 'Animal Planet GO',
        productId: '9NBCJX3ZVWVN',
        uniqueId: 'DiscoveryCommunicationsIn.AnimalPlanetGO_qa3esp0sj9xn6',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.34658.13515612958758091.417e265f-c3b3-484d-8aad-8c5843158b44.24cdf742-ec3e-4589-b4e3-8d9cc55be576'
    },
    {
        name: 'Apple TV',
        productId: '9MW0ZWQFH0M2',
        uniqueId: 'AppleInc.AppleTV_nzyj5cx40ttqa',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.17733.13853294760425971.103d1b70-e74c-4fd7-b66b-ee706b309129.c50abb6d-0549-4c09-9ae6-5cef992fe6d9'
    },
    {
        name: 'Aqua TV',
        productId: '9NBLGGH4VN41',
        uniqueId: 'ExtraMileStudiosLtd.AquaTV_2bbdy7rfnx6tr',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.13934.13510798887507646.0d7dd71a-5a42-41b4-bba0-d146752a0302.d6bda35b-12e5-4759-aa51-5e71526c1b1b'
    },
    {
        name: 'Bravo',
        productId: '9NFNNT7W6D67',
        uniqueId: 'NBCUniversalMediaLLC.Bravo_3tyhdar1yvg98',
        imageUri: ''
    },
    {
        name: 'Crackle',
        productId: '9N2W64XF1RBR',
        uniqueId: 'CrackleInc.27457EC798CE9_gn1at2342eymg',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.19643.13948414145977472.65d3400e-000e-495a-add4-6a253801f7cf.7f40c83a-43b6-4009-896c-2eb520b9b909'
    },
    {
        name: 'Discovery GO',
        productId: '9NCSFT53JNHL',
        uniqueId: 'DiscoveryCommunicationsIn.DiscoveryGO_qa3esp0sj9xn6',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.8111.13562029958667155.38f35dc6-3268-49b4-a3a0-39ff4a83e066.a5f549ae-a936-4268-b94a-3fe468013e47'
    },
    {
        name: 'Disney+',
        productId: '9NXQXXLFST89',
        uniqueId: 'Disney.37853FC22B2CE_6rarf9sa4v8jt',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.10289.14495311847124170.e89a4dce-fd9a-4a10-b8e4-a6c3aa1c055e.51e28f9f-db71-4a5b-b9f4-efa79f714905'
    },
    {
        name: 'Dolby Access',
        productId: '9N0866FS04W8',
        uniqueId: 'DolbyLaboratories.DolbyAccess_rz1tebttyb220',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.36820.13925855090824389.09f473d9-ce97-499c-9d53-c21e8f64ee62.ffec57c5-f0db-4da5-91ac-7392fc1f892e'
    },
    {
        name: 'ESPN Xbox',
        productId: '9NB4D3VWKB55',
        uniqueId: 'ESPNInc.359487C84E950_hpt16c9c0eesj',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.9968.13520414838141068.8b353098-7898-4b65-bef0-162703c2d1f7.63ddb053-a3f6-4940-8334-00df94a15d43'
    },
    {
        name: 'Facebook Watch',
        productId: '9P1BNLC6DD69',
        uniqueId: 'Facebook.FacebookVideo_8xx8rvfyw5nnt',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.3637.14510015500928362.f2d712ab-0a87-46dc-8215-06af44859362.4654f298-c191-40b1-8d7d-0d5f5d144082'
    },
    {
        name: 'FandangoNOW',
        productId: '9NBLGGH4SJCZ',
        uniqueId: 'FandangoNOW.FandangoNOW_pm3yb19n4mdqg',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.64921.13510798887581344.c58e2294-a9c6-49b5-bde9-3b47753e1ada.8f70acd2-d443-4644-9be1-e8ec963b8b8e'
    },
    {
        name: 'Funimation',
        productId: '9NBLGGH5L4J7',
        uniqueId: 'FunimationProductionsLTD.FunimationNow_nat5s4eq2a0cr',
        imageUri: ''
    },
    {
        name: 'HBO Max',
        productId: '9PJJ1K9DZMRS',
        uniqueId: 'HomeBoxOfficeInc.HBONOW_k40401y96tbnw',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.36498.14281583492175081.008f77e3-4604-4413-bc61-653ee00d6dd3.01289e93-596b-40d6-8873-b4aeb5b3bc2c'
    },
    {
        name: 'Hulu',
        productId: '9WZDNCRFJ3L1',
        uniqueId: 'HuluLLC.HuluPlus_fphbd361v8tya',
        imageUri: ''
    },
    {
        name: 'Karaoke One',
        productId: '9NBLGGH529WB',
        uniqueId: 'LisariSrl.2294003C7EFE3_v77s55f3ynft6',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.39409.13510798887652831.5e08861d-3806-4f86-9b41-070f18524244.d5a1fbe8-ab85-4498-99d9-ded79e30d176'
    },
    {
        name: 'NASCAR',
        productId: '9WZDNCRFJ49W',
        uniqueId: 'NASCARDigitalMediaLLC.NASCAR_vx5dhyhck8ak4',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.21120.9007199266244622.5c95a9db-8a59-4571-949a-902efcde4b7d.eef1cd2c-706b-45bd-b996-0fb982241a67'
    },
    {
        name: 'NBC',
        productId: '9N613THZWJ8R',
        uniqueId: 'NBCUniversalMediaLLC.NBC-WatchFullTVEpisodes_3tyhdar1yvg98',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.21593.14027630738209272.723b08c2-f50b-4bad-9167-3a93bebfc77f.4988ca08-552b-4d59-8845-713ca5f25324'
    },
    {
        name: 'NPR One',
        productId: '9WZDNCRCSG0J',
        uniqueId: 'NPROne.NPROne_301e00acq7vap',
        imageUri: 'https://store-images.microsoft.com/image/apps.62747.9007199266817663.0dc3ebe6-deb3-468c-a203-a193a4927fb2.b9ec81fe-1d33-4505-9f1a-2877ebbc3ccc'
    },
    {
        name: 'Netflix',
        productId: '9WZDNCRFJ3TJ',
        uniqueId: '4DF9E0F8.Netflix_mcm4njqhnhss8',
        imageUri: ''
    },
    {
        name: 'Nitrado',
        productId: '9P2CPMVCW0LX',
        uniqueId: 'marbisGmbH.Nitrado_ntn371j05xzh0',
        imageUri: ''
    },
    {
        name: 'Pandora',
        productId: '9WZDNCRFJ46V',
        uniqueId: 'PandoraMediaInc.29680B314EFC2_n619g4d5j0fnw',
        imageUri: ''
    },
    {
        name: 'Paramount+',
        productId: '9WZDNCRFJ0WK',
        uniqueId: '2BDFC20A.CBS_bd059sf7kn2rm',
        imageUri: ''
    },
    {
        name: 'PeacockTV',
        productId: '9PL67R4P9PG5',
        uniqueId: 'PeacockTVLLC.PeacockTV_4aes6aww7z2rj',
        imageUri: ''
    },
    {
        name: 'Plex',
        productId: '9NBLGGH3ZZVF',
        uniqueId: 'CAF9E577.PlexforXbox_aam28m9va5cke',
        imageUri: ''
    },
    {
        name: 'Red Bull TV',
        productId: '9WZDNCRDFN4C',
        uniqueId: 'RedBull.RedBullTV_mzhpkbwn2eczy',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.15228.9007199266286060.2b732ba4-2d9d-403c-82ac-83649306b8a1.403bacf1-ce77-43e9-90e8-391cfc745dfa'
    },
    {
        name: 'Rooster Teeth',
        productId: '9NBLGGH43D8W',
        uniqueId: 'RoosterTeethApps.RoosterTeethFIRST_v2cmybx7f0rc0',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.51553.13510798887926756.033de3be-e9d8-4783-9046-22455449a26c.9ee39a70-5b7a-4377-b3e5-c5564f9b42a7'
    },
    {
        name: 'STARZ',
        productId: '9NBLGGH510WB',
        uniqueId: 'STARZEntertainment.STARZ_g1z46p8ysz53c',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.52322.13510798887623419.169849c1-e9fe-462a-86b0-69271e11a1e9.b7ff09eb-4e32-4064-8593-9acfeb3212b5'
    },
    {
        name: 'Showtime Anytime',
        productId: '9PMB97FG9N4R',
        uniqueId: 'ShowtimeNetworksInc.ShowtimeAnytime_egpzkc5pe543a',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.14023.14301138863699952.ad4da719-6de7-49ba-8478-58f9a2834e92.4b655e41-a4ec-4c21-a60a-aff7e37da177'
    },
    {
        name: 'SoundCloud',
        productId: '9N5QR3KW6CMC',
        uniqueId: 'SoundcloudLtd.SoundCloud-MusicAudio_2xc63xn306dnw',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.28286.14033286175227382.282c996f-ebe1-4501-9b5e-4852bd1dd1ba.89119317-e29c-4b73-8ab9-2f2eef0459cc'
    },
    {
        name: 'Spectrum TV',
        productId: '9MVSBC4GLG8J',
        uniqueId: 'CharterCommunicationsInc.SpectrumTV_1mfw0xv962ep8',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.19528.13857591332523951.2b893a30-407f-4eb1-951d-05f83739e41f.4d97ed0a-32bb-4c51-9cfe-2830b03d4b1e'
    },
    {
        name: 'Spotify Xbox - Music and Podcasts',
        productId: '9NFQ49H668TB',
        uniqueId: 'SpotifyAB.SpotifyMusic-forXbox_zpdnekdrzrea0',
        imageUri: ''
    },
    {
        name: 'TLC GO',
        productId: '9PH1TJ8NWSXS',
        uniqueId: 'DiscoveryCommunicationsIn.TLCGO_qa3esp0sj9xn6',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.563.14244888962835279.0c07c0e1-8968-4a7d-9601-f1100fd5eac3.bbf0e8fe-d8c8-4d6c-8e55-e09c53b37967'
    },
    {
        name: 'Telemundo',
        productId: '9P0DQJMZV6C5',
        uniqueId: 'NBCUniversalMediaLLC.Telemundo_3tyhdar1yvg98',
        imageUri: ''
    },
    {
        name: 'Twitch',
        productId: '9PFJP1Q9R4FK',
        uniqueId: 'TwitchInteractive.TwitchApp_7kd9w9e3c5jra',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.1272.14203868070568856.9b4fd5cc-55f3-4f46-ac25-ac75178204e0.04cfa886-d123-4e58-bb95-f3675aaf94ac'
    },
    {
        name: 'USA Network',
        productId: '9NGDHHJWQRQ6',
        uniqueId: 'NBCUniversalMediaLLC.USANetwork_3tyhdar1yvg98',
        imageUri: ''
    },
    {
        name: 'Ubisoft Connect',
        productId: '9PGQ6G1K5QD7',
        uniqueId: 'Ubisoft.16898A56DAAC1_ngz4m417e0mpw',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.26924.14246812968525544.728aa0c9-2d78-4159-9318-ba80830aedbc.1889acd6-fb29-456c-9fa4-7ff33e467313'
    },
    {
        name: 'VRV',
        productId: '9NBLGGH4V9DB',
        uniqueId: '15EF7777.VRV_mgdgtskya6f22',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.63740.13510798887488321.8e8dcdc9-0117-4431-8815-3d8701da9781.af945124-339d-4f15-8411-0372890488f7'
    },
    {
        name: 'VUDU Movies and TV',
        productId: '9NBLGGH4V68B',
        uniqueId: '95FE1D22.VUDUMoviesandTV_0wkekwh8d6p78',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.4481.13510798887487045.3b19bfa3-85a2-4738-ab7f-9e173c7fdfc5.367599bf-19cd-4954-a40a-3c9dd3d7d193'
    },
    {
        name: 'WWE Network',
        productId: '9NBLGGH1ZRGP',
        uniqueId: '6FA0E4A0.WWENetwork_46xvzjh8v0pjy',
        imageUri: 'https://store-images.s-microsoft.com/image/apps.42150.13510798886199994.45091149-ec20-48cb-b8f7-cb7f09483665.c03b4a37-fc07-4061-bef3-5b2df9674707'
    },
    {
        name: 'YouTube',
        productId: '9NDP7KTLK7W3',
        uniqueId: 'GoogleInc.YouTube_yfg5n0ztvskxp',
        imageUri: ''
    },
    {
        name: 'YouTube TV',
        productId: '9NCPJ3XP3FN8',
        uniqueId: 'GoogleInc.YouTubeTV_yfg5n0ztvskxp',
        imageUri: ''
    }
]