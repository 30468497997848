import { useEffect, useState } from "react";
import { Box, Text, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Button, Input } from "@chakra-ui/react";
import Card from "lib/horizon/components/card/Card";
import { createSchedules } from "./ScheduleListItem";
import { SchedulingTimingDetails } from "./SchedulingTimingDetails";
import { ScheduleEvent } from "lib/coplay/types/BackendTypes";
import { removeFromSchedule } from "lib/backend/api";
import { getXboxSchedulingData } from "lib/backend/firebase/service";

// TODO: Refactor logic out into it's own hook
export function SchedulesView() {
    const [schedules, setSchedules] = useState<ScheduleEvent[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedSchedule, setSelectedSchedule] = useState<ScheduleEvent | null>(null);
    const [scheduleName, setScheduleName] = useState<string>('');
    const [originalName, setOriginalName] = useState<string>('');

    const onSchedules = (events: ScheduleEvent[]) => {
        setSchedules(events)
        setLoading(false)
    }
    useEffect(() => {
        const unsub = getXboxSchedulingData().subscribeToAllSchedules(onSchedules)
        return unsub;
    }, []);

    const openScheduleDetails = (schedule: ScheduleEvent) => {
        setSelectedSchedule(schedule);
        setScheduleName(schedule.name || '');
        setOriginalName(schedule.name || '');
        onOpen();
    }

    const handleNameChange = (newName: string) => {
        setScheduleName(newName);
    }

    const handleSaveName = () => {
        if (selectedSchedule) {
            getXboxSchedulingData()
                .updateScheduleName(selectedSchedule.scheduleId, scheduleName);
        }
        onClose();
    }

    let schedulesList = null;
    if (loading) {
        schedulesList = <Text>Loading...</Text>;
    }

    if (schedules.length === 0) {
        schedulesList = <Text>No schedules to display.</Text>;
    } else {
        schedulesList = createSchedules(schedules, openScheduleDetails);
    }

    return (
        <Box pb="10px" h="93vh">
            <Card h='100%' overflowY='scroll' >
                <Box borderWidth="1px" borderRadius="lg" p={4} h='100%'>
                    <Text fontSize="2xl" fontWeight="bold" mb={4}>Scheduled Actions</Text>
                    {schedulesList}
                    {selectedSchedule && (
                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Schedule Details</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Text fontSize="md" fontWeight="bold" mb={2}>Schedule Name:</Text>
                                    <Input
                                        value={scheduleName}
                                        onChange={(e) => handleNameChange(e.target.value)}
                                        placeholder="Enter schedule name"
                                        mb={4}
                                    />
                                    <Text fontSize="md">Action: {selectedSchedule.scheduledRequest.oauthAction}</Text>
                                    <Text fontSize="md">Frequency: {selectedSchedule.frequency}</Text>
                                    <SchedulingTimingDetails selectedSchedule={selectedSchedule} />
                                    <Text pt='15px' fontWeight='bold' fontSize="md">Users</Text>
                                    {selectedSchedule.scheduledRequest.emailAddresses.map((email) =>
                                        <Text key={email} fontSize="md">{email}</Text>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        colorScheme="blue"
                                        mr={3}
                                        onClick={handleSaveName}
                                        isDisabled={scheduleName === originalName}
                                    >
                                        Save Name
                                    </Button>
                                    <RemoveScheduleButton
                                        onClose={onClose}
                                        scheduleId={selectedSchedule.scheduleId}
                                        emailAddress={"all"}
                                    />
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export function RemoveScheduleButton(
    props: { onClose: Function, scheduleId: string, emailAddress: string }
) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleUnschedule = () => {
        setIsLoading(true)
        removeFromSchedule(props.scheduleId, props.emailAddress).then((response) => {
        }).catch((error: any) => {
            console.error(`[UnscheduleButton] Error: ${JSON.stringify(error)}`)
        }).finally(() => {
            props.onClose()
        })
    }

    return (
        <Button isLoading={isLoading} colorScheme='red' variant='ghost' onClick={handleUnschedule}>Delete Schedule</Button>
    )
}

export default SchedulesView;