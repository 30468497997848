import { getXboxGameSuggestions } from "lib/backend/api";
import { SearchedSoftware } from "lib/coplay/types/BackendTypes";
import { XMSuggestions } from "lib/coplay/types/FrontendTypes";
import { useEffect, useState } from "react";

const CACHE_EXPIRATION_MS = 3628800000 // 6 weeks

export const useDebouncedSearch = (onSelected: (app: SearchedSoftware) => void) => {
    const [inputValue, setInputValue] = useState("");
    const [debouncedInput, setDebouncedInput] = useState("");
    const [gameList, setGameList] = useState<XMSuggestions[]>([]); // TODO: Stub
    const [isLoading, setIsLoading] = useState(false);
    const [cache, setCache] = useState<{ [key: string]: { data: XMSuggestions[]; timestamp: number } }>(() => {
        const storedCache = localStorage.getItem("searchCache");
        return storedCache ? JSON.parse(storedCache) : {};
    });

    const handleChange = (value: string) => {
        setInputValue(value);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedInput(inputValue);
        }, 200);

        return () => clearTimeout(timeoutId);
    }, [inputValue]);



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const fetchAndCacheGames = async () => {
            setIsLoading(true);

            try {
                // Check cache first
                if (isCacheValid(inputValue)) {
                    setGameList(cache[inputValue].data);
                    return;
                }

                // Fetch new data if cache miss
                const result = await getXboxGameSuggestions(inputValue, signal);
                setGameList(result);
                updateCache(inputValue, result);
            } catch (err) {
                console.error("Error fetching game suggestions:", err);
                setGameList([]);
            } finally {
                setIsLoading(false);
            }
        };

        const isCacheValid = (key: string): boolean => {
            return (
                cache[key] &&
                cache[key].timestamp + CACHE_EXPIRATION_MS > Date.now()
            );
        };

        const updateCache = (key: string, data: XMSuggestions[]) => {
            setCache((prevCache) => {
                const newCache = {
                    ...prevCache,
                    [key]: { data, timestamp: Date.now() },
                };

                // Maintain cache size limit
                pruneCache(newCache);

                // Persist to localStorage
                localStorage.setItem("searchCache", JSON.stringify(newCache));
                return newCache;
            });
        };

        const pruneCache = (newCache: typeof cache) => {
            const cacheKeys = Object.keys(newCache);
            if (cacheKeys.length > 100) {
                const oldestKey = cacheKeys.reduce((a, b) =>
                    newCache[a].timestamp < newCache[b].timestamp ? a : b
                );
                delete newCache[oldestKey];
            }
        };

        setDebouncedInput(inputValue);
        fetchAndCacheGames();

        return () => controller.abort();
    }, [debouncedInput, cache]);


    return {
        inputValue,
        handleChange,
        debouncedInput,
        gameList,
        isLoading,
    };
};