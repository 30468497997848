// import { useHistory } from "react-router-dom"
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { AuthLayout } from "./AuthLayout";
import { AuthHeader } from "./components/AuthHeader";
import { useContext } from "react";
import { AuthContext } from "lib/coplay/contexts/AuthProvider";
import { useNavigate } from "react-router-dom";

export const SignoutPage: React.FC = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    logout().finally(() => {
        navigate("/sign-in");
    });

    return (
        <AuthLayout>
            <AuthHeader title='CoPlay XM' subtitle='' />
            <Flex direction="column" alignItems="center" justifyContent="center" width="100%" mb='4'>
                <Text>Signing you out...</Text>
                <Spinner size="xl" />
            </Flex>
        </AuthLayout>
    )
}