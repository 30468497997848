import { Button, Flex, Icon, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Text, useColorModeValue } from "@chakra-ui/react";
import { BasicResponse } from "lib/coplay/types/BackendTypes";
import { MdCheckCircleOutline } from "react-icons/md";


export function AggregationSuccessScreen(props: {
    close: () => void,
    response: BasicResponse
}) {
    let color = useColorModeValue('brand.500', 'white');

    return (
        <>
            <ModalHeader>Successfully Added Xbox User!</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                    <Icon as={MdCheckCircleOutline} width='100px' height='100px' color={color} />,
                    <Text textAlign='center' py={4}>
                        <strong>{props.response.emailAddress} </strong>
                        was successfully added to CoPlay XM!</Text>
                    <Button width='200px' mt={4} variant="brand" onClick={props.close}>Close</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}