import { Flex, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Box } from "@chakra-ui/react";
import { SupportedVendors } from "lib/coplay/types/PaymentTypes";
import React from "react";
import { RemoteBrowserCanvas } from "lib/coplay/components/remote-browser/remote-browser-canvas";
import { BrowserStrategy } from "lib/coplay/components/remote-browser/browser-strategies";


export function AddVendorMemberButton(props: {
    button: React.ReactNode,
    vendor: SupportedVendors,
    strategy: BrowserStrategy,
    [key: string]: any
}) {
    const { onClick, hideIcon, ...rest } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button variant='action' onClick={onOpen} {...rest}>
                <Box onClick={onOpen}>
                    {props.button}
                </Box>
                {/* <Flex alignItems="center" justifyContent="center">
                    {!hideIcon && <Avatar icon={<MdAdd />} size="sm" border="1px solid" borderColor="gray.300" bg="transparent" />}
                    <Text px={2}>Add Account</Text>
                </Flex> */}
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add your {props.vendor} account</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction="column" align="center" justify="center">
                            <RemoteBrowserCanvas strategy={props.strategy} onFinish={onClose} />
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}