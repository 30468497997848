import { Box, Text, Image, Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import enableXboxImg from 'lib/coplay/assets/Enable-Xbox-Management.jpg';
import manualEnable from 'lib/coplay/assets/Manually-Enable-Remote-Management.png';
import xboxAppSettings from 'lib/coplay/assets/Xbox-App-Settings.jpg';
import { XMError } from "lib/coplay/types/BackendTypes";
import { linkConsole } from "lib/backend/api";

const enum LinkConsoleStates {
    Linking = "Linking",
    Success = "Success",
    Error = "Error"
}

export const LinkingXboxConsoleScreen = (props: { emailAddress: string, xboxId: string, onError: (error: XMError) => void }) => {
    const [progress, setProgress] = useState<LinkConsoleStates>(LinkConsoleStates.Linking);

    useEffect(() => {
        linkConsole(props.emailAddress, props.xboxId).then((result: string) => {
            setProgress(LinkConsoleStates.Success);
        }).catch((error: any) => {
            setProgress(LinkConsoleStates.Error);
            console.error(error);
        })
    }, [props.emailAddress, props.xboxId])


    const states = {
        Linking: <LinkingScreen />,
        Success: <SuccessScreen />,
        Error: <ErrorScreen />
    }

    return (
        <>
            {states[progress]}
        </>
    )
}


const LinkingScreen = () => {
    return (
        <>
            <Box>
                <strong>We are linking your Xbox to CoPlay. This may take a sec.</strong>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }}>
                    You should see a pop up on your Xbox asking you to enable remote management. Make sure to click Enable!
                </Text>

                <Flex alignItems='center' justifyContent='center' py='25px'>
                    <Image align={'center'} src={enableXboxImg} w='90%' h='auto' />
                </Flex>

                <Flex direction='column' alignItems='center' justifyContent='center' py='50px'>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Flex>

            </Box>
        </>
    )
}

const SuccessScreen = () => {
    return (
        <>
            <Box>
                <strong>Success!</strong>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }}>
                    Your Xbox is now linked to CoPlay!
                </Text>
            </Box>
        </>
    )
}

const ErrorScreen = () => {

    /*
    Possible reasons for failure
        - restarting xbox,
        - enabling remote management manually
        - Xbox App Settings
        - Ensuring Logged in as active user
        - Took too Long
    */
    return (
        <Box>
            <Text textAlign={"center"} fontWeight='bold' fontSize='lg'>
                Something Went Wrong while Linking your Xbox
            </Text>

            <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontWeight='bold' fontSize='md'>
                There are a couple of reasons why this might have happened. Try following to fix the issue:
            </Text>

            <Text style={{ paddingTop: "20px" }}>
                • Enable Remote Management Manually
            </Text>
            <Image align={'center'} src={manualEnable} w='100%' h='auto' />

            <Text style={{ paddingTop: "20px" }}>
                • Ensure Xbox App Settings allow remote management
            </Text>
            <Image align={'center'} src={xboxAppSettings} w='100%' h='auto' />

            <Text style={{ paddingTop: "20px" }}>
                • Ensure you are logged in AND the active user on the Xbox
            </Text>

            <Text style={{ paddingTop: "20px" }}>
                • Restart your Xbox
            </Text>
        </Box>
    )
}