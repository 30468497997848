import { IconButton, Icon } from "@chakra-ui/react";
import { BiSortDown, BiSortUp } from "react-icons/bi";

export function SortButton(props: { isDescending: boolean, setIsDescending: Function }) {

    const handleClick = () => {
        props.setIsDescending(!props.isDescending);
    };

    return (
        <IconButton
            aria-label='Sort'
            onClick={handleClick}
            icon={
                <Icon
                    as={!props.isDescending ? BiSortDown : BiSortUp}
                    w='24px'
                    h='24px'
                />
            }
        />
    );
};