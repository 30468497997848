import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Select, Spinner, Button, ModalFooter, MenuItem, Text, useToast, ModalHeader } from "@chakra-ui/react";
import { changeXMProfile } from "lib/backend/api";
import { getXboxProfilesData } from "lib/backend/firebase/service";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { PartialXMProfile, XMProfile } from "lib/coplay/types/BackendTypes";
import { useContext, useEffect, useState } from "react";

import { MdAdd } from "react-icons/md";

enum ProfileState {
    Zero,
    Loading,
    Loaded,
}

const useAssignProfile = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const { selectedUsers, clearSelectedUsers } = useContext(XboxUsersContext)
    const [xmProfiles, setXMProfiles] = useState<XMProfile[]>([]);
    const [selectedProfile, setSelectedProfile] = useState('');
    const [profileState, setProfileState] = useState<ProfileState>(ProfileState.Loading);

    useEffect(() => {
        getXboxProfilesData().getXMProfiles().then((profiles) => {
            if (profiles.length === 0) setProfileState(ProfileState.Zero);
            else setProfileState(ProfileState.Loaded);
            setXMProfiles(profiles);
        }).catch(() => {
            // Handle error state, optionally you can add another state for error.
        });
    }, []);


    const handleProfileSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProfile(event.target.value);
    };


    const handleConfirm = () => {
        const selected: string[] = [];
        // get profile from id of selected
        const profile = xmProfiles.find(profile => profile.id === selectedProfile);
        const partial: PartialXMProfile = {
            id: profile?.id,
            name: profile?.name
        }
        selectedUsers.forEach(user => selected.push(user.emailAddress))



        if (selected.length === 0) return;

        const newProm = new Promise((resolve, reject) => {
            clearSelectedUsers()
            onClose()
            changeXMProfile(selected, 'set', partial).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })

        toast.promise(newProm, {
            success: (result) => ({
                title: "Assigning Profile complete",
                description: `Assigned profile to ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 1000,
            }),
            error: (error) => ({
                title: "Assigning Profile failed",
                description: `Failed to assign profile to ${selectedUsers.size()} user(s) - ${error.message}`,
                position: "bottom-right",
                isClosable: true,
            }),
            loading: {
                title: "Assigning Profile...",
                description: `Assigning profile to ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
            },
        });
    }


    return {
        xmProfiles,
        selectedProfile,
        handleProfileSelect,
        profileState,
        handleConfirm,
        isOpen,
        onOpen,
        onClose
    }
}

export function AssignProfileMenuItem() {
    const { xmProfiles, selectedProfile, profileState, handleProfileSelect, handleConfirm, isOpen, onOpen, onClose } = useAssignProfile()

    return (<>
        <MenuItem icon={<MdAdd />} onClick={onOpen}>
            Assign Profile
        </MenuItem>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Assign Profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {profileState === ProfileState.Zero && <Text>No Profiles Available</Text>}
                    {profileState === ProfileState.Loading && <Spinner />}
                    {profileState === ProfileState.Loaded && (
                        <Select value={selectedProfile} onChange={handleProfileSelect}>
                            <option value="">Select a profile</option>
                            {xmProfiles.map((profile: XMProfile) => (
                                <option key={profile.id} value={profile.id}>
                                    {profile.name}
                                </option>
                            ))}
                        </Select>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleConfirm}>Confirm</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>)
}