import { Box, Spinner, VStack, Text } from '@chakra-ui/react';
import { useXboxUserLogs } from '../hooks/useXboxUserLogsHook';
import { XboxAccountLogItem } from './XboxAccountLogItem';

export function XboxAccountLogs(props: { emailAddress: string }) {
    const { logs, loading } = useXboxUserLogs(props.emailAddress);

    return (
        <Box h='100%' >
            <Box justifyContent="space-between" alignItems="center" mb={4}>
                <Text fontSize="xl" fontWeight="bold">User Logs</Text>
                <Text fontWeight='500'>Logs are a way to track the actions taken on the account over time. Note that this is not a complete history of the account. Logs only track the last 20 actions.</Text>
            </Box>
            {
                loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Spinner size="xl" />
                    </Box>
                ) : logs.length === 0 ? (
                    <Text>No logs available.</Text>
                ) : (
                    <VStack align="stretch" spacing={2} maxHeight="100%" overflowY="auto" >
                        {logs.map((log, index) => (
                            <XboxAccountLogItem key={index} logEntry={log} />
                        ))}
                    </VStack>
                )
            }
        </Box>
    );
};