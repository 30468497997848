import { Box, Text } from "@chakra-ui/react";
import { XboxState } from "lib/coplay/types/BackendTypes";

export const DeviceStatus = (props: { errorCode?: string, errorMessage?: string }) => {
    let text = "";
    if (props.errorCode !== XboxState.OK) {
        text = props.errorMessage ?
            `${props.errorCode} : ${props.errorMessage}` :
            `${props.errorCode} : Your console may be offline or remote management may be disabled. Please check your console and try again`;
    } else {
        text = props.errorCode;
    }

    return (
        <Box w="85%">
            <Text fontWeight="bold">Status</Text>
            <Text>{text}</Text>
        </Box>
    );
};