import React from 'react';
import { FormLabel, FormControl, Text, Input } from '@chakra-ui/react';

interface EmailInputProps {
    labelColor: string;
    inputColor: string;
    value: string;
    onChange: (value: string) => void;
}

export const EmailInput: React.FC<EmailInputProps> = ({
    labelColor,
    inputColor,
    value,
    onChange
}) => {
    return (
        <>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={labelColor}
                mb='8px'>
                Email<Text color={inputColor}>*</Text>
            </FormLabel>
            <Input
                id='emailField'
                isRequired={true}
                variant='auth'
                fontSize='sm'
                type='email'
                placeholder='example@coplay.io'
                mb='24px'
                fontWeight='500'
                size='lg'
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            />
        </>
    );
};