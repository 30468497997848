import { Heading, Text, Divider, Box } from "@chakra-ui/react"
import { OauthActions } from "lib/coplay/types/BackendTypes";
import Card from "lib/horizon/components/card/Card"
import { BackendConfig } from "config/generated";


export default function APIDocumentation() {
    const BackendUrl: string = BackendConfig.thirdPartyUrl;

    return (
        <Card p={5}>
            <Heading size="lg" mb={4}>API Documentation</Heading>
            <Text fontSize="md" mb={4}>
                This API allows you to apply the profile and all of its settings to the user. <strong>It assumes that the user
                    has a profile assigned to them.</strong> If the user does not have a profile assigned to them, the API will return
                a 404 error.
            </Text>
            <Divider mb={4} />
            <Box mb={4}>
                <Heading size="sm" mb={2}>URL</Heading>
                <Text fontSize="sm" bg="gray.100" p={2} borderRadius="md">
                    <code>{BackendUrl}</code>
                </Text>
            </Box>
            <Box mb={4}>
                <Heading size="sm" mb={2}>Method</Heading>
                <Text fontSize="sm" bg="gray.100" p={2} borderRadius="md">
                    POST
                </Text>
            </Box>
            <Box mb={4}>
                <Heading size="sm" mb={2}>Payload</Heading>
                <Box fontSize="sm" bg="gray.100" p={2} borderRadius="md">
                    <pre>
                        {`{
    "key": "xm-...gJ0U",
    "action": "${OauthActions.ResetToProfile}",
    "emailAddress": "test@example.com"
}`}
                    </pre>
                </Box>
            </Box>
            <Box mb={4}>
                <Heading size="sm" mb={2}>Responses</Heading>
                <Box fontSize="sm" bg="gray.100" p={2} borderRadius="md">
                    <ul>
                        <li>200 - Successful</li>
                        <li>500 - Error</li>
                    </ul>
                </Box>
            </Box>
            <Box mb={4}>
                <Heading size="sm" mb={2}>Example Request</Heading>
                <Box fontSize="sm" bg="gray.100" p={2} borderRadius="md">
                    <pre>
                        {`curl -X POST ${BackendUrl} \\
-H "Content-Type: application/json" \\
-d '{
    "action": "${OauthActions.ResetToProfile}",
    "key": "xm-...gJ0U",
    "emailAddress": "test@example.com"
}'`}
                    </pre>
                </Box>
            </Box>
            <Box mb={4}>
                <Heading size="sm" mb={2}>Example Response</Heading>
                <Box fontSize="sm" bg="gray.100" p={2} borderRadius="md" overflow="scroll">
                    <pre>
                        {`{
    "emailAddress": "test@example.com",
    "oauthAction": "${OauthActions.ResetToProfile}",
    "status": 200,
    "requestId": 12345,
    "error": null // This field will contain additional information if the request does not succeed
}`}
                    </pre>
                </Box>
            </Box>
        </Card>
    )
}