import { Flex, Spacer, Box, Icon, useColorModeValue } from "@chakra-ui/react";
import { useUserLimit } from "lib/coplay/contexts/ConfigurationContext";
import MiniStatistics from "lib/horizon/components/card/MiniStatistics";
import IconBox from "lib/horizon/components/icons/IconBox";
import { ReactNode } from "react";
import { MdCheckBox, MdPeople, MdVisibility } from "react-icons/md";

const statsWidth = '150px';

// TODO: Seperate these out or something?
export function XboxDashboardControls(props: {
    children?: ReactNode[]
}) {
    return (
        <Flex gap={2} px={3} pb={3} wrap='wrap' id='xbox-dashboard-controls'>
            {props.children?.[0]}
            <Spacer />
            {props.children?.[1]}
        </Flex >
    )
}

export function XboxDashboardStats(props: {
    totalUsers: number,
    selectedUsers: number,
    displayedUsers: number
}) {
    const userLimit = useUserLimit();
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    let numUsers = String(props.totalUsers);

    if (userLimit) {
        numUsers += `/${userLimit}`
    }

    return (
        <Flex gap={2}>
            <Box w={statsWidth}>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='40px'
                            h='40px'
                            bg={boxBg}
                            icon={<Icon w='22px' h='22px' as={MdPeople} color={brandColor} />}
                        />
                    }
                    name='Total Users'
                    value={numUsers}
                />
            </Box>
            <Box w={statsWidth}>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='40px'
                            h='40px'
                            bg={boxBg}
                            icon={<Icon w='22px' h='22px' as={MdVisibility} color={brandColor} />}
                        />
                    }
                    name='Displayed Users'
                    value={props.displayedUsers}
                />
            </Box>
            <Box w={statsWidth}>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='40px'
                            h='40px'
                            bg={boxBg}
                            icon={<Icon w='22px' h='22px' as={MdCheckBox} color={brandColor} />}
                        />
                    }
                    name='Selected Users'
                    value={props.selectedUsers}
                />
            </Box>
        </Flex>
    )
}