import { User } from "firebase/auth";
import { collection, doc, Firestore, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { adaptMSOrderHistoryItem, MSOrderHistoryItem, SimpleOrder, SupportedVendors, VendorMember } from "lib/coplay/types/PaymentTypes";

export class VendorData {
    _user: User;
    _db: Firestore;

    constructor(user: User, db: Firestore) {
        this._user = user;
        this._db = db;
    }

    subscribeToVendorMembers = (vendor: SupportedVendors, callback: (fetchedMembers: VendorMember[]) => void) => {
        const vendorMembersRef = collection(this._db, `${this._user.email}/data/vendor`);
        const q = query(vendorMembersRef, where("vendor", "==", vendor));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const members: VendorMember[] = [];
            snapshot.forEach((doc) => {
                const member = doc.data() as VendorMember;
                members.push(member);
            });
            callback(members);
        });
        return unsubscribe;
    };

    subscribeToAllVendorMembers = (callback: (fetchedMembers: VendorMember[]) => void) => {
        const vendorMembersRef = collection(this._db, `${this._user.email}/data/vendor`);
        const unsubscribe = onSnapshot(vendorMembersRef, (snapshot) => {
            const members: VendorMember[] = [];
            snapshot.forEach((doc) => {
                const member = doc.data() as VendorMember;
                members.push(member);
            });
            callback(members);
        });
        return unsubscribe;
    };

    getVendorOrders = async (email: string): Promise<SimpleOrder[]> => {
        let path = `${this._user.email}/data/orders/${email}`;
        const docRef = doc(this._db, path);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            const data = docSnapshot.data() as { orders: MSOrderHistoryItem[] };
            const orders = data.orders.map(adaptMSOrderHistoryItem);
            return orders;
        } else {
            return [];
        }
    };
}