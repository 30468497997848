import React, { useState } from 'react';
import { Box, Button, IconButton, Text, useColorModeValue } from '@chakra-ui/react';
import { AuthHeader } from './components/AuthHeader';
import { EmailInput } from './components/EmailInput';
import { AuthLayout } from './AuthLayout';
import { MdArrowBack } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { resetPassword } from 'lib/backend/firebase/service';



export const ForgotPasswordPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('');

    const brandColor = useColorModeValue("brand.500", "brand.400");
    const textColor = useColorModeValue("gray.700", "white");

    const handleSubmit = async () => {
        setLoading(true);
        await resetPassword(email).then(() => {
            setStatus('Password reset email sent!');
        }).catch((error) => {
            console.error(error);
            setStatus('Hmmm.. Something went wrong. Please check your email address and try again.');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <AuthLayout>
            <NavLink style={{ height: 0, left: 0, width: '100%' }} to={"/sign-in"}>
                <IconButton aria-label={"Go back"}>
                    <MdArrowBack />
                </IconButton>
            </NavLink>
            <AuthHeader title='Welcome To CoPlay XM' subtitle='Enter your email and we will send you a link to reset your password!' />
            <Box width={{ base: "90%", md: "80%" }} mb='4'>
                <EmailInput labelColor={textColor} inputColor={brandColor} value={email} onChange={(value: string) => setEmail(value)} />
                {status && <Text fontSize='sm' pb='2' textAlign='center'>{status}</Text>}
            </Box>
            <Button variant='brand' onClick={handleSubmit} isLoading={loading}>Send Reset Link</Button>
        </AuthLayout>
    );
};