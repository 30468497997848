import { onSnapshot } from "firebase/firestore";

import { Firestore, getDocs, documentId, where, query, doc, updateDoc } from "firebase/firestore";

import { User } from "firebase/auth";
import { collection } from "firebase/firestore";
import { ScheduleEvent } from "lib/coplay/types/BackendTypes";

export class XboxSchedulingData {
    _user: User;
    _db: Firestore;

    constructor(user: User, db: Firestore) {
        this._user = user;
        this._db = db;
    }

    getSchedulesPath = () => {
        return `${this._user.email}/data/schedules/`;
    }

    getSchedulesCollectionRef = () => {
        return collection(this._db, this.getSchedulesPath());
    }

    getSchedulesById = async (ids: string[]): Promise<ScheduleEvent[]> => {
        try {
            const q = query(this.getSchedulesCollectionRef(), where(documentId(), "in", ids));
            const querySnapshot = await getDocs(q);
            let schedules: ScheduleEvent[] = [];
            if (querySnapshot.empty)
                throw new Error("Could not find schedule");

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                schedules.push(data as ScheduleEvent);
            });

            return schedules;
        } catch (e) {
            console.error(`[XboxData.getSchedulesById] Error: ${e}`);
            return null;
        }
    }

    updateScheduleName = async (scheduleId: string, newName: string): Promise<void> => {
        try {
            const path = this.getSchedulesPath() + scheduleId;
            const scheduleRef = doc(this._db, path);

            await updateDoc(scheduleRef, {
                name: newName
            });
        } catch (e) {
            console.error(`[updateScheduleName] Error: ${e}`);
            throw e;
        }
    }

    subscribeToAllSchedules = (callback: (events: ScheduleEvent[]) => void) => {
        try {
            const q = query(this.getSchedulesCollectionRef());
            const unsubscribe = onSnapshot(q, (snapshot) => {
                if (snapshot.empty)
                    callback([]);
                else {
                    const events: ScheduleEvent[] = [];
                    snapshot.forEach((doc) => {
                        events.push((doc.data() as ScheduleEvent));
                    });
                    callback(events);
                }
            });
            return unsubscribe;
        } catch (e) {
            console.error(`[subscribeToConversations] Error: ${e}`);
        }
    }
}
