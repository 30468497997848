import 'lib/horizon/assets/css/App.css';
import ReactDOM from 'react-dom/client';

import { AuthProvider } from 'lib/coplay/contexts/AuthProvider';
import CoplayRouter from 'lib/coplay/CoplayRouter';
import { ConfigurationProvider } from 'lib/coplay/contexts/ConfigurationContext';
import { XMNotificationProvider } from 'lib/coplay/contexts/NotificationContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <ConfigurationProvider>
      <XMNotificationProvider>
        <CoplayRouter />
      </XMNotificationProvider>
    </ConfigurationProvider>
  </AuthProvider>
);
