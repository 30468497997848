import { ContactDetails } from "lib/coplay/types/FrontendTypes"
import Card from "lib/horizon/components/card/Card"
import Information from "lib/horizon/views/admin/profile/components/Information"
import ContactDetailsForm from "lib/coplay/views/coplay-account/components/ContactDetailsForm"
import ResetPasswordLink from "lib/coplay/views/coplay-account/components/ResetPasswordLink"
import { useContactDetails } from "lib/coplay/contexts/ConfigurationContext"
import { Avatar } from "@chakra-ui/react"

export default function CoPlayAccountView() {
    const contactDetails: ContactDetails = useContactDetails()

    return (
        <Card>
            <div style={{ display: "flex", alignItems: "center", marginLeft: "15px", marginBottom: "15px" }}>
                <Avatar name={contactDetails.companyName} size="lg" borderRadius="10px" marginRight="10px" />
                <h3 style={{ fontSize: "24px", fontWeight: "bold" }}>
                    Company Details
                </h3>
            </div>
            <Information py={2} title={"Name"} value={contactDetails.companyName} />
            <Information py={2} title={"Location"} value={contactDetails.companyLoc} />
            <Information py={2} title={"Joined Date"} value={contactDetails.joinedDate} />
            <hr style={{ margin: "20px" }} />
            <ContactDetailsForm />
            <ResetPasswordLink />
        </Card>
    )
}