import { ChevronDownIcon } from "@chakra-ui/icons";
import { Flex, Menu, MenuButton, Button, MenuList, MenuOptionGroup } from "@chakra-ui/react";
import { XboxUsersSet } from "lib/coplay/utils/XboxUsersSet";
import { RefreshMenuItem } from "./RefreshMenuItem";
import { SyncFriendsMenuItem } from "./SyncFriendsMenuItem";
import { RemoveFriendsMenuItem } from "./RemoveFriendsMenuItem";
import { ClearConversationsMenuItem } from "./ClearConversationsMenuItem";
import { ScheduleActionButton } from "lib/coplay/components/scheduling/ScheduleActionButton";
import { BatchInstallMenuItem } from "./BatchInstallMenuItem";
import { AddNewXboxUserButton } from "../add-new-xbox-user/AddNewXboxUserButton";
import { AssignProfileMenuItem } from "./AssignProfileMenuItem";
import { SyncProfileMenuItem } from "./SyncProfileMenuItem";
import { RemoveProfileMenuItem } from "./RemoveProfileMenuItem";

export function XboxActionsDropdown(props: {
    selectedUsers: XboxUsersSet
}) {

    return (
        <Flex gap={2}>
            <Menu>
                <MenuButton isDisabled={props.selectedUsers.size() === 0} variant='brand' as={Button} rightIcon={<ChevronDownIcon />}>
                    Xbox Actions
                </MenuButton>
                <MenuList>
                    <MenuOptionGroup title="User Actions">
                        <RefreshMenuItem />
                        <SyncFriendsMenuItem />
                        <RemoveFriendsMenuItem />
                        <ClearConversationsMenuItem />
                    </MenuOptionGroup>
                    <MenuOptionGroup title="Console Actions">
                        <BatchInstallMenuItem />
                    </MenuOptionGroup>
                    <MenuOptionGroup title="Profile Actions">
                        <SyncProfileMenuItem />
                        <AssignProfileMenuItem />
                        <RemoveProfileMenuItem />
                    </MenuOptionGroup>
                </MenuList>
            </Menu>
            <ScheduleActionButton />
            <AddNewXboxUserButton />
        </Flex>
    );
}