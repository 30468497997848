import { Radio, RadioGroup, Stack, Text, useColorModeValue, Wrap, WrapItem } from "@chakra-ui/react"
import { XboxAccountSettingsController } from "../profile-utils"
import { UserPrivacySettings } from "lib/coplay/types/BackendTypes";
import { enrichSettings, SettingsHumanReadableToValue, PrioritySettings } from "lib/coplay/utils/profile-settings-utils";
import { useEffect, useState } from "react";


function usePrivacySettings(controller: XboxAccountSettingsController, editing: boolean, enablePrivacy: boolean) {
    const [standardSettingsElem, setStandardSettingsElem] = useState<JSX.Element[]>([]);
    const [prioritySettingsElems, setPrioritySettingsElems] = useState<JSX.Element[]>([]);
    const textColorPrimary = useColorModeValue('brand.500', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.900', 'white');

    useEffect(() => {
        if (controller.getEnablePrivacy) {
            const enrichedSettings = enrichSettings(controller.privacySettings);
            const newStandardSettingsElem: JSX.Element[] = [];
            const newPrioritySettingsElems: JSX.Element[] = [];

            enrichedSettings.forEach((setting, index) => {
                const settingName = setting.setting;
                const currentValue = setting.value;
                const handleSettingChange = (newSettingValue: string) => {
                    const newSettings: UserPrivacySettings = {
                        settings: [...controller.privacySettings.settings]
                    };

                    for (const s in newSettings.settings) {
                        if (newSettings.settings[s].setting === settingName) {
                            newSettings.settings[s]
                                = { setting: settingName, value: newSettingValue }
                        }
                    }

                    controller.setPrivacySettings(newSettings);
                }

                const settingElem = (
                    <WrapItem w='420px' h='160px' p='10px' key={index} borderWidth="1px" borderColor="gray.200" borderRadius="md">
                        <div>
                            <Text w='300px' color={textColorPrimary} fontWeight={"bold"}>
                                {setting.description}
                            </Text>
                            <Text w='300px' color={textColorSecondary}>
                                {setting.details}
                            </Text>
                        </div>

                        <RadioGroup
                            isDisabled={!editing}
                            value={currentValue}
                            onChange={e => handleSettingChange(e as string)}
                        >
                            <Stack direction="column">
                                {setting.options.map((option, i) => (
                                    <Radio key={i} value={SettingsHumanReadableToValue[option]}>
                                        {option}
                                    </Radio>
                                ))}
                            </Stack>
                        </RadioGroup>
                    </WrapItem>
                );

                if (PrioritySettings.includes(settingName)) {
                    newPrioritySettingsElems.push(settingElem)
                } else {
                    newStandardSettingsElem.push(settingElem)
                }
            });

            setStandardSettingsElem(newStandardSettingsElem);
            setPrioritySettingsElems(newPrioritySettingsElems);
        } else {
            setStandardSettingsElem([]);
            setPrioritySettingsElems([]);
        }
    }, [editing, enablePrivacy, controller.privacySettings]);

    return { standardSettingsElem, prioritySettingsElems };
}


export function PrivacySettings(
    props: { editing: boolean, controller: XboxAccountSettingsController }
): JSX.Element {

    const { standardSettingsElem, prioritySettingsElems } = usePrivacySettings(props.controller, props.editing, props.controller.getEnablePrivacy);

    const textColorPrimary = useColorModeValue('brand.500', 'white');

    let renderStandardSettings = () => {
        if (standardSettingsElem.length > 0) {
            return (
                <details color={textColorPrimary} >
                    <summary style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }} >Advanced Privacy Settings</summary>
                    <Wrap w='100%' p='10px'>
                        {standardSettingsElem}
                    </Wrap>
                </details>
            )
        }
    }

    return (
        <>
            <Wrap w='100%' p='10px'>
                {prioritySettingsElems}
            </Wrap>
            {renderStandardSettings()}
        </>
    )
}