import { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useIsLoggedIn } from './contexts/AuthProvider';

import initialTheme from 'lib/horizon/theme/theme';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import App from 'App';
import { LoginPage } from './views/auth/LoginPage';
import { ForgotPasswordPage } from './views/auth/ForgotPasswordPage';
import { ChangePasswordPage } from './views/auth/ChangePasswordPage';
import { SignoutPage } from './views/auth/SignoutPage';


const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const isLoggedIn = useIsLoggedIn();

    if (!isLoggedIn) {
        return <Navigate to="/sign-in" replace />;
    }

    return children;
};

function CoplayRouter() {
    const [currentTheme,] = useState(initialTheme);

    return (
        <ChakraProvider theme={currentTheme}>
            <BrowserRouter>
                <Routes>
                    {/* Public routes */}
                    <Route path='/sign-in' element={<LoginPage />} />
                    <Route path='/sign-in/forgot-password' element={<ForgotPasswordPage />} />
                    <Route path='/sign-in/change-password' element={<ChangePasswordPage />} />
                    <Route path='/sign-out' element={<SignoutPage />} />
                    <Route path='auth/sign-out' element={<SignoutPage />} />

                    {/* Protected routes */}
                    <Route
                        path='/app/*'
                        element={
                            <ProtectedRoute>
                                <App />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/" element={<Navigate to="/app/dashboard" replace />} />
                </Routes>
            </BrowserRouter>
        </ChakraProvider>
    );
}

export default CoplayRouter;