import { Spinner, Checkbox, ListItem, Flex, Avatar, Box, Spacer, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { InstalledSoftware } from "lib/coplay/types/BackendTypes";
import { memo } from "react";

const SoftwareItem = (props: {
    emailAddress: string,
    consoleId: string,
    software: InstalledSoftware,
    isSelected: boolean,
    onSelect: (isSelected: boolean) => void,
    isUninstalling: boolean,
}) => {
    const bgHover = useColorModeValue('secondaryGray.300', 'whiteAlpha.50');
    const secondaryColor = useColorModeValue('gray.400', 'white');

    const installTime =
        props.software.installTime != null ? new Date(props.software.installTime).toLocaleString() : 'Pending';

    let actionElem = <></>;
    if (installTime !== 'Pending') {
        if (props.isSelected && props.isUninstalling) {
            actionElem = <Spinner />
        } else {
            actionElem =
                <Checkbox
                    px="5px"
                    isChecked={props.isSelected}
                    onChange={(e) => props.onSelect(e.target.checked)}
                    isDisabled={props.isUninstalling}
                />
        }
    }

    return (
        <ListItem key={props.software.instanceId}>
            <Flex _hover={{ background: bgHover }} alignItems="center" >
                <Avatar name={props.software.name} size="md" mr={4} />
                <Box>
                    <Text fontWeight='bold' fontSize='md' flexGrow={1}> {props.software.name} </Text>
                    <Text color={secondaryColor} fontWeight='bold' fontSize='xs'>Installed: {installTime} </Text>
                </Box>
                <Spacer />
                {actionElem}
            </Flex>
        </ListItem>
    )
}

export const MemoSoftwareItem = memo(SoftwareItem)