import { Box, useColorModeValue, Button, Flex, Text, useTheme } from "@chakra-ui/react";

import { motion } from "framer-motion";
import { BrowserStates } from "lib/coplay/types/PaymentTypes";
import { useState, useCallback, useEffect } from "react";
import useRemoteBrowserWorker from "./useRemoteBrowserHook";
import { BrowserStrategy } from "./browser-strategies";
import { CoPlayLoadingBar } from "../CoPlayLoading";


export function RemoteBrowserCanvas({ outlineColor = 'lightgrey', strategy, onFinish }: {
    outlineColor?: string;
    strategy: BrowserStrategy;
    onFinish: () => void;
}) {
    const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement | null>(null);
    const { browserState, error, handleStartClick } = useRemoteBrowserWorker(canvasElement, strategy);

    const canvasRef = useCallback((node: HTMLCanvasElement | null) => {
        if (node !== null) {
            setCanvasElement(node);
        }
    }, []);

    useEffect(() => {
        if (browserState === BrowserStates.Finished) {
            const timer = setTimeout(() => {
                onFinish();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [browserState]);

    return (
        <>
            <Box
                bg={useColorModeValue('gray.200', 'gray.200')}
                style={{ background: 'grey.300', position: 'relative' }}
            >
                <canvas
                    ref={canvasRef}
                    tabIndex={10}
                    height={'667px'}
                    width={'375px'}
                    style={{ borderRadius: '5px', outline: `2px solid ${outlineColor}` }}
                />
                <BrowserOverLay handleStartClick={handleStartClick} state={browserState} error={error} />
            </Box>
        </>
    );
};

function BrowserOverLay(props: { handleStartClick: () => void; state: BrowserStates; error: string }) {
    let mapObj: { [key in BrowserStates]: JSX.Element } = {
        [BrowserStates.Ready]: (
            <Button
                height={'100%'}
                width={'100%'}
                borderRadius={'10px'}
                colorScheme={'blue'}
                variant={'action'}
                style={{ transform: 'translate(-50%, -50%)', position: 'absolute', top: '50%', left: '50%' }}
                onClick={props.handleStartClick}
            >
                Click To Start
            </Button>

        ),
        [BrowserStates.Error]: <p style={{ color: 'red' }}>{props.error}</p>,
        [BrowserStates.Closed]: <p style={{ color: 'red' }}>Browser Closed</p>,
        [BrowserStates.Finished]: (
            <AnimatingCheckMark />
        ),
        [BrowserStates.InProgress]: (<>LOADING...</>),
        [BrowserStates.Loading]: (
            <Flex direction='column' alignItems='center' justifyContent='center' height="100%">
                <CoPlayLoadingBar label="Loading..." time={30} />
            </Flex>
        )
    };

    let elem = mapObj[props.state];

    let css = props.state === BrowserStates.InProgress ? 'none' : 'block';
    return (
        <Box
            bg={useColorModeValue('gray.100', 'gray.900')}
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '100%',
                height: '100%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                borderRadius: '10px',
                display: css,
            }}
        >
            {elem}
        </Box>
    );
}

function AnimatingCheckMark() {

    const theme = useTheme();
    const color = theme.colors.green[500]
    // get hex color

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, type: "spring", stiffness: 260, damping: 20 }}
            >
                <svg width="60" height="60" viewBox="0 0 60 60">
                    <motion.circle
                        cx="30"
                        cy="30"
                        r="28"
                        stroke={color}
                        strokeWidth="4"
                        fill="none"
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: 1 }}
                        transition={{ duration: 0.8 }}
                    />
                    <motion.path
                        d="M15 30 L25 40 L45 20"
                        fill="none"
                        stroke={color}
                        strokeWidth="4"
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: 1 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                    />
                </svg>
            </motion.div>
            <Text mt={2} fontSize="xl" fontWeight="bold" color="green.500">
                You did it!
            </Text>
        </Box>
    )
}

export default RemoteBrowserCanvas;
