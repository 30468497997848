import { useBreakpointValue, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { ConsoleCrumb } from 'lib/coplay/types/BackendTypes';
import { CoPlayLoadingBar } from '../../CoPlayLoading';
import { useConsoleDetails } from './hooks/useDeviceDetailsHook';
import { NoDeviceScreen } from './components/NoDeviceScreen';
import { DeviceDetailsHeader } from './components/DeviceDetailsHeader';
import { DeviceDetailsList } from './components/DeviceDetailsList';

export const DeviceDetailsModal = (props: {
    emailAddress: string,
    onClose: () => void,
    crumb: ConsoleCrumb
}) => {
    const { progress, consoleDetails, unlinkConsole } = useConsoleDetails(props.emailAddress, props.onClose);

    const isLargeScreen = useBreakpointValue({ base: false, md: true });

    const states = {
        LOADING: <CoPlayLoadingBar />,
        ERROR: <>Error</>,
        NO_CONSOLE: <NoDeviceScreen emailAddress={props.emailAddress} onClose={props.onClose} />,
        SUCCESS:
            (<Flex direction={isLargeScreen ? 'row' : 'column'}>
                <DeviceDetailsHeader crumb={props.crumb} onUnlink={unlinkConsole} emailAddress={props.emailAddress} device={consoleDetails} />
                <DeviceDetailsList emailAddress={props.emailAddress} console={consoleDetails} />
            </Flex>),
    }

    return (
        <Modal isOpen={true} onClose={props.onClose} size={isLargeScreen ? '6xl' : 'md'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Console Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {states[progress]}
                </ModalBody>

                <ModalFooter>
                    <Button onClick={props.onClose} variant='ghost'>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}