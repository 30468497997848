import React, { useState, useContext, FormEvent } from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
import { Box, Button, Flex, FormControl, useColorModeValue, Text, Link } from '@chakra-ui/react';
import { AuthHeader } from './components/AuthHeader';
import { AuthLayout } from './AuthLayout';
import { Navigate } from 'react-router-dom';
import { EmailInput } from './components/EmailInput';
import { PasswordInput } from './components/PasswordInput';
import { RememberMeAndForgotPassword } from './components/RememberMeAndForgotPassword';

export const useLoginForm = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await login(email, password, rememberMe);
    } catch (err: any) {
      setError("Incorrect username or password");
    } finally {
      setLoading(false);
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    error,
    loading,
    rememberMe,
    setRememberMe,
    showPassword,
    setShowPassword,
    handleSubmit,
  };
};


export const LoginPage: React.FC = () => {
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("gray.700", "white");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const { currentUser } = useContext(AuthContext);

  const {
    email,
    setEmail,
    password,
    setPassword,
    error,
    loading,
    rememberMe,
    setRememberMe,
    showPassword,
    setShowPassword,
    handleSubmit,
  } = useLoginForm();


  if (currentUser) {
    return <Navigate to="/app/dashboard" replace />;
  }

  return (
    <AuthLayout>
      <AuthHeader title='Welcome To CoPlay XM' subtitle='Enter your email and password to sign in!' />
      <Box width={{ base: "90%", md: "80%" }} mb='4'>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <EmailInput
              labelColor={textColor}
              inputColor={brandColor}
              value={email}
              onChange={(value: string) => setEmail(value)}
            />
            <PasswordInput
              labelColor={textColor}
              inputColor={brandColor}
              value={password}
              onChange={(value: string) => setPassword(value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            {error && <Text pb={4} color="red" textAlign='center'>{error}</Text>}
            <RememberMeAndForgotPassword
              rememberMe={rememberMe}
              onRememberMeChange={(checked: boolean) => setRememberMe(checked)}
            />
            <Button
              type="submit"
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              isLoading={loading}
            >
              Login
            </Button>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'>
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                Not signed up yet?
                <Link fontWeight='500' color={brandColor} paddingLeft='5px' href='mailto:braxton@coplay.io'>
                  Let's Chat!
                </Link>
              </Text>
            </Flex>
          </FormControl>
        </form>
      </Box>
    </AuthLayout>
  );
};
