import { collection, doc, Firestore, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { User } from "firebase/auth";
import { BasicReport, BasicResponse, MonthlyReport } from "lib/coplay/types/BackendTypes"


export class XboxLogsUsageData {
    _user: User;
    _db: Firestore;

    constructor(user: User, db: Firestore) {
        this._user = user;
        this._db = db;
    }

    getUsagePath = (year: string, month: string) => {
        return `${this._user.email}/data/usage/${year}/monthly/${month}/`;
    }

    getXMLogsPath = () => {
        return `${this._user.email}/data/xmlogs`;
    }

    getXMLogsCollectionRef = () => {
        return collection(this._db, this.getXMLogsPath());
    }

    getUsageCollectionRef = (year: string, month: string) => {
        return collection(this._db, this.getUsagePath(year, month));
    }

    getMonthlyUsageReports = async (year: string, month: string) => {
        try {
            const monthDoc = doc(this._db, this.getUsagePath(year, month));
            const monthSnapshot = await getDoc(monthDoc);
            if (!monthSnapshot.exists()) {
                return null
            }
            const monthData = monthSnapshot.data() as MonthlyReport;
            return monthData;
        } catch (e) {
            console.error(`[getMonthlyUsageReports] Error: ${e}`);
            throw (e);
        }
    }

    getXMLogs = async () => {
        try {
            const q = query(this.getXMLogsCollectionRef(), orderBy("timestamp", "desc"));
            const querySnapshot = await getDocs(q);
            const logs: (BasicReport | BasicResponse)[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data() as BasicReport | BasicResponse;
                logs.push(data);
            });
            return logs;
        } catch (e) {
            console.error(`[getXMLogs] Error: ${e}`);
            throw (e);
        }
    }
}
