// AuthContext.tsx

import React, { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import {
    User,
    signInWithEmailAndPassword,
    signOut, onAuthStateChanged,
    setPersistence,
    browserLocalPersistence,
    browserSessionPersistence
} from 'firebase/auth';
import { auth, setServiceUser } from 'lib/backend/firebase/service';

interface AuthContextProps {
    currentUser: User | null;
    login: (email: string, password: string, rememberMe: boolean) => Promise<void>;
    logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
    currentUser: null,
    login: async () => { },
    logout: async () => { },
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const login = async (email: string, password: string, rememberMe: boolean) => {
        try {
            await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);
            const credentials = await signInWithEmailAndPassword(auth, email, password);
            setServiceUser(credentials.user);
        } catch (error) {
            throw error;
        }
    };

    const logout = async () => {
        await signOut(auth);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setServiceUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useIsLoggedIn = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useIsLoggedIn must be used within a AuthProvider');
    }
    return context.currentUser !== null;
}