import { Input } from "@chakra-ui/react"

export default function EndSelector(
    props: { getRepeat: boolean, endValue: string, endMin: string, setEnd: Function }
) {
    let endSelector: JSX.Element
    if (props.getRepeat) {
        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            props.setEnd(new Date(event.target.value))
        }

        endSelector = (
            <Input
                style={{ maxWidth: "173px" }}
                type={"date"}
                value={props.endValue}
                min={props.endMin.slice(0, 10)}
                onChange={onChange}
                ml={2}
            />
        )
    } else {
        endSelector = null
    }

    return endSelector
}