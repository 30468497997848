import { IconButton } from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';
import ProfileDetailsPage from './components/ProfileDetailsPage';

export function CreateProfile(props: { onCreated: () => void, onBack: () => void }) {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <IconButton
                style={{ width: "40px" }}
                aria-label="Go back"
                icon={<MdArrowBack />}
                onClick={() => props.onBack()}
                mb={2} />
            <ProfileDetailsPage editing={true} onCreated={props.onCreated} />
        </div>
    );
};

export default CreateProfile;