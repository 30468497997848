import { Box, Text, Button, Tabs, TabList, TabPanels, TabPanel, Tab, Input } from "@chakra-ui/react";
import { handleXboxFriending } from "lib/backend/api";
import { XboxPeople, OauthActions, XboxFriends, XboxPerson } from "lib/coplay/types/BackendTypes";
import { useState } from "react";

// TODO: Add Delete friend button, clean up the friend renderer
export function XboxAccountFriends(props: { emailAddress: string, friends?: XboxFriends }) {
    const [searchQuery, setSearchQuery] = useState("");

    const filterItems = (items: XboxPerson[] = []) => {
        return items.filter(item =>
            item.gamertag.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    return (
        <Box mb='4'>
            <Text as='h3' fontSize='lg' fontWeight='bold'>Friends</Text>
            <Text fontWeight='500' mb='4'>Manage your Xbox friends, followers, and requests.</Text>

            <Tabs variant='line' colorScheme='blue'>
                <TabList mb='4'>
                    <Tab>Friends {props.friends?.people?.length ? `(${props.friends.people.length})` : ''}</Tab>
                    <Tab>Friend Requests {props.friends?.friendInvites?.length ? `(${props.friends.friendInvites.length})` : ''}</Tab>
                    <Tab>Friend Invites {props.friends?.friendRequests?.length ? `(${props.friends.friendRequests.length})` : ''}</Tab>
                    <Tab>Followers {props.friends?.followers?.people.length ? `(${props.friends.followers.people.length})` : ''}</Tab>
                    <Tab>Following {props.friends?.following?.people.length ? `(${props.friends.following.people.length})` : ''}</Tab>


                </TabList>

                <TabPanels>
                    <TabPanel p='0'>
                        <Input
                            placeholder="Search friends..."
                            mb='4'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Box p='4' overflowY='scroll' maxHeight='400px' bg='gray.100'>
                            {!props.friends?.people?.length ? (
                                <ZeroState />
                            ) : (
                                filterItems(props.friends.people)
                                    .sort((a, b) => a.gamertag.localeCompare(b.gamertag))
                                    .map((friend) => (
                                        <FriendItem
                                            key={friend.xuid}
                                            gamertag={friend.gamertag}
                                            xuid={friend.xuid}
                                            emailAddress={props.emailAddress}
                                        />
                                    ))
                            )}
                        </Box>
                    </TabPanel>

                    <TabPanel p='0'>
                        <Input
                            placeholder="Search invites..."
                            mb='4'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Box p='4' overflowY='scroll' maxHeight='400px' bg='gray.100'>
                            {!props.friends?.friendInvites?.length ? (
                                <ZeroState type="requests" />
                            ) : (
                                filterItems(props.friends.friendInvites)
                                    .map((person) => (
                                        <PersonItem key={person.xuid} gamertag={person.gamertag} />
                                    ))
                            )}
                        </Box>
                    </TabPanel>

                    <TabPanel p='0'>
                        <Input
                            placeholder="Search requests..."
                            mb='4'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Box p='4' overflowY='scroll' maxHeight='400px' bg='gray.100'>
                            {!props.friends?.friendRequests?.length ? (
                                <ZeroState type="invites" />
                            ) : (
                                filterItems(props.friends.friendRequests)
                                    .map((person) => (
                                        <PersonItem key={person.xuid} gamertag={person.gamertag} />
                                    ))
                            )}
                        </Box>
                    </TabPanel>

                    <TabPanel p='0'>
                        <Input
                            placeholder="Search followers..."
                            mb='4'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Box p='4' overflowY='scroll' maxHeight='400px' bg='gray.100'>
                            {!props.friends?.followers?.people?.length ? (
                                <ZeroState type="followers" />
                            ) : (
                                filterItems(props.friends.followers.people)
                                    .map((person) => (
                                        <PersonItem key={person.xuid} gamertag={person.gamertag} />
                                    ))
                            )}
                        </Box>
                    </TabPanel>

                    <TabPanel p='0'>
                        <Input
                            placeholder="Search following..."
                            mb='4'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Box p='4' overflowY='scroll' maxHeight='400px' bg='gray.100'>
                            {!props.friends?.following?.people?.length ? (
                                <ZeroState type="following" />
                            ) : (
                                filterItems(props.friends.following.people)
                                    .map((person) => (
                                        <PersonItem key={person.xuid} gamertag={person.gamertag} />
                                    ))
                            )}
                        </Box>
                    </TabPanel>

                </TabPanels>
            </Tabs>

            <Text fontSize='xs' color="red.500" fontWeight='500'>{props.friends?.statusMessage}</Text>
        </Box>
    )
}

function FriendItem(props: { gamertag: string, xuid: string, emailAddress: string }) {
    const [isLoading, setIsLoading] = useState(false);
    const handleDelete = (xuid: string) => {
        setIsLoading(true);
        handleXboxFriending([props.emailAddress], [xuid], OauthActions.RemoveFriends).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <Box key={props.xuid} display='flex' alignItems='center' justifyContent='space-between' bg='white' borderRadius='md' mb='2' boxShadow='sm'>
            <Text p='1'>{props.gamertag}</Text>
            <Button
                colorScheme='red'
                variant='ghost'
                size='xs'
                p='2'
                isLoading={isLoading}
                onClick={() => handleDelete(props.xuid)}>
                Remove
            </Button>
        </Box>
    )
}

function PersonItem(props: { gamertag: string }) {
    return (
        <Box display='flex' alignItems='center' bg='white' borderRadius='md' mb='2' boxShadow='sm'>
            <Text p='2'>{props.gamertag}</Text>
        </Box>
    )
}

function ZeroState({ type = "friends" }: { type?: string }) {
    const messages = {
        friends: "This account has no friends. Add some friends to see them here!",
        followers: "No followers yet.",
        following: "Not following anyone yet.",
        requests: "You have not received any friend requests.",
        invites: "You have not sent any friend requests."
    };

    return (
        <Text m='8' textAlign='center' color='gray.500' fontSize='md' fontStyle='italic'>
            {messages[type as keyof typeof messages]}
        </Text>
    )
}