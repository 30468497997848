import { CheckIcon, CloseIcon, EditIcon, WarningIcon } from "@chakra-ui/icons";
import { Box, Editable, EditableInput, EditablePreview, Flex, Heading, IconButton, Input, Spinner, Text, useEditableControls } from "@chakra-ui/react";
import { XboxConsole } from "lib/coplay/types/BackendTypes";
import { useEditableDeviceName } from "../hooks/useEditableDeviceName";


export const EditableDeviceName = (props: { emailAddress: string, device: XboxConsole }) => {
    const { deviceName, state, handleChange, onSubmit } = useEditableDeviceName(props.emailAddress, props.device);

    function EditableControls() {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls();

        if (state === "Loading") {
            return (
                <Flex pl='5px' alignItems={'center'}>
                    <Spinner pl="10px" />
                </Flex>
            );
        } else if (state === "Error") {
            return (
                <Flex pl='5px' alignItems={'center'}>
                    <WarningIcon color='tomato' />
                    <Text pl='5px' size='small' color='tomato'> Something went wrong! </Text>
                </Flex>
            );
        } else if (isEditing) {
            return (
                <Flex>
                    <IconButton size='xs' aria-label="Submit" icon={<CheckIcon />} {...getSubmitButtonProps()} />
                    <IconButton size='xs' aria-label="Cancel" icon={<CloseIcon />} {...getCancelButtonProps()} />
                </Flex>
            );
        } else {
            return (
                <IconButton aria-label="Edit" size='sm' icon={<EditIcon />} {...getEditButtonProps()} />
            );
        }
    }

    return (
        <Box textAlign={'center'}>
            <Heading as='h4' size='md'><strong>Name </strong></Heading>
            <Editable
                textAlign='center'
                defaultValue={props.device.name}
                fontSize='lg'
                isPreviewFocusable={false}
                py='0px'
                my='0px'
                value={deviceName}
                onChange={handleChange}
                onSubmit={onSubmit}
            >
                <Flex alignItems={'center'}>
                    <EditablePreview my='0px' py='0px' />
                    <Input as={EditableInput} />
                    <EditableControls />
                </Flex>
            </Editable>
        </Box>
    );
}