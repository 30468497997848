import { Checkbox, Flex, FormLabel, Text, useColorModeValue } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

interface RememberMeAndForgotPasswordProps {
    rememberMe: boolean;
    onRememberMeChange: (checked: boolean) => void;
}

export const RememberMeAndForgotPassword: React.FC<RememberMeAndForgotPasswordProps> = ({
    rememberMe,
    onRememberMeChange,
}) => {
    const brandColor = useColorModeValue("brand.500", "brand.400");
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Flex justifyContent='space-between' align='center' mb='24px'>
            <>
                <Checkbox
                    id='remember-login'
                    colorScheme='brandScheme'
                    me='10px'
                    isChecked={rememberMe}
                    onChange={(e) => onRememberMeChange(e.target.checked)}
                >
                    <FormLabel
                        htmlFor='remember-login'
                        mb='0'
                        fontWeight='normal'
                        color={textColor}
                        fontSize='sm'>
                        Keep me logged in
                    </FormLabel>
                </Checkbox>
            </>
            <NavLink to='/sign-in/forgot-password'>
                <Text
                    color={brandColor}
                    fontSize='sm'
                    w='124px'
                    fontWeight='500'>
                    Forgot password?
                </Text>
            </NavLink>
        </Flex>
    );
};