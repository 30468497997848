import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getXboxProfilesData } from "lib/backend/firebase/service";
import { XMProfile } from "lib/coplay/types/BackendTypes";

export enum ProfileViewState {
    LOADING = "Loading",
    LIST = "List",
    CREATE = "Create",
    DETAILS = "Details"
}

export function useProfilesView() {
    const navigate = useNavigate();
    const location = useLocation();

    const [profiles, setProfiles] = useState<XMProfile[]>();
    const [selectedProfile, setSelectedProfile] = useState<XMProfile | null>(null);

    useEffect(() => {
        const unsubscribe = getXboxProfilesData().subscribeToProfiles((profiles) => {
            setProfiles(profiles);
            navigate("/app/profiles", { state: ProfileViewState.LIST });
        });
        return unsubscribe;
    }, [navigate]);

    const onCreated = () => {
        navigate("/app/profiles", { state: ProfileViewState.LIST });
    };

    const onDetails = (profile: XMProfile) => {
        setSelectedProfile(profile);
        navigate("/app/profiles", { state: ProfileViewState.DETAILS });
    };

    const onDelete = (profile: XMProfile) => {
        getXboxProfilesData().deleteProfile(profile).catch((err) => {
            console.error("Error deleting profile: ", err);
        });
    };

    const onDuplicate = (profile: XMProfile) => {
        getXboxProfilesData().duplicateProfile(profile).catch((err) => {
            console.error("Error duplicating profile: ", err);
        });
    };

    let viewState: ProfileViewState;
    if (profiles) {
        if (location.state) {
            viewState = location.state as ProfileViewState;
        } else {
            viewState = ProfileViewState.LIST;
        }
    } else {
        viewState = ProfileViewState.LOADING;
    }

    return {
        profiles,
        selectedProfile,
        viewState,
        onCreated,
        onDetails,
        onDelete,
        onDuplicate,
    };
}