import { Button, MenuItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import { handleXboxFriending } from "lib/backend/api";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { OauthActions } from "lib/coplay/types/BackendTypes";
import { useContext } from "react";
import { MdGroupOff } from "react-icons/md";

export function RemoveFriendsMenuItem() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <MenuItem icon={<MdGroupOff />} onClick={onOpen}>
                Remove All Friends
            </MenuItem>
            <RemoveFriendsModal
                isOpen={isOpen}
                onClose={onClose}
            />
        </>
    )
}

export function RemoveFriendsModal(props: {
    isOpen: boolean,
    onClose: () => void
}) {
    const { selectedUsers, clearSelectedUsers } = useContext(XboxUsersContext)
    const toast = useToast();

    const selectedEmails = selectedUsers.toArray().map(user => user.emailAddress)

    const handleConfirmPromise = () => {
        clearSelectedUsers()
        return handleXboxFriending(selectedEmails, ['all'], OauthActions.RemoveFriends)
    }

    const handleConfirm = () => {
        toast.promise(handleConfirmPromise(), {
            success: (result) => ({
                title: "Removing Friends complete",
                description: `Removed friends for ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,
            }),
            error: (error) => ({
                title: "Removing Friends failed",
                description: `Failed to remove friends for ${selectedUsers.size()} user(s) - ${error.message}`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,

            }),
            loading: {
                title: "Removing Friends...",
                description: `Removing friends for ${selectedUsers.size()} user(s)`,
                position: "bottom-right",
                isClosable: true,
                duration: 3000,
            },
        });
        props.onClose();
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Remove All Friends</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure you want to remove all friends for {selectedUsers.size()} user(s)?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleConfirm}>
                        Confirm
                    </Button>
                    <Button variant="ghost" onClick={props.onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}