import { Box, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { XboxNotes } from "./XboxNotes";
import { AddLinkDeviceOverviewButton } from "lib/coplay/components/xbox/AddXboxConsoleButton";
import { XboxDeviceOverviewDetailsButton } from "lib/coplay/components/xbox/XboxConsoleDetailsButton";
import { DeviceDetailsModal } from "lib/coplay/components/xbox/device-details-modal/DeviceDetailsModal";
import { createConsoleCrumb } from "lib/coplay/utils/utils";
import { XboxUser } from "lib/coplay/types/BackendTypes";


export function XboxAccountOverview(props: { user?: XboxUser, updateNotes: (notes: string) => void }) {
    const [showDeviceDetails, setShowDeviceDetails] = useState(false); // HACK

    return (
        <Flex direction='column' fontWeight='bold' fontSize='sm' overflow="hidden">

            <Box mb='4'>
                <Text as='h3' fontSize='lg'>Presence</Text>
                <Text fontWeight='500'>{props.user?.presenceState}</Text>
                <Text fontWeight='500'>{props.user?.presenceText}</Text>
            </Box>

            <Box mb='4'>
                <Text fontSize='md' fontWeight='bold'>Console Information</Text>
                {!props.user?.consoleId ?
                    <>
                        <Text fontWeight='500'>Click below to link your xbox console to your account. This will allow CoPlaY XM to install and remove apps on your console</Text>
                        <AddLinkDeviceOverviewButton emailAddress={props.user?.emailAddress} />
                    </>
                    :
                    <>
                        {showDeviceDetails &&
                            <DeviceDetailsModal
                                emailAddress={props.user?.emailAddress}
                                onClose={() => { setShowDeviceDetails(false) }}
                                crumb={createConsoleCrumb(props.user)}
                            />
                        }
                        <XboxDeviceOverviewDetailsButton
                            crumb={createConsoleCrumb(props.user)}
                            emailAddress={props.user?.emailAddress}
                            onClick={() => { setShowDeviceDetails(true) }}
                        />
                    </>
                }
            </Box>

            <Box mb='4'>
                <Text fontSize='md' fontWeight='bold'> Status</Text>
                <Text fontWeight='500'>{props.user?.userStatus.statusMessage}</Text>
            </Box>

            <Box mb='4'>
                <Text fontSize='md' fontWeight='bold'> Details</Text>
                <Text fontWeight='500'>Profile: {props.user?.xmProfile?.name || "None Assigned"}</Text>
                <Text fontWeight='500'>Status: {props.user?.userStatus.statusMessage}</Text>
                <Text fontWeight='500'>Schedules: {props.user?.schedules?.map(s => s).join(', ') || "None Assigned"}</Text>
                {/* <Text fontWeight='500'>Number of Friends: {user.numFriends || 0}</Text>
                <Text fontWeight='500'>Conversations: {user.numConversations || 0}</Text> */}
            </Box>

            <XboxNotes notes={props.user?.notes} onNotesUpdate={props.updateNotes} />
        </Flex>
    )
}