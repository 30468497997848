import { Box, Button, Text, Textarea, useColorModeValue } from "@chakra-ui/react";
import { useXboxNotes } from "../hooks/useXboxNotesHook";

export function XboxNotes(props: { notes?: string, onNotesUpdate: (notes: string) => void }) {
    const {
        originalNotes,
        handleNotesChange,
        handleSaveClick,
        saveButtonDisabled,
        updateIndicator } = useXboxNotes(props.notes, props.onNotesUpdate);
    const bg = useColorModeValue('gray.100', 'navy.700');

    return (
        <Box>
            <Text fontSize='md' fontWeight='bold'>Notes</Text>
            <Textarea
                bg={bg}
                pt='10px'
                h='250px'
                placeholder='Add your notes here!'
                onChange={handleNotesChange}
                value={originalNotes}
                defaultValue={props?.notes || ''}
            >
            </Textarea>
            <Button float='right' w='100px' mt='20px' variant='brand' onClick={handleSaveClick} isDisabled={saveButtonDisabled}>Save</Button>
            {updateIndicator && <Text color='green.500' mt='10px'>Notes updated!</Text>}
        </Box>
    );
}