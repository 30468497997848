import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { XMNotification } from '../types/BackendTypes';
import { getCoplayConfigData } from 'lib/backend/firebase/service';
import { AuthContext } from './AuthProvider';

interface NotificationContextType {
    notifications: XMNotification[];
    unreadCount: number;
    markRead: (notificationId: string) => void;
    deleteNotification: (notificationId: string) => void;
}

interface XMNotificationProviderProps {
    children: ReactNode;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const XMNotificationProvider: React.FC<XMNotificationProviderProps> = ({ children }) => {
    const { currentUser } = useContext(AuthContext);
    const [notifications, setNotifications] = useState<XMNotification[]>([]);
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        if (!currentUser) return;
        const unsubscribe = getCoplayConfigData().subscribeToXMNotifications(setNotifications);
        return () => unsubscribe();
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser) return;
        setUnreadCount(notifications.filter(n => !n.read).length);
    }, [notifications, currentUser]);

    const markRead = (notificationId: string) => {
        const notification = notifications.find(n => n.id === notificationId);
        if (notification) {
            if (!notification.read) {
                getCoplayConfigData().markXMNotificationAsRead(notification);
            }
        }
    };

    const deleteNotification = (notificationId: string) => {
        const notification = notifications.find(n => n.id === notificationId);
        if (notification) {
            getCoplayConfigData().deleteXMNotification(notification.id);
        }
    };

    return (
        <NotificationContext.Provider value={{ notifications, unreadCount, markRead, deleteNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotifications must be used within a NotificationProvider');
    }
    return context;
};