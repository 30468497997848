import { ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import ContactLink from "./ContactLink";

export function UpgradeYourPlanModalScreen() {
    return (
        <>
            <ModalHeader>
                Upgrade Your CoPlay XM Account
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                Your CoPlay XM account has reached its maximum number of Xbox Live®
                users. If you'd like to raise your limit, please <ContactLink />!
            </ModalBody>
            <ModalFooter />
        </>
    );
}