import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { PasswordInput } from "./components/PasswordInput"
import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react"
import { AuthLayout } from "./AuthLayout"
import { AuthHeader } from "./components/AuthHeader"
import { confirmThePasswordReset } from "lib/backend/firebase/service"



const usePasswordChange = (resetCode: string) => {
    const [firstPassword, setFirstPassword] = useState<string>();
    const [secondPassword, setSecondPassword] = useState<string>();
    const [status, setStatus] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (firstPassword === secondPassword) {
            if (firstPassword && firstPassword.length > 6) {
                setLoading(true);
                await confirmThePasswordReset(
                    resetCode,
                    firstPassword
                )
                    .then(() => {
                        setStatus("Password changed successfully! Redirecting to login...");
                        setTimeout(() => {
                            navigate("/sign-in");
                        }, 2000);
                    })
                    .catch((e) => {
                        console.error(e);
                        setStatus("Hmm... Something went wrong. Please try again later.")
                    })
                    .finally(() => setLoading(false));
            } else {
                setStatus("Password too short");
            }
        } else {
            setStatus("Mismatched passwords");
        }
    };

    return {
        firstPassword,
        secondPassword,
        status,
        loading,
        setFirstPassword,
        setSecondPassword,
        handleSubmit,
    };
}

export const ChangePasswordPage: React.FC = () => {
    const oobCode = new URLSearchParams(window.location.search).get("oobCode");
    const { firstPassword, secondPassword, status, loading, setFirstPassword, setSecondPassword, handleSubmit } = usePasswordChange(oobCode);
    const brandColor = useColorModeValue("brand.500", "brand.400");
    const textColor = useColorModeValue("gray.700", "white");


    if (!oobCode) {
        return (
            <AuthLayout>
                <AuthHeader title='CoPlay XM' subtitle='' />
                <Text color='red.500' fontSize='lg' textAlign='center'>Uh oh... Something went wrong while resetting your password. Please contact support or try again later.</Text>
            </AuthLayout>
        )
    }

    return (
        <AuthLayout>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Flex direction="column" alignItems="center" justifyContent="center" width="100%" mb='4' p='2'>
                    <AuthHeader title='Welcome To CoPlay XM' subtitle='Enter your new password!' />
                    <PasswordInput
                        labelColor={textColor}
                        inputColor={brandColor}
                        value={firstPassword}
                        onChange={(value: string) => setFirstPassword(value)}
                        showPassword={false}
                        setShowPassword={() => { }}
                    />
                    <PasswordInput
                        labelColor={textColor}
                        inputColor={brandColor}
                        value={secondPassword}
                        onChange={(value: string) => setSecondPassword(value)}
                        showPassword={false}
                        setShowPassword={() => { }}
                    />
                    <Text pb='2'>{status}</Text>
                    <Button variant='brand' isLoading={loading} type="submit">
                        Change Password
                    </Button>
                </Flex>
            </form>
        </AuthLayout>
    )
}