import { Text, Box, HStack, Avatar, useColorModeValue, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, ListItem } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import DisneyPlus from 'lib/coplay/assets/disney_plus_icon.png';
import XboxIcon from 'lib/coplay/assets/xbox_icon.png';
import { useLocation } from "react-router-dom";


export function AddSubscriptionsModal() {
    const brandColor = useColorModeValue('brand.600', 'brand.500');
    const location = useLocation();
    const isActive = location.pathname === '/subscriptions/add';

    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <ListItem
                cursor="pointer"
                fontSize="md"
                h='25px'
                _hover={{ color: brandColor }}
                fontWeight={isActive ? "bold" : "normal"}
                borderRight={isActive ? "4px solid blue" : "none"}
                w='100%'
            >
                <Box onClick={onOpen} display="block" w="100%" h="100%">
                    + Add Subscription
                </Box>
            </ListItem>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent maxW="600px">
                    <ModalHeader>Add Subscription</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Box>
                            <Text textAlign={"center"} fontWeight="black" fontSize='3xl'>
                                Contact support to add additional services.
                            </Text>
                            <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontSize='md'>
                                Aggregate third-party services like Disney+ or Xbox Game Pass, and manage your payments and billing in one place.
                            </Text>
                            <HStack justifyContent="center" spacing={4} mt={4} mb={4}>
                                <Avatar src={DisneyPlus} size="lg" />
                                <Avatar src={XboxIcon} size="lg" />
                                <Avatar icon={<MdAdd />} size="lg" border="1px solid" borderColor="gray.300" bg="gray.300" />
                            </HStack>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}