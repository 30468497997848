import { Box, Text } from "@chakra-ui/react";
import { BasicReport, BasicResponse } from "lib/coplay/types/BackendTypes";
import Card from "lib/horizon/components/card/Card";
import { ResultRenderer } from "./ResultRenderer";

export function RequestLogRenderer(props: { requestLog: (BasicReport | BasicResponse)[] }) {
    return (
        <Card overflowX="auto">
            <Text fontSize="lg" fontWeight="bold">Recent Requests</Text>
            {props.requestLog.map((entry, index) => (
                <Box key={index}>
                    <ResultRenderer result={entry} isOpen={false} />
                </Box>
            ))}
        </Card>
    );
}