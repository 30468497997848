import { useState } from "react"
import { XMProfile } from "lib/coplay/types/BackendTypes"
import { collateProfileData } from "../profile-utils"
import { VStack, Flex, FormControl, FormLabel, Input, FormErrorMessage } from "@chakra-ui/react"
import { useSoftwareSettings } from "../hooks/useSoftwareSettings"
import { useXboxAccountSettings } from "../hooks/useXboxAccountSettings"
import { CreateProfileButton } from "./CreateProfileButton"
import { getXboxProfilesData } from "lib/backend/firebase/service"
import { ProfileUserSettings } from "./ProfileUserSettings"
import { ProfileAppSettings } from "./ProfileAppSettings"
import { ProfileGameSettings } from "./ProfileGameSettings"


export function useProfileDetails(profile?: XMProfile) {
    const [getChanged, setChanged] = useState<boolean>(false);
    const [name, setName] = useState<string>(profile ? profile.name : "");

    const setSetting = (setState: Function) => {
        return (settingValue: any) => {
            setChanged(true);
            setState(settingValue);
        };
    };

    // TODO: Figure out a better way to structure. These controllers seem to get recreated everytime 
    // the name changes
    const appsController = useSoftwareSettings(profile?.consoleSettings.apps, setSetting);
    const gamesController = useSoftwareSettings(profile?.consoleSettings.games, setSetting);
    const accountController = useXboxAccountSettings(profile?.accountSettings, setSetting);

    const handleSubmit = async (onCreated?: () => void): Promise<void> => {
        const profileData = collateProfileData(
            profile?.id, name, appsController, gamesController, accountController
        );
        if (profile && profile.name !== name) {
            await getXboxProfilesData().renameProfile(profileData);
        }
        await getXboxProfilesData().createProfile(profileData);
        onCreated && onCreated();
    };

    const isValid = (name.length > 0);

    return {
        getChanged,
        name,
        setName: setSetting(setName),
        appsController,
        gamesController,
        accountController,
        handleSubmit,
        isValid
    };
}


export default function ProfileDetailsPage(
    props: { editing?: boolean, profile?: XMProfile, onCreated?: () => void }
) {
    const {
        getChanged,
        name,
        setName,
        appsController,
        gamesController,
        accountController,
        handleSubmit,
        isValid
    } = useProfileDetails(props.profile);

    return (
        <VStack spacing={4}>
            <FormControl isInvalid={!isValid}>
                <Flex pt={2} alignItems={'center'}>
                    <FormLabel fontWeight={'bold'} w='100px'>Profile Name:</FormLabel>
                    <Input
                        required
                        maxW='500px'
                        isDisabled={!props.editing}
                        defaultValue={name}
                        placeholder='Enter the name for this profile'
                        name="name"
                        onChange={(e) => setName(e.target.value as string)}
                    ></Input>
                    {!isValid ? <FormErrorMessage px='10px'>*Profile name is required.</FormErrorMessage> : <></>}
                </Flex>
            </FormControl>

            <ProfileUserSettings editing={props.editing} controller={accountController} />
            <ProfileAppSettings editing={props.editing} controller={appsController} />
            <ProfileGameSettings editing={props.editing} controller={gamesController} />

            <CreateProfileButton
                editing={props.editing}
                onClick={() => handleSubmit(props.onCreated)}
                enabled={getChanged && isValid}
            />
        </VStack >
    )
}