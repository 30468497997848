import { Flex, Spinner, Text } from "@chakra-ui/react";

export function PaymentsLoadingState(props: { selectedService: string }) {

    return (
        <Flex id="paymentsLoadingState" direction={"column"} justifyContent="center" alignContent={"center"} alignItems={"center"} w='100%' h='100%'>
            <Text textAlign={"center"} fontWeight="black" fontSize='3xl'>
                {`Loading ${props.selectedService} Payments And Subscriptions...`.toUpperCase()}
            </Text>
            <Spinner size="xl" />
        </Flex>
    )
}
