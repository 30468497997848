import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Avatar, Flex, Button, Box, Link, Spinner, Text, ListItem, List } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { SearchedSoftware } from "lib/coplay/types/BackendTypes";
import { XMSuggestions } from "lib/coplay/types/FrontendTypes";
import { getXboxGameSuggestionDetails } from "lib/backend/api";

const CompatibilityMap = {
    "ConsoleGen9": "Xbox Series S|X",
    "ConsoleGen8": "Xbox One"
}

export default function XboxGameDetailsModal(props: {
    game: XMSuggestions | null,
    onSelected: (app: SearchedSoftware) => void,
    isOpen: boolean,
    onClose: () => void
}) {

    const [gameDetails, setGameDetails] = React.useState<SearchedSoftware | null>(null)
    const [isLoading, setIsLoading] = React.useState(true)

    const closeModal = () => {
        props.onClose()
        setGameDetails(null)
        setIsLoading(false)
    }

    const details = () => {
        if (props.game) {
            return (
                <Flex alignItems={'center'}>
                    <Avatar
                        name={props.game.Title}
                        marginRight="2"
                        src={props.game.ImageUrl}
                        w='50px'
                        h='50px'
                        borderRadius='20px'
                        me='16px'
                    />
                    <Box>
                        {props.game.Title}
                        <br></br>
                        <Link color='teal.500' href={props.game.Url} isExternal>View in Microsoft Store</Link>
                    </Box>

                </Flex>
            )
        }
        return null
    }

    useEffect(() => {
        if (props.game) {
            setIsLoading(true)
            getXboxGameSuggestionDetails(props.game.BigCatalogId).then((res) => {
                setGameDetails(res)
            }).catch((e) => {
                console.error("Error getting suggestion details", e)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [props.game?.BigCatalogId]);

    const compatElem = (compatConsoles: string[] | null) => {
        if (compatConsoles && compatConsoles.length > 0) {
            return (
                <>
                    <List>
                        <strong>Compatible with:</strong>
                        {gameDetails.compatibileWith.map((detail) => (
                            <ListItem>- {CompatibilityMap[detail as keyof typeof CompatibilityMap]}</ListItem>
                        ))}
                    </List>
                </>
            )
        }
    }

    const installableElem = () => {
        if (isLoading) {
            return (<Spinner />)
        } else {
            if (gameDetails != null) {

                const installSelected = () => {
                    props.onSelected(gameDetails)
                    closeModal()
                }

                return (
                    <>
                        {gameDetails.compatibileWith
                            ? (
                                <>
                                    {compatElem(gameDetails.compatibileWith)}
                                    <Button variant='brand' onClick={() => installSelected()}>Add</Button>
                                </>
                            )
                            : <Text textAlign="center" fontWeight="bold">Game is not compatible with your console</Text>}
                    </>
                )
            } else {
                return (
                    <>
                        <Text>Hmmm... Cannot install this title</Text>
                        <Button variant='brand' disabled>Add</Button>
                    </>
                )
            }
        }
    }

    return (
        <Modal
            isOpen={props.isOpen}
            size={"xl"}
            onClose={closeModal}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody style={
                    { display: "flex", flexDirection: "column", rowGap: "24px" }
                }>
                    {details()}
                    {installableElem()}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}