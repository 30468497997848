import { useContext, useState, useEffect } from "react"
import { ScheduledActionSelector, useActionController } from "./ActionSelector"
import { Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, Alert, AlertIcon, AlertDescription, Spinner, Icon } from "@chakra-ui/react"
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider"
import RepeatSelector from "./RepeatSelector"
import { ScheduledFrequencySelector } from "./FrequencySelector"
import DaySelectors from "lib/coplay/views/schedules/DaySelectors"
import FrequencyDescription from "./FrequencyDescription"
import EndSelector from "./EndSelector"
import { useScheduleAction } from "./useScheduleAction"
import { MdCheckCircleOutline } from "react-icons/md"
import { XboxUsersSet } from "lib/coplay/utils/XboxUsersSet"


export function ScheduleActionButton() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const { selectedUsers } = useContext(XboxUsersContext);

    return (
        <>
            <Button isDisabled={selectedUsers.size() === 0} variant='brand' onClick={onOpen}>Add Schedule</Button>
            <ScheduleActionModal isOpen={isOpen} onClose={onClose} />
        </>
    )
}

function ScheduleActionModal(props: {
    isOpen: boolean;
    onClose: () => void;
}) {
    const [viewState, setViewState] = useState<"form" | "loading" | "success" | "error">("form");
    const [error, setError] = useState<string | null>(null);

    const {
        start,
        end,
        repeat,
        frequency,
        scheduleName,
        repeatFrequency,
        startMin,
        setEnd,
        setRepeat,
        setScheduleName,
        handleStartChange,
        handleFrequencyStart,
        incrementedStart,
        submitSchedule
    } = useScheduleAction();

    const actionsController = useActionController();
    const { selectedUsers, setSelectedUsers } = useContext(XboxUsersContext);

    const handleSubmit = async () => {
        try {
            setViewState("loading");
            await submitSchedule(actionsController.action, selectedUsers.toArray());
            setViewState("success");
            setTimeout(() => {
                props.onClose();
                setViewState("form");
            }, 2000);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setSelectedUsers(new XboxUsersSet());
        }
    };

    useEffect(() => {
        if (!props.isOpen) {
            setError(null);
            setViewState("form");
        }
    }, [props.isOpen]);

    return (
        <>
            <Modal size="xl" isOpen={props.isOpen} onClose={props.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Schedule An Action</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {viewState === "loading" && (
                            <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                                <Spinner size='xl' mb={4} />
                            </Flex>
                        )}
                        {viewState === "error" && (
                            <Alert status="error" mb={4}>
                                <AlertIcon />
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        )}
                        {viewState === "success" && (
                            <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                                <Icon as={MdCheckCircleOutline} width='100px' height='100px' color={'green.500'} />,
                                <Text py={4}>Schedule created successfully!</Text>
                                <Button width='200px' mb={4} variant="brand" onClick={props.onClose}>Close</Button>
                            </Flex>
                        )}
                        {viewState === "form" && (
                            <>
                                <FormControl mb={4}>
                                    <FormLabel>Schedule Name (optional)</FormLabel>
                                    <Input
                                        value={scheduleName}
                                        onChange={(e) => setScheduleName(e.target.value)}
                                        placeholder="Enter a name for this schedule"
                                    />
                                </FormControl>
                                <label style={{ display: "flex", alignItems: "center" }}>
                                    <ScheduledActionSelector controller={actionsController} />
                                </label>
                                <label style
                                    ={{ padding: "25px 0", display: "flex", alignItems: "center" }}>
                                    <Text style={{ width: "68px" }} fontWeight='bold'>
                                        {repeat ? "Start" : "Time"}:
                                    </Text>
                                    <Input
                                        style={{ maxWidth: "285px" }}
                                        type={"datetime-local"}
                                        value={start.toISOString().slice(0, 16)}
                                        min={startMin.toISOString().slice(0, 16)}
                                        onChange={handleStartChange}
                                    />
                                </label>
                                <div style={{ height: "40px", display: "flex", fontWeight: "bold" }}>
                                    <label style={
                                        { paddingRight: "2px", display: "flex", alignItems: "center" }
                                    }>
                                        <RepeatSelector setRepeat={setRepeat} />
                                        <p>
                                            Repeat
                                        </p>
                                    </label>
                                    <ScheduledFrequencySelector
                                        frequency={repeatFrequency} frequencyStart={handleFrequencyStart}
                                    />
                                </div>
                                <Flex width="100%">
                                    <DaySelectors
                                        frequency={repeatFrequency}
                                        getStart={start}
                                        frequencyStart={handleFrequencyStart}
                                    />
                                </Flex>
                                <label style={{
                                    paddingTop: "16px",
                                    height: "56px",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <Flex alignItems="center" width="100%">

                                        <FrequencyDescription
                                            frequency={repeatFrequency} getStart={start}
                                        />
                                        <EndSelector
                                            getRepeat={repeat}
                                            endValue={end.toISOString().slice(0, 10)}
                                            endMin={incrementedStart(frequency, start).toISOString()}
                                            setEnd={setEnd}
                                        />
                                    </Flex>
                                </label>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        my='25px'
                                        width='200px'
                                        variant="brand"
                                        onClick={handleSubmit}
                                        loadingText="Creating Schedule..."
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

