import { Box, Flex, Text } from "@chakra-ui/react";
import { BasicResponse } from "lib/coplay/types/BackendTypes";

export function ResponseRenderer(props: { response: BasicResponse }) {

    return (
        <>
            <Box mt={2}>
                <Flex flexWrap="wrap" gap={4}>
                    <Flex alignItems="center">
                        <Text fontWeight="bold" mr={1}>User:</Text>
                        <Text>{props.response.emailAddress}</Text>
                    </Flex>
                </Flex>
            </Box>
        </>
    );
};