export default function RepeatSelector(props: { setRepeat: Function }) {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setRepeat(event.target.checked)
    }

    return (
        <input
            style={{ margin: "0 36px 0 19px" }}
            type={"checkbox"}
            onChange={onChange}
        />
    )
}