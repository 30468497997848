import { XboxUser } from "../types/BackendTypes";

type XboxUserSet = {
    [xuid: string]: XboxUser
}
// Needed for checking if a user is selected (references are different from allUsers to selectedUsers)
export class XboxUsersSet {
    private users: XboxUserSet;

    static FromUserArray(users: XboxUser[]) {
        let set = new XboxUsersSet();
        users.forEach(user => {
            set.add(user);
        })
        return set;
    }

    constructor(existing?: XboxUsersSet) {
        if (existing) {
            this.users = { ...existing.users };
        } else {
            this.users = {};
        }
    }

    public add(user: XboxUser) {
        this.users[user.xuid] = user;
    }

    public delete(user: XboxUser) {
        delete this.users[user.xuid];
    }

    public has(user: XboxUser) {
        return this.users[user.xuid] !== undefined;
    }

    public getAll() {
        return Object.values(this.users);
    }

    public get(user: XboxUser) {
        return this.users[user.xuid];
    }

    public getByXuid(xuid: string) {
        return this.users[xuid];
    }

    public clear() {
        this.users = {};
    }

    public size() {
        return Object.keys(this.users).length;
    }

    public forEach(callback: (user: XboxUser) => void) {
        Object.values(this.users).forEach(callback);
    }

    public map(callback: (user: XboxUser) => any) {
        return Object.values(this.users).map(callback);
    }

    public filter(callback: (user: XboxUser) => boolean) {
        return Object.values(this.users).filter(callback);
    }

    public toArray() {
        const users: XboxUser[] = [];
        this.forEach(user => {
            users.push(user);
        })
        return users;
    }

    public [Symbol.iterator]() {
        return Object.values(this.users)[Symbol.iterator]();
    }
}