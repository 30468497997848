import { Box } from "@chakra-ui/react";
import { XboxUser } from "lib/coplay/types/BackendTypes";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { useContext } from "react";
import { XboxUsersSet } from "lib/coplay/utils/XboxUsersSet";


export function SelectAllCheckbox(props: { displayedUsers: XboxUser[] }) {
    // TODO: Possibly life state?
    const { selectedUsers, setSelectedUsers } = useContext(XboxUsersContext);
    const isChecked = selectedUsers.size() > 0

    return (
        <Box pl="6">
            <label>
                <input id="SelectAllCheckbox" style={{ height: '1em', width: '1em' }} type="checkbox" checked={isChecked}
                    onClick={(e) => {
                        if (isChecked) {
                            setSelectedUsers(new XboxUsersSet())
                        } else {
                            const newSelectedUsers = XboxUsersSet.FromUserArray(props.displayedUsers);
                            setSelectedUsers(newSelectedUsers)
                        }
                    }}
                    onChange={() => { }} />
            </label>
        </Box>
    )
}