import { useEffect, useState } from 'react';
import { CoplayFunding, emptyCoplayFunding, SupportedVendors, VendorMember } from 'lib/coplay/types/PaymentTypes';
import { getVendorData } from 'lib/backend/firebase/service';
import { useHasPayments } from 'lib/coplay/contexts/ConfigurationContext';


export enum PaymentsViewState {
    LOADING = "Loading",
    LIST = "List",
    ZERO_STATE = "ZeroState",
    UPGRADE = "Upgrade",
}

export const usePaymentsViewHook = (vendor: SupportedVendors) => {
    const [viewState, setViewState] = useState<PaymentsViewState>(PaymentsViewState.LOADING);
    const [vendorMembers, setVendorMembers] = useState<VendorMember[]>([]);
    const [coplayFunding, setCoplayFunding] = useState<CoplayFunding>(emptyCoplayFunding());

    const hasPayments = useHasPayments();

    useEffect(() => {
        if (!hasPayments) {
            setViewState(PaymentsViewState.UPGRADE);
            return;
        }

        const unsubscribe = getVendorData().subscribeToVendorMembers(vendor, (members) => {
            if (members.length > 0) {
                setVendorMembers(members);
                setViewState(PaymentsViewState.LIST);
            } else {
                setViewState(PaymentsViewState.ZERO_STATE)
            }
        });

        return unsubscribe
    }, [vendor, hasPayments]);

    return { viewState, vendorMembers, coplayFunding };
};