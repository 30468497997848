import { useState } from "react";

import { XboxConsole } from "lib/coplay/types/BackendTypes";
import { renameConsole } from "lib/backend/api";

export const useEditableDeviceName = (emailAddress: string, device: XboxConsole) => {
    const [deviceName, setDeviceName] = useState(device.name);
    const [state, setState] = useState<"Ready" | "Loading" | "Error">("Ready");

    const handleChange = (newValue: string) => {
        setDeviceName(newValue);
    };

    const onSubmit = () => {
        if (deviceName === device.name) return;

        setState("Loading");
        renameConsole(emailAddress, device.id, deviceName)
            .then((res) => {
                setState("Ready");
            })
            .catch((e) => {
                console.error("Error changing console name: ", e);
                setState("Error");
            })
    };

    return { deviceName, state, handleChange, onSubmit };
};