import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { FindXboxConsolesScreen } from "./components/FindXboxConsolesScreen";
import { FoundXboxConsolesScreen } from "./components/FoundXboxConsolesScreen";
import { useXboxConsoleLink } from "./hooks/useXboxConsoleLink";
import { LinkingXboxConsoleScreen } from "./components/LinkingXboxConsoleScreen";
import { XboxLinkErrorScreen } from "./components/XboxLinkErrorScreen";

export const LinkXboxConsoleModal = (props: { emailAddress: string, isOpen: boolean, onClose: () => void }) => {
    const { onClose } = useDisclosure();
    const {
        progress,
        xboxIds,
        selectedXboxId,
        error,
        onFoundConsoles,
        onSelectedXboxId,
        onError,
        reset,
    } = useXboxConsoleLink(props.emailAddress);

    const resetOnClose = () => {
        reset();
        props.onClose();
        onClose();
    }

    console.log(`[Rendering] LinkXboxConsoleModal ${props.emailAddress} isOpen: ${props.isOpen}`);

    const states = {
        NO_EMAIL: <div>No User Selected.</div>,
        FINDING_CONSOLE: <FindXboxConsolesScreen emailAddress={props.emailAddress} onError={onError} onFound={onFoundConsoles} />,
        FOUND_CONSOLES: <FoundXboxConsolesScreen xboxIds={xboxIds} onSelected={onSelectedXboxId} />,
        LINKING_CONSOLE: <LinkingXboxConsoleScreen emailAddress={props.emailAddress} onError={onError} xboxId={selectedXboxId} />,
        ERROR: <XboxLinkErrorScreen error={error} />,
    }

    return (
        <Modal isOpen={props.isOpen} onClose={resetOnClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Link Your Xbox To Coplay</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {states[progress]}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={resetOnClose} variant='ghost'>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};