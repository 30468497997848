import { deleteDoc, deleteField, DocumentSnapshot, onSnapshot, setDoc, updateDoc } from "firebase/firestore";

import { Firestore, getDocs, where, query, doc } from "firebase/firestore";

import { User } from "firebase/auth";
import { collection } from "firebase/firestore";
import { XMProfile } from "lib/coplay/types/BackendTypes";

export class XboxProfilesData {
    _user: User;
    _db: Firestore;

    constructor(user: User, db: Firestore) {
        this._user = user;
        this._db = db;
    }

    getProfilesPath = () => {
        return `${this._user.email}/data/profiles/`;
    }

    getProfilesCollectionRef = () => {
        return collection(this._db, this.getProfilesPath());
    }

    subscribeToProfiles = (callback: (profiles: XMProfile[]) => void) => {
        try {
            const q = query(this.getProfilesCollectionRef());
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const allProfiles: XMProfile[] = [];
                snapshot.forEach((doc) => {
                    const data = doc.data() as XMProfile;
                    allProfiles.push(data);
                });
                callback(allProfiles);
            });
            return unsubscribe;
        } catch (e) {
            console.error(`[subscribeToProfiles] Error: ${e}`);
        }
    }

    deleteProfile = async (profile: XMProfile) => {
        try {
            const profileRef = doc(this._db, this.getProfilesPath(), `${profile.id}`);
            await deleteDoc(profileRef);
            await Promise.all((await getDocs(query(
                collection(this._db, `${this._user.email}/data/users`),
                where("xmProfile.id", "==", profile.id)
            ))).docs.map((doc: DocumentSnapshot) => {
                return setDoc(doc.ref, { xmProfile: deleteField() }, { merge: true })
            }))
        } catch (e) {
            console.error(`[deleteProfile] Error: ${e}`);
        }
    }

    renameProfile = async (profile: XMProfile) => {
        try {
            await Promise.all((await getDocs(query(
                collection(this._db, `${this._user.email}/data/users`),
                where("xmProfile.id", "==", profile.id)
            ))).docs.map((doc: DocumentSnapshot) => {
                return updateDoc(doc.ref, "xmProfile.name", profile.name)
            }))
        } catch (error) {
            console.error(`[renameProfile] Error: ${error}`)
        }
    }

    duplicateProfile = async (profile: XMProfile): Promise<void> => {
        try {
            const newId = Date.now().toString(36) + Math.random().toString(36).substr(2);
            const newName = `${profile.name} Copy`;
            const path = `${this.getProfilesPath()}${newId}`;
            const profileRef = doc(this._db, path);
            const newProfile = { ...profile, id: newId, name: newName };
            await setDoc(profileRef, newProfile);
        } catch (e) {
            console.error(`[duplicateProfile] Error: ${e}`);
        }
    };

    createProfile = async (profile: XMProfile) => {
        try {
            const profileRef = doc(this._db, this.getProfilesPath(), `${profile.id}`);
            await setDoc(profileRef, profile);
        } catch (e) {
            console.error(`[createProfile] Error: ${e}`);
        }
    }

    getXMProfiles = async (): Promise<XMProfile[]> => {
        try {
            const q = query(collection(this._db, this.getProfilesPath()));
            const querySnapshot = await getDocs(q);
            let profiles: XMProfile[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data() as XMProfile;
                profiles.push(data);
            });
            return profiles;
        } catch (e) {
            console.log(`[getXMProfiles] Error: ${e}`);
            return [];
        }
    }

}
