import { Flex, Spacer, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { ConsoleCrumb, XboxState } from "lib/coplay/types/BackendTypes";
import { XboxIcon } from "./XboxIcon";

export function XboxConsoleDetailsButton(props: {
    consoleId: string,
    consoleName?: string,
    consoleOs?: string,
    consoleState?: XboxState,
    emailAddress: string,
    onClick: () => void
}) {
    const brandColor = useColorModeValue('brand.500', 'white');
    const iconColor = useColorModeValue('#000000', 'white');

    return (
        <Flex
            textAlign={'left'}
            minH='80px'
            fontSize='xs'
            mt={-11}
            direction='column'
            alignItems='center'
            _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                props.onClick();
            }}>
            <XboxIcon color={iconColor} size='47px' consoleState={props.consoleState} />
            <Text
                marginTop={"-15px"}
                textAlign='center'
                color={brandColor}
                fontWeight='bold'
                fontSize='11px'
                _hover={{ cursor: 'pointer' }}>
                {props.consoleName && <Text>{props.consoleName}</Text>}
                {props.consoleOs && <Text>OS: {props.consoleOs}</Text>}
                {props.consoleId && <Text>{props.consoleId}</Text>}
            </Text>
        </Flex>
    );
}

export function XboxDeviceOverviewDetailsButton(props: {
    crumb: ConsoleCrumb,
    emailAddress: string,
    onClick: () => void
}) {
    const brandColor = useColorModeValue('brand.500', 'white');
    const iconColor = useColorModeValue('#000000', 'white');

    return (
        <Flex
            px={1}
            bg='gray.100'
            alignItems='center'
            textAlign={'left'}
            minH='70px'
            _hover={{ cursor: 'pointer', fontSize: 'md', shadow: 'sm' }}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                props.onClick();
            }}>
            <XboxIcon color={iconColor} size='100px' consoleState={props.crumb.consoleState} />
            <Text
                marginLeft={2}
                color={brandColor}
                fontWeight='bold'>
                {props.crumb.consoleName && <Text>{props.crumb.consoleName}</Text>}
                {props.crumb.consoleOS && <Text>OS: {props.crumb.consoleOS}</Text>}
                {props.crumb.consoleId && <Text>{props.crumb.consoleId}</Text>}
            </Text>
            <Spacer />
            <Text px={2}>
                Status: {props.crumb.consoleState}
            </Text>
        </Flex>
    );
}   