import { FormControl, FormLabel, Checkbox, Text } from "@chakra-ui/react";
import { XboxGameSearch } from "lib/coplay/components/xbox/gamesearch/XboxGameSearch";
import SoftwareDescription from "./SoftwareDescription";
import { SoftwareSettingsController } from "../profile-utils";
import { ProfileSoftwareContainer } from "./ProfileSoftwareContainer";


export function ProfileGameSettings(props: {
    editing: boolean, controller: SoftwareSettingsController
}) {

    return (
        <>
            <FormControl>
                <FormLabel fontWeight='bold'>Games</FormLabel>
                <Text>
                    <SoftwareDescription
                        editing={props.editing} softwareCategory={"game"}
                    />
                </Text>
                <ProfileSoftwareContainer
                    apps={props.controller.selected}
                    editing={props.editing}
                    remove={props.controller.remove}
                    softwareCategory={"game"}
                />
            </FormControl>
            <FormControl>
                <div style={{ height: "55px", maxWidth: "448px" }}>
                    <XboxGameSearch
                        editing={props.editing}
                        onSelected={props.controller.add}
                    />
                </div>
                <Checkbox py='10px'
                    isDisabled={!props.editing}
                    defaultChecked={props.controller.removeOthers}
                    checked={props.controller.removeOthers}
                    onChange={() => props.controller.setRemoveOthers(!props.controller.removeOthers)}
                >
                    Remove all other installed games
                </Checkbox>
            </FormControl>
        </>
    )
}