import { Avatar, Box, Button, Heading, Image } from '@chakra-ui/react';
import Card from 'lib/horizon/components/card/Card';
import { PaymentsLoadingState } from './components/PaymentsLoadingState';
import { SupportedVendors, VendorMember } from 'lib/coplay/types/PaymentTypes';
import { PaymentsZeroStateLayout } from './components/PaymentsZeroState';
import { PaymentsViewLayout } from './components/PaymentsViewLayout';
import { FundingDetails } from './components/FundingDetails';
import { PaymentsViewState, usePaymentsViewHook } from './usePaymentsViewHook';
import XboxIcon from 'lib/coplay/assets/xbox_icon.png';
import { PaymentsUpgradeState } from './components/PaymentsUpgradeState';
import { AggXboxStrategy } from 'lib/coplay/components/remote-browser/browser-strategies';
import { AddVendorMemberButton } from './AddPaymentsMemberButton';


export function XboxPaymentsView() {
    const { viewState, vendorMembers, coplayFunding } = usePaymentsViewHook(SupportedVendors.Microsoft);

    return (
        <Box h='100%' >
            <Card h='100%' overflowY='scroll' p={2}>
                {viewState === PaymentsViewState.LOADING &&
                    <PaymentsLoadingState selectedService={SupportedVendors.Microsoft} />}
                {viewState === PaymentsViewState.ZERO_STATE
                    && <XboxPaymentsZeroState />}
                {viewState === PaymentsViewState.LIST
                    && <XboxPaymentsList members={vendorMembers} />}
                {viewState === PaymentsViewState.UPGRADE
                    && <PaymentsUpgradeState
                        icon={<Avatar src={XboxIcon} size="2xl" />}
                    />}
            </Card>
        </Box>
    );
}


export function XboxPaymentsList(props: { members: VendorMember[] }) {
    const gamepassIcon = <Image src={XboxIcon} width={8} height={8} borderRadius='full' />

    // TODO: Make this use virtualized list
    return (
        <PaymentsViewLayout
            header={<Heading size="lg" fontWeight="bold" color="green.500">YOUR XBOX GAME PASS ACCOUNTS</Heading>}
            description={'Aggregate your Xbox game pass accounts and manage your payments and billing in one place.'}
            button={
                <AddVendorMemberButton
                    vendor={SupportedVendors.Microsoft}
                    strategy={new AggXboxStrategy()}
                    button={
                        <Button
                            leftIcon={gamepassIcon}
                            variant="action"
                            color="green.600"
                        >
                            Add Account
                        </Button>
                    }
                />
            }
            fundingDetails={
                <FundingDetails coplayBalance={0} coplaySpend={0} users={props.members.length} />
            }
            members={props.members}
        />
    )
}

function XboxPaymentsZeroState() {

    return (
        <PaymentsZeroStateLayout
            icon={<Avatar src={XboxIcon} size="2xl" />}
            title="No Managed Payments Or Subscription Accounts Found"
            description='Aggregate your Xbox game pass accounts and manage your payments and billing in one place.'
            button={
                <Button mt={4} variant="action" color="green.500" maxWidth="300px" minWidth="200px" onClick={() => { }}>
                    Add Game Pass Account
                </Button>
            }
        />
    )
}

