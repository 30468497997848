import { Flex, Text, Button } from "@chakra-ui/react";
import { getXboxUserData } from "lib/backend/firebase/service";

export function NoDeviceScreen(props: {
    emailAddress: string,
    onClose: () => void
}) {

    const unlinkConsole = () => {
        getXboxUserData().deleteDeviceCrumb(props.emailAddress)
            .catch((error) => {
                console.error("Error unlinking console", error);
            }).finally(() => {
                props.onClose();
            })
    }

    return (
        <Flex direction='column' gap={4} alignItems='center'>
            <Text>Hmm, we couldn't find your console. Please try unlinking and relinking your console.</Text>
            <Button maxWidth='150px' variant='brand' colorScheme='red' onClick={unlinkConsole}>Unlink Console</Button>
        </Flex>
    )
}