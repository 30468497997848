export default function
    SoftwareDescription(props: { editing: boolean, softwareCategory: string }) {
    let softwareDescription: string
    if (props.editing) {
        softwareDescription = `Select the ${props.softwareCategory
            }s that you want to be preinstalled on your console`
    } else {
        softwareDescription = "Software to be installed:"
    }

    return (
        <>
            {softwareDescription}
        </>
    )
}