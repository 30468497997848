export const XboxLoginUrl     = 'https://login.live.com/logout.srf?ru=https%3A%2F%2Flogin.live.com%2Foauth20_authorize.srf%3Fclient_id%3D2c7953d8-4eb0-4131-a9e6-d08054d103dc%26redirect_uri%3Dhttps%253A%252F%252Fxm-dev.coplay.io%252Fcode%252F%26response_type%3Dcode%26scope%3DXboxLive.signin%2520XboxLive.offline_access';

export const FirebaseConfig   = {
  "apiKey": "AIzaSyA4kn5DZpNvJbswj60ZwS98At7X1I6Dn3w",
  "authDomain": "coplay-oauth-int.firebaseapp.com",
  "projectId": "coplay-oauth-int",
  "storageBucket": "coplay-oauth-int.appspot.com",
  "messagingSenderId": "148736684254",
  "appId": "1:148736684254:web:5d92e016677311a185d24f"
};

export const BackendConfig    = {
  "date": "1/20/2025, 12:08:42 PM",
  "environment": "int",
  "xboxGameSearchUrl": "https://7yfc6zoymshikuld6zvdfl57h40vegub.lambda-url.us-west-1.on.aws/",
  "firebaseId": "coplay-oauth-int",
  "thirdPartyUrl": "https://enbjt3eclwxhdkxenwdum4moqq0bnkcn.lambda-url.us-west-1.on.aws/",
  "scheduleGroupName": "CustomScheduleGroup-int",
  "schedulerRoleArn": "arn:aws:iam::367627898123:role/IntBackendStack-schedulerRoleintid98C8C8CF-JmQOA5A6QIXl",
  "scheduleLambdaArn": "arn:aws:lambda:us-west-1:367627898123:function:IntBackendStack-xboxSchedHandlerintB7372484-vv9GOovQAhkI",
  "url": "https://lphpmj5tdimlnyp6ghfihso74q0osjmz.lambda-url.us-west-1.on.aws/"
};