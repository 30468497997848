import { CopyIcon } from "@chakra-ui/icons";
import { useClipboard, FormControl, FormLabel, Center, Button, Icon, Text } from "@chakra-ui/react";
import { MintKeyResponse, ResponseStatus } from "lib/coplay/types/BackendTypes";

export const CreateKeyResponseScreen = (props: {
    response: MintKeyResponse
}) => {
    const { onCopy, hasCopied } = useClipboard(props.response.key);

    if (props.response.status === ResponseStatus.Success) {
        return (
            <>
                <FormControl mb={4}>
                    <FormLabel>Your new secret key:</FormLabel>

                    <Text
                        align="center"
                        outline="1px solid gray"
                        fontFamily="mono"
                        bg="gray.100"
                        fontSize="14px"
                        p={2}
                        borderRadius="md"
                    >{props.response.key}</Text>

                    <Center>
                        <Button
                            m={3}
                            size="sm"
                            colorScheme="teal"
                            onClick={onCopy}
                            leftIcon={<Icon as={CopyIcon} />}
                        >
                            {hasCopied ? "Copied!" : "Copy"}
                        </Button>
                    </Center>

                </FormControl>
                <Text mb={4} color="red.500" fontWeight="bold">
                    Important: Copy and store this key in a safe place. It will not be shown again after you close this window.
                </Text>
            </>
        )
    } else {
        return (
            <>
                <FormControl mb={4}>
                    <Text>Hmm... Something went wrong.</Text>
                    <FormLabel>Error</FormLabel>
                    <Text>{props.response.error.toString()}</Text>
                </FormControl>

            </>
        )
    }
}