import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { ContactDetails, EmptyContactDetails, FrontendConfiguration } from '../types/FrontendTypes';
import { AuthContext } from './AuthProvider';
import { getCoplayConfigData } from 'lib/backend/firebase/service';

interface ConfigurationContextType {
    numUsers?: number;
    hasPayments?: boolean;
    contactDetails: ContactDetails;
    setContactDetails: React.Dispatch<React.SetStateAction<ContactDetails>>
}

interface ConfigurationProviderProps {
    children: ReactNode;
}

const ConfigurationContext = createContext<ConfigurationContextType | undefined>(undefined);

export const ConfigurationProvider: React.FC<ConfigurationProviderProps> = ({ children }) => {
    const { currentUser } = useContext(AuthContext);
    const [config, setConfig] = useState<FrontendConfiguration>({});
    const [contactDetails, setContactDetails] = useState<ContactDetails>(EmptyContactDetails);


    useEffect(() => {
        if (!currentUser) return;
        const unsubscribe = getCoplayConfigData().subscribeToConfiguration(setConfig);
        return () => unsubscribe();
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser) return;
        getCoplayConfigData().getContactDetails(setContactDetails);
        return () => { };
    }, [currentUser]);

    const value: ConfigurationContextType = {
        numUsers: config.numUsers,
        hasPayments: config.hasPayments,
        contactDetails: contactDetails,
        setContactDetails: setContactDetails
    };

    return (
        <ConfigurationContext.Provider value={value}>
            {children}
        </ConfigurationContext.Provider>
    );
};


export const useConfiguration = () => {
    const context = useContext(ConfigurationContext);
    if (!context) {
        throw new Error('useConfiguration must be used within a ConfigurationProvider');
    }
    return context;
};

export const useUserLimit = () => {
    const config = useConfiguration();
    return config.numUsers;
};

export const useHasPayments = () => {
    const { hasPayments } = useConfiguration();
    return hasPayments;
};

export const useContactDetails = () => {
    const { contactDetails } = useConfiguration();
    return contactDetails;
};

export const useUpdateContactDetails = () => {
    const context = useConfiguration();
    if (context === undefined) {
        throw new Error("useUpdateContactDetails must be used within a ConfigurationProvider")
    }
    return function (enteredDetails: { [key: string]: string }) {
        getCoplayConfigData().updateContactDetails(enteredDetails)
        context.setContactDetails({ ...context.contactDetails, ...enteredDetails })
    }
}
